import React, {lazy, Suspense, useCallback, useEffect, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTopNavStyles} from "./styles/top_nav_styles.js";
import {useLeftNavStyles} from "./styles/left_nav_styles.js";
import {useTheme} from "@mui/material/styles";
import logo from "assets/signalcraft-logo-darkBG.png";
import LogoutIcon from "@mui/icons-material/Logout";

import GiteIcon from "@mui/icons-material/Gite";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SettingsIcon from "@mui/icons-material/Settings";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import PolicyIcon from "@mui/icons-material/Policy";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import {NotificationCenter} from "../notifications/index";

// import {ListItemProps} from "@mui/material";
// import Collapse from "@mui/material/Collapse";
// import ListItem from "@mui/material/ListItem";
import InsightsIcon from "@mui/icons-material/Insights";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewListIcon from "@mui/icons-material/ViewList";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {NavLink as Link, useNavigate} from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import {Avatar} from "@mui/material";
import {firstNameAtom, userInfoAtom} from "services/jotai/auth/msauth";
import {useAtom} from "jotai/index";
import urlPaths from "services/api/urlPaths";
import {allUserProfileSettings, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {useApiAuth} from "services/api/useApiAuth";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {settings} from "services/jotai/settings/settings";
import Badge from "@mui/material/Badge";


const
  Button = lazy(() => import("@mui/material/Button")),
  Typography = lazy(() => import("@mui/material/Typography")),
  AppBar = lazy(() => import("@mui/material/AppBar")),
  IconButton = lazy(() => import("@mui/material/IconButton")),
  Box = lazy(() => import("@mui/material/Box")),
  Drawer = lazy(() => import("@mui/material/Drawer")),
  List = lazy(() => import("@mui/material/List")),
  Divider = lazy(() => import("@mui/material/Divider")),
  ListItem = lazy(() => import("@mui/material/ListItem")),
  // GiteIcon = lazy(() => import("@mui/icons-material/Gite")),
  // HorizontalRuleIcon = lazy(() => import("@mui/icons-material/HorizontalRule")),
  ListItemButton = lazy(() => import("@mui/material/ListItemButton")),
  ListItemIcon = lazy(() => import("@mui/material/ListItemIcon")),
  ListItemText = lazy(() => import("@mui/material/ListItemText")),
  MenuIcon = lazy(() => import("@mui/icons-material/Menu")),
  InboxIcon = lazy(() => import("@mui/icons-material/MoveToInbox")),
  MailIcon = lazy(() => import("@mui/icons-material/Mail")),
  Toolbar = lazy(() => import("@mui/material/Toolbar")),
  Popover = lazy(() => import("@mui/material/Popover")),
  Stack = lazy(() => import("@mui/material/Stack")),
  // SettingsIcon = lazy(() => import("@mui/icons-material/Settings")),
  DriveFolderUploadIcon = lazy(() => import("@mui/icons-material/DriveFolderUpload"));


export const MobileMenu = (props: any) => {
  const
    {makeRequest} = useApiAuth(),
    theme = useTheme(),
    navigate = useNavigate(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    [firstName] = useAtom(firstNameAtom),
    [globalSettings] = useAtom<any>(settings),
    [allUserSettings, setAllUserSettings] = useAtom<any>(allUserProfileSettings),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [politicalMatchingCount, setPoliticalMatchingCount] = useState(0),
    {getCurrentQuarter} = useQuarter(),
    {getCurrentYear} = useDate(),
    topNavStyles = useTopNavStyles(),
    leftNavClasses = useLeftNavStyles(),
    [open, setOpen] = useState(false),
    id = open ? "simple-popover" : undefined,
    [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null),
    settingsOpen = Boolean(anchorEl),
    toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    },
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    handleClose = () => {
      setAnchorEl(null);
    },
    stringToColor = (string: string) => {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    },

    stringAvatar = (name: string) => {
      return {
        sx: {
          height: "30px",
          width: "30px",
          fontSize: "15px",
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    },
    getPoliticalCompliance = async () => {
      const markets = allUserMarkets.map((item: any, index: number) => `market[${index}]=${encodeURIComponent(item)}`).join("&");
      if (allUserMarkets.length > 0) {
        await makeRequest("GET", `${urlPaths.POLITICAL_MATCHES}?year=${getCurrentYear()}&${markets}&quarter=${getCurrentQuarter()}&matched=false&showLatest=true`).then((data: any) => {
          setPoliticalMatchingCount(data.count);
        }).catch((error) => console.log("error", error));
      }
    },
    handleLogout = useCallback(async () => {
      navigate("/msauth/logout");
    }, []),
    MainMenuItems = [{
      name: (userInfo.firstName) ? userInfo.firstName : firstName + " " + userInfo?.lastName,
      src: "internal",
      href: null,
      active: true,
      decoration: "Upcoming",
      iconType: "component",
      icon: <Avatar alt={`${userInfo.firstName} ${userInfo.lastName}`}
        {...stringAvatar(`${userInfo.firstName} ${userInfo.lastName}`)}
        src={`/craft/rest/users/avatar/${userInfo.username}`}
        sx={{
          boxShadow: 9,
          height: "30px",
          width: "30px",
          fontSize: "14px",
          bgcolor: stringToColor(`${userInfo.firstName} ${userInfo.lastName}`)
        }}/>,
      subNav: [{
        name: "Logout",
        src: "internal",
        href: "/msauth/logout",
        active: true,
        icon: LogoutIcon
      }, {
        name: "Settings",
        src: "internal",
        href: "/profile/",
        active: true,
        icon: SettingsIcon
      }, {
        name: "Notifications",
        component: <NotificationCenter {...{userInfo: userInfo, type: "mobile"}}/>
      }]
    }, {
      name: "Dashboard",
      src: "internal",
      href: "/",
      active: true,
      decoration: "Upcoming",
      icon: InsightsIcon
    }, {
      name: "Townsquare",
      src: "internal",
      href: null,
      active: true,
      decoration: FiberNewIcon,
      icon: GiteIcon,
      subNav: [{
        name: "Markets",
        src: "internal",
        href: "/markets/",
        active: true,
        icon: HorizontalRuleIcon
      }, {
        name: "Brands",
        src: "internal",
        href: "/facilities/",
        active: true,
        icon: HorizontalRuleIcon
      }]
    }, {
      name: "Issues & Programs",
      src: "internal",
      href: "/issues-and-programs/",
      // active: true,
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access.includes("Issues & Programs") ? true : false,
      decoration: FiberNewIcon,
      icon: DriveFolderUploadIcon,
      subNav: [{
        name: "Full Report",
        src: "internal",
        href: "/issues-and-programs/report/",
        active: true,
        icon: AssessmentIcon
      }, {
        name: "Compliance Report",
        src: "internal",
        href: "/issues-and-programs/compliance/",
        active: true,
        icon: CrisisAlertIcon
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/issues-and-programs/queue/",
        active: true,
        icon: ViewListIcon
      }]
    }, {
      name: "Political",
      src: "internal",
      href: "/political-candidates-and-issues/",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access.includes("Political") && globalSettings?.menu?.political && globalSettings?.menu?.political?.value ? true : false,
      // active: globalSettings?.menu?.political ? globalSettings?.menu?.political?.value : false,
      decoration: "Upcoming",
      icon: GavelIcon,
      subNav: [{
        name: "Matching",
        src: "internal",
        href: "/political-candidates-and-issues/matching/",
        active: true,
        icon: AccountTreeIcon,
      }, {
        name: "Compliance Report",
        src: "internal",
        href: "/political-candidates-and-issues/compliance/",
        active: true,
        icon: CrisisAlertIcon,
        badge: politicalMatchingCount
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/political-candidates-and-issues/queue/",
        active: true,
        icon: ViewListIcon
      }, {
        name: "Full Report",
        src: "internal",
        href: "/political-candidates-and-issues/report/",
        active: true,
        icon: AssessmentIcon
      }]
    }, {
      name: "EEO",
      src: "internal",
      href: "/eeo",
      active: false,
      decoration: "Upcoming",
      icon: PolicyIcon
    }, {
      name: "Entity Settings",
      src: "internal",
      href: "/entity-settings",
      active: false,
      decoration: "Upcoming",
      icon: SettingsApplicationsIcon
    }],
    SecondaryMenuItems = [{
      name: "Get Support",
      src: "external",
      active: true,
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/",
      icon: ContactSupportIcon
    }, {
      name: "Training Library",
      src: "internal",
      active: true,
      href: "/training/",
      icon: VideoLibraryIcon
    }, {
      name: "Mobile App",
      src: "internal",
      active: false,
      href: "/mobile-app",
      icon: AppShortcutIcon
    }, {
      name: "Submit An Idea",
      src: "internal",
      active: false,
      href: "submit-an-idea",
      icon: TipsAndUpdatesIcon
    }];


  const DrawerList = (toggleDrawer: any) => {
    const classes = useLeftNavStyles();
    return (
      <Box sx={{...classes.drawerPaper}} role="presentation" onClick={toggleDrawer(true)}>
        <List>
          {MainMenuItems.map((item, index) => {

            let expand = false;

            // If the menu item has subnav menu items
            if (item.subNav) {
              const subNavItems: any = [];
              // Put the subnav items into an array
              item.subNav.map((subNav: any) => {
                const route = props?.route?.split("/")[1];
                if (!subNav.component) {
                  props?.route !== "/" && subNav.href.includes(route) ? expand = true : null;
                  subNavItems.push(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <ListItem key={subNav.name} disablePadding component={Link} disabled={!subNav.active}
                      to={`${subNav.href}`}>
                      <ListItemButton sx={{...classes.listItemStyles, pl: 2}} dense={true}
                        selected={props?.route?.includes(subNav.href)}>
                        {/*{subNav.icon ? <ListItemIcon sx={classes.listItemIcon}>*/}
                        {/*  <subNav.icon/>*/}
                        {/*</ListItemIcon> : <></>}*/}
                        {/*<ListItemText primary={subNav.name} sx={classes.listItemText}/>*/}
                        {subNav.icon ? <ListItemIcon sx={classes.listItemIcon}>
                          {subNav.badge && subNav.badge >= 1 ? <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            overlap="rectangular"
                            badgeContent={subNav.badge ? subNav.badge : 0}
                            color="warning"
                            sx={{paddingRight: "5px", paddingTop: "3px"}}
                          >
                            <subNav.icon/>
                          </Badge>
                            : <subNav.icon/>}
                        </ListItemIcon> : <ListItemIcon sx={classes.listItemIcon}></ListItemIcon>}
                        <ListItemText primary={subNav.name} sx={classes.listItemText}/>
                      </ListItemButton>
                    </ListItem>
                  );
                } else {
                  subNavItems.push(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <ListItem key={subNav.name} disablePadding>{subNav.component}</ListItem>);
                }
              });

              const [openIssues, setOpenIssues] = useState(expand);
              const updateOpenIssuesMenu = () => {
                setOpenIssues(!openIssues);
              };

              // return the menu item and its subnav items
              return (<Suspense key={item.name} fallback={<div key={item.name} style={{
                height: "100px",
                backgroundColor: "transparent"
              }}></div>}>
                <ListItemButton id={item.name + "123123"} sx={classes.listItemStyles} dense={true}
                  disabled={!item.active}
                  onClick={updateOpenIssuesMenu}>
                  <ListItemIcon sx={classes.listItemIcon}>
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item.iconType === "component" ? item.icon : <item.icon/>
                    }
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={classes.listItemText}/>
                  {openIssues ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                </ListItemButton>
                <Collapse in={openIssues} timeout="auto" aria-expanded={openIssues} mountOnEnter unmountOnExit>
                  <List disablePadding>
                    {subNavItems}
                  </List>
                </Collapse>
              </Suspense>);
            }

            // Regular menu item
            else {

              return (<ListItem key={item.name} disablePadding disabled={!item.active}><ListItemButton
                sx={classes.listItemStyles}
                dense={true}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Link}
                to={item.active ? item.href : undefined}
                selected={props?.route?.split("/")[1] === item.href}>
                <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon>
                <ListItemText primary={item.name} sx={classes.listItemText}/>
              </ListItemButton>
              </ListItem>);

            }
          })}
        </List>
        <Divider sx={classes.dividerStyleCb}/>
        <List>
          {/*{["All mail", "Trash", "Spam"].map((text, index) => (*/}
          {/*  <ListItem key={text} disablePadding>*/}
          {/*    <ListItemButton sx={classes.secondaryBtn}>*/}
          {/*      <ListItemIcon sx={classes.listItemIcon}>*/}
          {/*        {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}*/}
          {/*      </ListItemIcon>*/}
          {/*      <ListItemText primary={text} sx={classes.listItemText}/>*/}
          {/*    </ListItemButton>*/}
          {/*  </ListItem>*/}
          {/*))}*/}
          {SecondaryMenuItems.map((item, index) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ListItem key={item.name} disablePadding component={Link}
              target={item.src === "external" ? "_blank" : undefined}
              to={item.src === "external" && item.active ? item.href : (item.src !== "external" && item.active) ? `${item.href}` : undefined}>
              <ListItemButton sx={classes.secondaryBtn} dense={true} disabled={!item.active}>
                <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon>
                <ListItemText primary={item.name} sx={classes.listItemText}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  useEffect(() => {
    getPoliticalCompliance();
  }, [allUserMarkets, props]);

  return (
    <Suspense fallback={<div style={{...topNavStyles.loading_toolbar as any}}></div>}>
      <Box>
        <Drawer
          onClose={toggleDrawer(false)}
          anchor={"left"}
          variant={isSmallScreen ? "temporary" : "permanent"}
          open={isSmallScreen ? open : true}
          sx={leftNavClasses.body}
        >
          {DrawerList(toggleDrawer)}
        </Drawer>
      </Box>
      <nav style={{...topNavStyles.nav as any}}>
        <AppBar sx={topNavStyles.appbar} position="fixed">
          <Toolbar sx={topNavStyles.toolbar} variant="dense">
            <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}} onClick={toggleDrawer(true)}>
              <MenuIcon/>
            </IconButton>
            <Link style={topNavStyles.logolink} to={"/"}>
              <img style={{...topNavStyles.logo}} src={logo as any}/>
            </Link>
          </Toolbar>
        </AppBar>
      </nav>
    </Suspense>
  );
};