import {CircularProgress} from "@mui/material";
import {useSnackBar} from "hooks/useSnackBar";
import {useAtom} from "jotai";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {debounce} from "lodash";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useApiAuth} from "services/api/useApiAuth";
import {MSAUTH_LOGOUT_ROUTE} from "services/constants/routes";
import {authTokenAtom, userInfoAtom} from "services/jotai/auth/msauth";


let renderCount = 0;
const SsoLogout = () => {
  renderCount += 1;
  const location = useLocation();
  const navigate = useNavigate();
  const {makeRequest} = useApiAuth();
  const [authToken, setAuthToken] = useAtom(authTokenAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const {setSnackBar} = useSnackBar();

  const fetchData = debounce(async () => {
    try {
      const data = await makeRequest("POST", MSAUTH_LOGOUT_ROUTE, {});
      setAuthToken("");
      setSnackBar(`Good bye ${userInfo?.firstName}`, "success");
      navigate("/msauth/login");

    } catch (err) {
      setSnackBar(`Error:` + err, "error");
      setAuthToken("");
      navigate("/msauth/login");
    }

  }, 300);

  useEffect(() => {
    fetchData();
  }, [authToken]);

  return (
    <div>
      <CircularProgress size="40" color="primary"/>
    </div>
  );
};

export default SsoLogout;
