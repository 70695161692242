import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link, useNavigate} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {cachedRequests} from "services/api/cachedRequests";
import {Brand} from "components/ui/table/components/cell/Brand";
import {StepperCell} from "components/ui/table/components/cell/Stepper";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {v4 as uuidv4} from "uuid";
import Button from "@mui/material/Button";
import FccPoliticalFetchNewFccDocuments from "../../../../components/ui/forms/political_fetch_new_fcc_documents";


const
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  IconButton = lazy(() => import("@mui/material/IconButton")),

  TerminalIcon = lazy(() => import("@mui/icons-material/Terminal")),
  ReceiptLongIcon = lazy(() => import("@mui/icons-material/ReceiptLong")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccPoliticalUploadQueue = (props: any) => {

  const
    navigate = useNavigate(),
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {convertDate, convertDatePretty, getCurrentYear, getArrayOfYears, daysBetweenDates} = useDate(),
    {makeRequest} = useApiAuth(),
    [year, setYear] = useState(2024),
    [market, setMarket] = useState(props?.market ? props.market : "My Markets"),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [errorState, setErrorState] = useState(null),
    uploadQueue = requestAtom("politicalUploadQueue", urlPaths.POLITICAL_DOCUMENT_ACTIVITY, {
      year: year,
      market: props?.facility_id || props?.uploaded_by ? undefined : props?.market ? [props.market] : market === "My Markets" ? allUserMarkets : [market], /*market,*/
      facility_id: props?.market || props?.uploaded_by ? undefined : props?.facility_id ? props.facility_id : undefined,
      uploaded_by: props?.uploaded_by ? props.uploaded_by : undefined,
      date_based_quarter: quarter
    }, setErrorState),
    [atomState, setAtomState] = useState(() => uploadQueue),
    [{data, isPending, isError}] = useAtom<AtomQuery>(atomState);

  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    return setAtomState(uploadQueue);
  }, [year, market, quarter, allUserMarkets]);

  const mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          upload_filename: string,
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          date_required: string,
          file_id: string,
          upload_status: string,
          ui_batch_id: string
        }
      }) => {
        return <Stack direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{width: "100%", minHeight: "100%", height: "max-content"}}
        >
          <Grid container spacing={3} sx={{width: "100%", minHeight: "100%", height: "max-content"}}>
            <Grid xs={2}>
              <Stack direction="column"
                justifyContent="center"
                spacing={0}
                sx={{width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <Brand {...{
                  callsign: params.row.callsign,
                  facility_id: params.row.facility_id,
                  service: params.row.service,
                  size: 50,
                  styles: {}
                }}
                />
              </Stack>
            </Grid>
            <Grid xs={10}>
              <Stack direction="column"
                justifyContent="center"
                spacing={-3}
                sx={{marginTop: "-20px", width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <p><strong>Uploaded:</strong> {convertDatePretty(params.row.upload_date)}</p>
                <p><strong>Market:</strong> <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link></p>
                <p><strong>Callsign:</strong> <Link
                  to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link></p>
                <p><strong>Status:</strong> {params.row.upload_status}</p>
                <p style={{marginTop: "-5px", height: "20px", marginBottom: "20px"}}>
                  <StepperCell {...{status: params.row.upload_status}} /></p>
                <p style={{display: "inherit"}}><strong>View Doc: </strong><Link
                  to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/political-candidates-and-issues/document/${params.row.file_id}` : ""}
                  key={params.row.upload_filename}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "calc(70%)",
                    pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
                    color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
                  }} rel="noreferrer"
                > {params.row.upload_filename.toString()}</Link></p>
              </Stack>
            </Grid>
            <Stack key={uuidv4()}
              sx={{width: "100%", minWidth: "fit-content", margin: "-5px 0px 5px 0px"}}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}>
              {/*<Link*/}
              {/*  to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}`}*/}
              {/*  key={uuidv4()}*/}
              {/*>*/}
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<TerminalIcon/>}
                onClick={(() => {
                  navigate(`/political-candidates-and-issues/queue/${params.row.ui_batch_id}`);
                })}
              >
                BATCH LOG
              </Button>
              {/*</Link>*/}
              {/*<Link*/}
              {/*  to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}/facility/${params.row.facility_id}`}*/}
              {/*  key={uuidv4()}*/}
              {/*>*/}
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<ReceiptLongIcon/>}
                onClick={(() => {
                  navigate(`/political-candidates-and-issues/queue/${params.row.ui_batch_id}/facility/${params.row.facility_id}`);
                })}
              >
                BRAND LOG
              </Button>
              {/*</Link>*/}
            </Stack>
          </Grid>
        </Stack>;
      }
    }],
    desktopColumns = [{
      field: "upload_date",
      id: "upload_date",
      headerName: "Uploaded",
      renderCell: (params: { row: { upload_date: string } }) => {
        return String(convertDatePretty(params.row.upload_date));
      },
      flex: 1,
    }, {
      field: "market",
      id: "market",
      headerName: "Market",
      width: 100,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      flex: 1,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "uploaded_by",
      id: "uploaded_by",
      align: "left",
      headerName: "Uploader",
      flex: 1,
      renderCell: (params: { row: { uploaded_by: string } }) => {
        return params.row.uploaded_by ?
          <p style={{textTransform: "capitalize"}}>{params.row.uploaded_by.split("@")[0].replace(".", " ")}</p> : null;
      }
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      // width: 250,
      // align: "center",
      cellClassName: "name",
      renderCell: (params: {
        row: { link: string, file_id: string, upload_filename: string, upload_status: string }
      }) => {
        if (params.row.upload_filename.toString().includes(".pdf") && params.row.upload_status.toLowerCase() === "verification completed") {
          return <Link
            to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/political-candidates-and-issues/document/${params.row.file_id}` : ""}
            // target={"_blank"}
            key={params.row.upload_filename}
            style={{
              pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
              color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params.row.upload_filename.toString()}</Link>;
        } else {
          return <p>{params.row.upload_filename.toString()}</p>;
        }
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return params.row.upload_status;
      }
    }, {
      field: "step",
      id: "step",
      align: "left",
      headerName: "Progress",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      flex: 3,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return <StepperCell {...{status: params.row.upload_status}} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      headerName: "Actions",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      // flex: 2,
      width: 120,
      renderCell: (params: {
        row: { callsign: string, ui_batch_id: string, facility_id: string, upload_status: string, file_id: string }
      }) => {
        return <Stack direction="row" spacing={0}>
          <Link
            to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}`}
          >
            <Tooltip
              title="Batch Upload Log"
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <TerminalIcon/>
              </IconButton>
            </Tooltip></Link>
          <Link
            to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}/facility/${params.row.facility_id}/document/${params.row.file_id}`}
          >
            <Tooltip
              title={`Upload For ${params.row.callsign}`}
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <ReceiptLongIcon/>
              </IconButton>
            </Tooltip>
          </Link>
        </Stack>;
      }
    }],
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload Political Documents",
        type: "link",
        href: "/political-candidates-and-issues/upload/"
      }, {
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: []
    },
    topperProps: any = {
      title: props?.title ? props.title : props.mobile ? "Upload Queue" : "Political Candidates & Issues Upload Queue",
      inputs: [{
        label: <span style={{fontSize: "10px"}}><FolderIcon
          sx={{marginRight: "5px", marginTop: "-5px"}}/>VIEW FCC POLITICAL FILES</span>,
        type: "button",
        display: props?.showFccLink ? props?.showFccLink : false,
        onClick: ((e: React.MouseEvent) => {
          window.open("/");
        })
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        options: getArrayOfQuarters(year),
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        options: marketsSelect,
        defaultValue: "My Markets",
        state: market,
        setState: setMarket,
        display: props?.market || props?.facility_id || props?.uploaded_by ? false : true,
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket(props?.market ? props.market : "My Markets");
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setYear(getCurrentYear());
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      {props.buttonBar ? <ButtonBar {...buttonBarButons} /> : <></>}
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        ...props,
        mobile: props.mobile,
        tableKey: props?.title ? props.title : "politicalUploadQueue",
        data: data?.items.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : desktopColumns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        minHeight: data?.items.length > 0 ? "400px" : undefined
      }} />
    </Suspense>
  );
};