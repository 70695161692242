import {useSnackBar} from "hooks/useSnackBar";
import React from "react";

/**
 * Usage
 *
 * IMPORT THE HOOK
 * import {useMainSnackBar} from "hooks/useMainSnackBar";
 *
 * INITIATE THE HOOK
 * const {setMainSnackBar} = useMainSnackBar();
 *
 * CALL THE HOOK
 * Add the call to the custom hook inside a axios request
 * postIssueAndPrograms({
 *         market: data.Markets,
 *         year: data.Years,
 *         callsigns: data.Callsigns,
 *         quarter: data.Quarters,
 *         file: data.PdfFile
 *       }).then((response: any) => {
 *         setMainSnackBar(response, {
 *           success: {
 *             message: "Successful custom",
 *             severity: "success"
 *           },
 *           error: {
 *             message: "Oh no there was an error!",
 *             severity: "error"
 *           }
 *         });
 *      });
 **/

export const useMainSnackBar = () => {

  const generalOptions = {
    success: {
      message: "Successful default response",
      severity: "success"
    },
    error: {
      message: "Not found",
      severity: "error"
    }
  };

  const {setSnackBar} = useSnackBar();

  // const setMainSnackBar = (request: any, options?: {
  //   success: { message: string, severity: string },
  //   error: { message: string, severity: string }
  // }) => {
  //   try {
  //     if (request.status && request.status === 200 || request.code && request.code === "success") {
  //       setSnackBar(options ? options.success.message : generalOptions.success.message, options ? options.success.severity : generalOptions.success.severity);
  //     } else {
  //       setSnackBar(options ? options.error.message : request.error.message, options ? options.error.severity : generalOptions.error.severity);
  //     }
  //   } catch (error) {
  //     setSnackBar("General Error", "error");
  //     console.log("Smart Snackbar Error", error);
  //   }
  // };

  const setMainSnackBar = (type: string, message?: string, severity?: string) => {
    try {
      if (type === "success" && message && severity) {
        setSnackBar(message, severity);
      } else if (type === "success" && !message && severity) {
        setSnackBar(generalOptions.success.message, severity);
      } else if (type === "success" && message && !severity) {
        setSnackBar(message, generalOptions.success.severity);
      } else if (type === "success" && !message && !severity) {
        setSnackBar(generalOptions.success.message, generalOptions.success.severity);
      }

      if (type === "error" && message && severity) {
        setSnackBar(message, severity);
      } else if (type === "error" && !message && severity) {
        setSnackBar(generalOptions.success.message, severity);
      } else if (type === "error" && message && !severity) {
        setSnackBar(message, generalOptions.success.severity);
      } else if (type === "error" && !message && !severity) {
        setSnackBar(generalOptions.success.message, generalOptions.success.severity);
      }
    } catch (error) {
      setSnackBar("General Error", "error");
      console.log("Smart Snackbar Error", error);
    }
  };

  const displayMainSnackBar = (message: string, severity: string) => {
    try {
      setSnackBar(message ? message : generalOptions.success.message, severity ? severity : generalOptions.success.severity);
    } catch (error) {
      setSnackBar("General Error", "error");
      console.log("Smart Snackbar Error", error);
    }
  };

  return {setMainSnackBar, displayMainSnackBar};
};