// styles.js
import {useTheme} from "@mui/material/styles";

// Define drawer width and header height constants
const drawerWidth = 260;
const HEADER_HEIGHT = 0;

const useLeftNavStyles = () => {
  const
    backgroundImage = "https://images.unsplash.com/photo-1621947081720-86970823b77a?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // backgroundImage = "https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png",
    theme = useTheme(),
    fontColor = "#b2b7b8",
    hoverFontColor = "#b8e6ed",
    buttonHighlightColor = "#00acc0",
    selectedColor = "#ff9326",
    white = "#fff",
    defaultIconColor = "#D0EEF2";

  return {
    drawerPaper: {
      color: fontColor,
      backgroundColor: "#050508",
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      paddingTop: 15,
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
      },
      width: 250,
      height: "100vh"
    },
    // Class for drawer
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth
      }
    },

    // Class for secondary section
    secondarySection: {
      margin: "40px 0"
    },
    userProfilePopover: {
      "& .MuiStack-root": {
        width: "200px",
        top: "20px",
        padding: "15px 5px 5px 5px",
        backgroundColor: "#00013E",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundAttachment: "fixed",
        // backgroundImage: `url(${backgroundImage})`,
      },
      "& .MuiButton-text": {
        color: "white",
        backgroundColor: "transparent",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)"
        }
      }
    },
    listItemStyles: {
      borderRadius: "3px",
      margin: "0px 25px",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      marginBottom: "5px",
      "&:hover": {
        backgroundColor: buttonHighlightColor,
        boxShadow: `0px 0px 15px 1px ${buttonHighlightColor}`,
        "& span": {
          color: hoverFontColor
        },
        "& svg": {
          stroke: hoverFontColor,
          fill: hoverFontColor,
          color: hoverFontColor
        }
      },
      "& > svg": {
        color: white
      },
      "& svg": {
        stroke: defaultIconColor,
        fill: defaultIconColor,
        color: defaultIconColor
      },
      selected: {
        "&.Mui-selected": {
          color: selectedColor,
          backgroundColor: "unset",

          "& span": {
            color: selectedColor
          },
          "&:hover": {
            backgroundColor: buttonHighlightColor
          },
          "& svg": {
            stroke: selectedColor,
            fill: selectedColor,
            color: selectedColor
          }
        }
      }
    },
    listItemIcon: {
      color: fontColor,
      minWidth: "40px",
      marginLeft: "10px",
      marginRight: 0,
    },
    listItemText: {
      // fontSize: "14px",
      textAlign: "left",
      margin: "0px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      display: "block",
    },

    // Class for secondary button
    secondaryBtn: {
      marginTop: 10,
      display: "flex",
      justifyContent: "start",
      backgroundColor: "#46494d",
      border: "1px solid #46494d",
      color: "#b2b7b8",
      fontSize: "14px",
      padding: "4px 0",
      margin: "10px 25px",
      alignItems: "center",
      lineHeight: "1.5",
      borderRadius: "3px",
      textDecoration: "none",
      transition: "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "#46494d",
        border: "1px solid #7c9397",
        boxShadow: "0px 0px 15px 1px #00ACC0",
        cursor: "pointer",
      },
      "&$selected": {
        color: "#ff9326"
      }
    },

    body: {
      minWidth: drawerWidth,
      position: "relative",
      flex: "none",
      zIndex: "1",
      boxShadow: "2px 0px 4px -1px rgba(0,0,0,.2), 4px 0px 5px 0px rgba(0,0,0,.14), 1px 0px 10px 0px rgba(0,0,0,.12)"
    },

    // Class for secondary text
    secondaryText: {
      paddingLeft: "16px"
    },

    // Class for secondary icon
    secondaryIcon: {
      marginLeft: "10px",
      color: "#D1EDF3"
    },

    // Class for toolbar
    toolbar: {
      height: 15,
      [theme.breakpoints.up("sm")]: {
        height: 100
      }
    },

    // Class for divider
    dividerStyleCb: {
      backgroundColor: "#454f5b",
      margin: theme.spacing(2)
    }
  };
};

export {useLeftNavStyles};