// styles.js
import {useTheme} from "@mui/material/styles";

// Define drawer width and header height constants
const drawerWidth = 260;
const HEADER_HEIGHT = 0;

const useStyles = () => {
  const
    theme = useTheme(),
    fontColor = "#b2b7b8",
    hoverFontColor = "#b8e6ed",
    buttonHighlightColor = "#00acc0",
    selectedColor = "#ff9326",
    white = "#fff",
    defaultIconColor = "#D0EEF2",
    disabledButtonColor = "#46494d",
    // backgroundImage = "https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png",
    backgroundImage = "https://images.unsplash.com/photo-1621947081720-86970823b77a?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return {
    loading_drawer: {
      color: fontColor,
      backgroundColor: "#050508",
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      width: drawerWidth,
    },
    drawerPaper: {
      color: fontColor,
      backgroundColor: "#050508",

      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      overflowY: "auto",
      paddingTop: 15,
      [theme.breakpoints.up("sm")]: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        width: drawerWidth,
        marginTop: HEADER_HEIGHT,
      }
    },
    // Class for drawer
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth
      }
    },

    // Class for secondary section
    secondarySection: {
      margin: "40px 0"
    },

    listItemStyles: {
      borderRadius: "3px",
      margin: "0px 25px",
      paddingLeft: "0px",
      paddingRight: "0px",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      marginBottom: "5px",
      "&:hover": {
        backgroundColor: buttonHighlightColor,
        boxShadow: `0px 0px 15px 1px ${buttonHighlightColor}`,
        "& span": {
          color: hoverFontColor
        },
        "& svg": {
          stroke: hoverFontColor,
          fill: hoverFontColor,
          color: hoverFontColor
        }
      },
      "& > svg": {
        color: white
      },
      "& svg": {
        stroke: defaultIconColor,
        fill: defaultIconColor,
        color: defaultIconColor
      },
      "&.Mui-selected": {
        backgroundColor: buttonHighlightColor,
        boxShadow: `0px 0px 15px 1px ${buttonHighlightColor}`,
        color: hoverFontColor,
        "& span": {
          color: hoverFontColor
        },
        "& svg": {
          stroke: hoverFontColor,
          fill: hoverFontColor,
          color: hoverFontColor
        },
        "&:hover": {
          backgroundColor: buttonHighlightColor,
          boxShadow: `0px 0px 15px 1px ${buttonHighlightColor}`,
          "& span": {
            color: hoverFontColor
          },
          "& svg": {
            stroke: hoverFontColor,
            fill: hoverFontColor,
            color: hoverFontColor
          }
        },
      },
      // "&.Mui-focusVisible": {
      //   backgroundColor: "#2e8b57"
      // },
      // ":hover": {
      //   backgroundColor: "#2e8b57"
      // }
      // selected: {
      //   "&.Mui-selected": {
      //     color: selectedColor,
      //     backgroundColor: selectedColor,
      //
      //     "& span": {
      //       color: selectedColor
      //     },
      //     "&:hover": {
      //       backgroundColor: buttonHighlightColor
      //     },
      //     "& svg": {
      //       stroke: selectedColor,
      //       fill: selectedColor,
      //       color: selectedColor
      //     }
      //   }
      // }
    },
    listItemDisabledStyles: {
      borderRadius: "3px",
      margin: "0px 25px",
      paddingLeft: "0px",
      paddingRight: "0px",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      marginBottom: "5px",
      color: "#b2b7b",
      "&:hover": {
        backgroundColor: disabledButtonColor,
        color: "white",
        boxShadow: `0px 0px 15px 1px ${disabledButtonColor}`,
        "& span": {
          color: "white"
        },
        "& svg": {
          stroke: "white",
          fill: "white",
          color: "white"
        }
      },
      "& > svg": {
        color: white
      },
      "& svg": {
        stroke: "white",
        fill: "white",
        color: "white"
      },
      selected: {
        "&.Mui-selected": {
          color: selectedColor,
          backgroundColor: "unset",

          "& span": {
            color: selectedColor
          },
          "&:hover": {
            backgroundColor: buttonHighlightColor
          },
          "& svg": {
            stroke: selectedColor,
            fill: selectedColor,
            color: selectedColor
          }
        }
      }
    },
    listItemIcon: {
      color: fontColor,
      minWidth: "40px",
      marginLeft: "10px",
      marginRight: 0,
    },
    listItemText: {
      // fontSize: "14px",
      textAlign: "left",
      margin: "0px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      display: "block",
    },

    // Class for secondary button
    secondaryBtn: {
      marginTop: 10,
      display: "flex",
      justifyContent: "start",
      backgroundColor: "#46494d",
      border: "1px solid #46494d",
      color: "#b2b7b8",
      fontSize: "14px",
      padding: "4px 0",
      margin: "10px 25px",
      alignItems: "center",
      lineHeight: "1.5",
      borderRadius: "3px",
      textDecoration: "none",
      transition: "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "#46494d",
        border: "1px solid #7c9397",
        boxShadow: "0px 0px 15px 1px #00ACC0",
        cursor: "pointer",
      },
      "&$selected": {
        color: "#ff9326"
      }
    },

    body: {
      minWidth: drawerWidth,
      position: "relative",
      flex: "none",
      zIndex: "1",
      boxShadow: "2px 0px 4px -1px rgba(0,0,0,.2), 4px 0px 5px 0px rgba(0,0,0,.14), 1px 0px 10px 0px rgba(0,0,0,.12)"
    },

    // Class for secondary text
    // secondaryText: {
    //   paddingLeft: "16px"
    // },

    // Class for secondary icon
    secondaryIcon: {
      marginLeft: "10px",
      color: "#D1EDF3"
    },

    // Class for toolbar
    toolbar: {
      height: 15,
      [theme.breakpoints.up("sm")]: {
        height: 100
      }
    },

    // Class for divider
    dividerStyleCb: {
      backgroundColor: "#454f5b",
      margin: theme.spacing(2)
    }
  };
};

export {useStyles};
//
// // Define styles using styled components
// // export const useStyles = () => {

//
//
// export const Drawer = styled("div")(({theme}) => ({
//   [theme.breakpoints.up("sm")]: {
//     width: drawerWidth,
//   },
// }));
//
// export const SecondarySection = styled("div")({
//   margin: "40px 0",
// });
//
// export const DrawerPaper = styled("div")(({theme}) => ({
//   backgroundColor: "#050508",
//   backgroundImage: `url(https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png)`,
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "center",
//   backgroundAttachment: "fixed",
//   backgroundSize: "cover",
//   paddingTop: 15,
//   [theme.breakpoints.up("sm")]: {
//     height: `calc(100vh - ${HEADER_HEIGHT}px)`,
//     width: drawerWidth,
//     marginTop: HEADER_HEIGHT,
//   },
// }));
//
// export const SecondaryBtn = styled("button")({
//   marginTop: 10,
//   display: "flex",
//   justifyContent: "start",
//   backgroundColor: "#46494d",
//   border: "1px solid #46494d",
//   color: "#b2b7b8",
//   fontSize: "14px",
//   padding: "4px 0",
//   margin: "0 15px",
//   alignItems: "center",
//   lineHeight: "1.5",
//   borderRadius: "3px",
//   textDecoration: "none",
//   transition: "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
//   "&:hover": {
//     border: "1px solid #7c9397",
//     boxShadow: "0px 0px 15px 1px #00ACC0",
//     cursor: "pointer",
//   },
//   "&$selected": {
//     color: "#ff9326",
//   },
// });
//
// export const SecondaryText = styled("div")({
//   paddingLeft: "16px",
// });
//
// export const SecondaryIcon = styled("div")({
//   marginLeft: "10px",
//   color: "#D1EDF3",
// });
//
// export const Toolbar = styled("div")({
//   height: 15,
//   [theme.breakpoints.up("sm")]: {
//     height: 100,
//   },
// });
//
// export const DividerStyleCb = styled("div")(({theme}) => ({
//   backgroundColor: "#454f5b",
//   margin: theme.spacing(2),
// }));
//
//
// // module.exports = {
// //   Drawer,
// //   SecondarySection,
// //   DrawerPaper,
// //   SecondaryBtn,
// //   SecondaryText,
// //   SecondaryIcon,
// //   Toolbar,
// //   DividerStyleCb,
// // };
// // };
//
// // import { useTheme } from "@mui/material/styles";
// // import { styled } from "@mui/system";
// // // import styled from "@emotion/styled";
// //
// // // Define drawer width and header height constants
// // const drawerWidth = 260;
// // const HEADER_HEIGHT = 70;
// //
// // // Define styles using styled components
// // export const useStyles = () => {
// //   const theme = useTheme();
// //
// //   return {

//     // Class for drawer
//     drawer: styled("div")({
//       [theme.breakpoints.up("sm")]: {
//         width: drawerWidth
//       }
//     }),
//
//     // Class for secondary section
//     secondarySection: styled("div")({
//       margin: "40px 0"
//     }),
//
//     // Class for drawer paper
//     drawerPaper: styled("div")({
//       backgroundColor: "#050508",
//       backgroundImage: `url(https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png)`,
//       backgroundRepeat: "no-repeat",
//       backgroundPosition: "center",
//       backgroundAttachment: "fixed",
//       backgroundSize: "cover",
//       paddingTop: 15,
//       [theme.breakpoints.up("sm")]: {
//         height: `calc(100vh - ${HEADER_HEIGHT}px)`,
//         width: drawerWidth,
//         marginTop: HEADER_HEIGHT
//       }
//     }),
//
//     // Class for secondary button
//     secondaryBtn: styled("button")({
//       marginTop: 10,
//       display: "flex",
//       justifyContent: "start",
//       backgroundColor: "#46494d",
//       border: "1px solid #46494d",
//       color: "#b2b7b8",
//       fontSize: "14px",
//       padding: "4px 0",
//       margin: "0 15px",
//       alignItems: "center",
//       lineHeight: "1.5",
//       borderRadius: "3px",
//       textDecoration: "none",
//       transition: "border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
//       "&:hover": {
//         border: "1px solid #7c9397",
//         boxShadow: "0px 0px 15px 1px #00ACC0",
//         cursor: "pointer"
//       },
//       "&$selected": {
//         color: "#ff9326"
//       }
//     }),
//
//     // Class for secondary text
//     secondaryText: styled("div")({
//       paddingLeft: "16px"
//     }),
//
//     // Class for secondary icon
//     secondaryIcon: styled("div")({
//       marginLeft: "10px",
//       color: "#D1EDF3"
//     }),
//
//     // Class for toolbar
//     toolbar: styled("div")({
//       height: 15,
//       [theme.breakpoints.up("sm")]: {
//         height: 100
//       }
//     }),
//
//     // Class for divider
//     dividerStyleCb: styled("div")({
//       backgroundColor: "#454f5b",
//       margin: theme.spacing(2)
//     })
// //   };
// // };