export default {
  MARKETS: "/craft/rest/markets2",
  ISSUES_ENDPONT: "/craft/rest/issues",
  ISSUES_UPLOADS: "/craft/rest/issues/upload",
  ISSUES_QUEUE: "/craft/rest/issues/uploadActivity",
  ISSUES_STATUS: "/craft/rest/issues/uploadStatus",
  ISSUES_STATUS_SUMMARY: "/craft/rest/issues/uploadStatusSummary",
  ISSUES_STATUS_COMPLIANCE: "/craft/rest/issues/compliance",
  ISSUES_DOCUMENT: "/craft/rest/document",
  POLITICAL_ISSUES: "/craft/rest/political-issues/",
  POLITICAL_MATCH: "/craft/rest/political-issues/match/",
  POLITICAL_MATCHES: "/craft/rest/political-issues/matches/",
  POLITICAL_MATCHES2: "/craft/rest/political-issues/matches",
  POLITICAL_MATCHES_ADD: "/craft/rest/political-issues/addmatch/",
  POLITICAL_MATCHES_SET: "/craft/rest/political-issues/setmatch/",
  POLITICAL_MATCHES_REMOVE: "/craft/rest/political-issues/removematch/",
  POLITICAL_MATCHES_DISMISS_MATCHING: "/craft/rest/political-issues/dismissmatching/",
  POLITICAL_DOCUMENT_UPLOAD: "/craft/rest/political-issues/upload/",
  POLITICAL_DOCUMENT_ACTIVITY: "/craft/rest/political-issues/uploadActivity/",
  POLITICAL_DOCUMENT_STATUS: "/craft/rest/political-issues/uploadStatus/",
  POLITICAL_FILE_BY_ID: "/craft/rest/political-issues/file/",
  POLITICAL_DOCUMENT_STATUSV2: "/craft/rest/political-issues/uploadActivityV2/",
  POLITICAL_DOCUMENT_STATUS_SUMMARY: "/craft/rest/political-issues/uploadStatusSummary/",
  POLITICAL_DOCUMENT: "/craft/rest/document/political",
  POLITICAL_FILES: "/craft/rest/file/political",
  POLITICAL_UPDATE_FCC_FILES: "/craft/rest/political-issues/updatePoliticalFiles",
  STATUS_PRODUCTION: "/craft/rest/status/production/",
  STATUS_RELEASE: "/craft/rest/status/release/",
  FILES: "/craft/rest/file",
  USER: "/craft/rest/user/",
  USER_DELETE_AVATAR: "/craft/rest/users/avatar/delete/",
  USER_ONBOARDING: "/craft/rest/user/onboarding/",
  USERS: "/craft/rest/users/",
  NOTIFICATIONS: "/craft/rest/notifications/",
  NOTIFICATION_REMOVE: "/craft/rest/notification/remove/",
  NOTIFICATIONS_REMOVE: "/craft/rest/notifications/remove/",
  SETTINGS: "/craft/rest/settings/"
};