import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);


// Using the custom local storage hook
// import {useQuarter} from "../hooks/useLocalStorage.tsx";
// const {setItem, getItem, removeItem} = useLocalStorage('someKey');
export const useQuarter = () => {

  const gracePeriod = 10;
  const getCurrentQuarter = () => {
    try {
      return dayjs(new Date()).subtract(gracePeriod, "days").quarter();
    } catch (error) {
      console.log(error);
    }
  };
  const getDocumentQuarter = (date: string) => {
    try {
      return dayjs(new Date(date)).subtract(gracePeriod, "days").quarter();
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentEndOfQuarter = (date: string) => {
    try {
      return new Date(dayjs(new Date(date)).subtract(gracePeriod, "days").endOf("quarter").toLocaleString()).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStartOfThisQuarter = () => {
    try {
      return new Date(dayjs(new Date()).startOf("quarter").toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  const getEndOfThisQuarter = () => {
    try {
      return new Date(dayjs(new Date()).endOf("quarter").toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  const getDaysRemainingInThisQuarter = () => {
    try {
      return dayjs(getEndOfThisQuarter()).diff(new Date(), "days");
      // return new Date(dayjs(new Date()).endOf("quarter").toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  const getStartOfNextQuarter = () => {
    try {
      return dayjs(new Date()).add(1, "quarter").startOf("quarter");
    } catch (error) {
      console.log(error);
    }
  };

  const daysRemainingBeforeNextDeadline = () => {
    try {
      return dayjs(getEndOfThisQuarter()).add(gracePeriod, "days").diff(new Date(), "days");
    } catch (error) {
      console.log(error);
    }
  };

  const lastDayInQuarterAsDayjs = (quarter: any, year: any) => {
    try {
      const quarters: any = {
        q1: `01/01/${year}`, // Q1: January, February, and March
        q2: `04/01/${year}`, // Q2: April, May, and June
        q3: `07/01/${year}`, // Q3: July, August, and September
        q4: `10/01/${year}` // Q4: October, November, and December
      };
      const date: any = (quarters) ? quarters[quarter.toString().toLowerCase()] : undefined;
      return dayjs(date).endOf("quarter").add(gracePeriod, "days");
    } catch (error) {
      console.log(error);
    }
  };
  const lastDayInQuarter = (quarter: string, year: string) => {
    try {
      const quarters: any = {
        q1: `01/01/${year}`, // Q1: January, February, and March
        q2: `04/01/${year}`, // Q2: April, May, and June
        q3: `07/01/${year}`, // Q3: July, August, and September
        q4: `10/01/${year}` // Q4: October, November, and December
      };
      const date: any = (quarters) ? quarters[quarter.toString().toLowerCase()] : undefined;
      return new Date(dayjs(new Date(date)).endOf("quarter").add(gracePeriod, "days").toLocaleString()).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric"
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Return the quarters in the year up to the current date
  const getArrayOfQuarters = (year: number, includeAll?: boolean) => {
    try {
      if (dayjs().year(year).year() === dayjs(new Date()).year()) {
        const currentQuarter = dayjs().subtract(gracePeriod, "days").quarter();
        const quarters = [];
        if (includeAll) {
          quarters.push("All Quarters");
        }
        for (let i = 0; i < currentQuarter; i++) {
          quarters.push(`Q${i + 1}`);
        }
        return quarters;
      } else {
        if (!includeAll) {
          return ["Q1", "Q2", "Q3", "Q4"];
        } else {
          return ["All Quarters", "Q1", "Q2", "Q3", "Q4"];
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const getItem = () => {
  //   try {
  //     const item = window.localStorage.getItem(key);
  //     return item ? JSON.parse(item) : undefined;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  //
  // const removeItem = () => {
  //   try {
  //     window.localStorage.removeItem(key);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return {
    lastDayInQuarter,
    lastDayInQuarterAsDayjs,
    getCurrentQuarter,
    getDocumentEndOfQuarter,
    getArrayOfQuarters,
    getDocumentQuarter,
    getStartOfThisQuarter,
    getEndOfThisQuarter,
    getDaysRemainingInThisQuarter,
    getStartOfNextQuarter,
    daysRemainingBeforeNextDeadline
  };
};