import Button from "@mui/material/Button";
import React, {lazy, Suspense, useEffect, useState} from "react";
import {NavLink as Link} from "react-router-dom";
import {useApiAuth} from "services/api/useApiAuth";
import {cachedRequests} from "services/api/cachedRequests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {useAtom} from "jotai";
import {allTheFacilitiesDataAsArray, allTheMarkets, allTheMarketsData} from "services/jotai/requests/requests";
import {v4 as uuidv4} from "uuid";

const
  Title = lazy(() => import("components/ui/pagetitle").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Box = lazy(() => import("@mui/material/Box")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));
export const Facilities = (props: any) => {
  const
    {cachedMarketsData} = cachedRequests(),
    [marketsSelect] = useAtom(allTheMarkets),
    [allMarketsData] = useAtom(allTheMarketsData),
    [allFacilitiesData] = useAtom<any>(allTheFacilitiesDataAsArray),
    {getDocumentEndOfQuarter, getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {getCurrentYear, getArrayOfYears, convertDate} = useDate(),
    {makeRequest} = useApiAuth(),
    {getDocumentQuarter} = useQuarter(),
    [compliant, setCompliance] = useState<any>(false),
    [year, setYear] = useState<any>(getCurrentYear()),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("All Markets"),
    [tableData, setTableData] = useState([]),
    [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true));

  useEffect(() => {
    // Reset the quarter dropdown if the user is switching between years so its not a blank select
    if (quarter !== "All Quarters" && Number(getCurrentYear()) <= year && Number(getCurrentQuarter()) < Number(quarter.split("Q")[1])) {
      setQuarter(`Q${getCurrentQuarter()}`);
    }
  }, [year, quarter, market, compliant]);


  useEffect(() => {
    if (compliant) {
      // Remove the all quarters select option when viewing compliant documents
      if (quarter === "All Quarters") {
        setQuarter(`Q${getCurrentQuarter()}`);
      }
      setQuartersArray(getArrayOfQuarters(year.toString(), false));
    } else {
      setQuartersArray(getArrayOfQuarters(year.toString(), true));
    }
  }, [year, quarter, market, compliant]);


  useEffect(() => {
    setTableData([]);
    setTableData(allFacilitiesData);
  }, [cachedMarketsData]);

  useEffect(() => {
    cachedMarketsData();
  }, []);


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          fcc_directory: string,
          date_required: string,
          documents: { link: string, name: string, date: string }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={3}>
          <Grid xs>
            <div style={{lineHeight: "normal"}}><Box
              component="img"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              loading="lazy"
              sx={{
                lineHeight: "normal",
                width: "auto",
                maxHeight: {xs: 90, md: 90},
              }}
              alt={`${params.row.callsign} Logo`}
              src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
            />
            </div>
          </Grid>
          <Grid xs sx={{float: "left"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
              <span>Market: {params.row.market}</span>
              <span>Callsign: {params.row.callsign}</span>
              <span>Service: {params.row.service}</span>
              <span>Facility ID: {params.row.facility_id}</span>
            </Stack>
          </Grid>
          <Grid xs={12} sx={{marginTop: "-15px", marginBottom: "5px"}}>
            <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
              <Stack direction="column" spacing={1}>{params?.row?.documents?.map((doc: {
                link: string,
                name: string,
                date: string
              }) => {
                return (
                  <Button key={uuidv4()} size={"small"} variant="contained"
                    href={`/craft/view/issues-and-programs/document/${doc.link}`}
                    sx={{boxShadow: 0, width: "100%"}}>
                    <Stack direction="row" spacing={2}>
                      <strong style={{
                        fontWeight: "600",
                        maxWidth: "180px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}>{doc.name}</strong>
                      <h5 style={{
                        fontWeight: "100"
                      }}>Q{getDocumentQuarter(doc.date)}</h5>
                      <h5 style={{
                        fontWeight: "100"
                      }}>{convertDate(doc.date)}
                      </h5>
                    </Stack></Button>);
              })} <Button variant="outlined"
                href={params.row.fcc_directory}
                sx={{boxShadow: 0, width: "100%"}}
                target={"_blank"}
                key={uuidv4()}>
                <FolderIcon/>
                <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
              </Button></Stack>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      // flex: 1,
      flex: true,
      renderCell: (params: { row: { market: string } }) => {
        return <Link
          to={`/market/${params.row.market.replace(/ /g, "%20")}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }, {
      field: "brand",
      headerName: "Brand",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service,
          size: 45,
        }} />;
      }
    }, {
      field: "callsign",
      headerName: "Callsign",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Link
          to={`/facility/${params.row.facility_id}/`}>{params.row.callsign.toString()}</Link>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }, {
      field: "facility_id",
      headerName: "Facility ID",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return <Stack direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2} sx={{height: "50px"}}><Link
            to={`/facility/${params.row.facility_id}/`}>{params.row?.facility_id.toString()}</Link></Stack>;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }, {
      field: "service",
      headerName: "Service",
      flex: 1,
      renderCell: (params: {
        row: { callsign: string, facility_id: string, id: string, market_id: string, service: string }
      }) => {
        return params.row.service;
      },
      valueGetter: (params: any) => {
        return params.toString();
      }
    }],

    buttonBarButons: any = {
      mobile: props.screen,
      left: [],
      right: []
    },
    topperProps: any = {
      title: "All Brands",
      inputs: []
    };


  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Brands",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Brands"]
      }} />
      {/*<ButtonBar {...buttonBarButons} />*/}
      <Topper {...{...topperProps, screen: props.mobile}} />
      <Table {...{
        tableKey: "facilitiesTable",
        mobile: props.mobile,
        data: tableData,
        columns: props.mobile ? mobileColumns : columns
      }} />
    </Suspense>
  );
};