import React, {lazy, Suspense} from "react";
import {Controller} from "react-hook-form";

const
  Dropzone: any = lazy(() => import("react-dropzone")),
  Skeleton: any = lazy(() => import("@mui/material/Skeleton")),
  Box: any = lazy(() => import("@mui/material/Box")),
  Stack: any = lazy(() => import("@mui/material/Stack")),
  DriveFolderUploadIcon: any = lazy(() => import("@mui/icons-material/DriveFolderUpload")),
  Typography: any = lazy(() => import("@mui/material").then((module: any) => ({default: module["Typography"]})));


type FormInputs = {
  file: FileList;
};
const TF = (props: any) => {
  // const form = useForm<FormInputs>();
  return (
    <Suspense fallback={<Skeleton variant="rectangular" animation="wave" width={"100%"} height={100}/>}>
      <Controller
        control={props.control}
        name={props.name}
        rules={{
          required: {
            value: props.required,
            message: props.validationMessage ? props.validationMessage : "Please upload a document"
          },
          value: {
            value: !props.fileCount || props.fileCount < 1,
            message: props.validationMessage ? props.validationMessage : "Please upload a document"
          }
        }}
        render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => (
          // render={({field: {onChange, onBlur}, fieldState}) => (
          <Dropzone
            noClick
            multiple={true}
            onDrop={(acceptedFiles: File[]) => {
              const file = acceptedFiles.length ? acceptedFiles : null;
              onChange(file);  // Update react-hook-form state
              props.onFileChange(file);  // Update parent state
            }}
            // onDrop={(acceptedFiles: any) => onChange(acceptedFiles[0])}
            // onDrop={(acceptedFiles:any) => {
            //   form.setValue("file", acceptedFiles as unknown as FileList, {
            //     shouldValidate: true,
            //   });
            // }}
            accept={props.mediaType ? props.mediaType : null}
          >
            {({getRootProps, getInputProps, open, isDragActive, acceptedFiles}: any) => (
              <Box sx={{
                margin: "0 auto",
                paddingTop: "10px",
                paddingBottom: "10px",
                width: props.width ? props.width : "100px"
              }}>
                <button type="button" onClick={open} style={{width: "100%"}}>
                  <Stack spacing={2} sx={{textAlign: "center"}}>
                    <Box
                      sx={{
                        // padding: "5px",
                        border: "1px dashed lightgrey",
                        borderStyle: "dashed",
                        backgroundColor: isDragActive ? `#808080` : "transparent",
                        color: "#1976d2",
                        fontWeight: "100",
                        fontSize: "14px",
                        paddingTop: "15px"
                      }}
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps({
                          id: "spreadsheet",
                          onChange,
                          // onChange: (e: any) => e.target.files,
                          onBlur
                        })}
                      />
                      {/*<Box sx={{width: "100%"}}>*/}
                      {/*  <DriveFolderUploadIcon fontSize={"large"} color={"primary"} sx={{fontWeight: "100"}}/>*/}
                      {/*</Box>*/}
                      <Box sx={{
                        width: "100%",
                        color: "#1976d2",
                        fontWeight: "normal",
                        fontSize: "15px",
                        // paddingBottom: "15px"
                      }}>
                        <Typography component="p" paragraph={false} sx={{
                          width: "100%",
                          color: "#1976d2",
                          fontWeight: "normal",
                          fontSize: "13px"
                        }}>{props.message ?
                            <strong>{props.message}</strong> : "Choose a file or drag and drop"}</Typography>
                        {props.fileCount > 0
                          ? <Typography component="p" paragraph={true}
                            sx={{fontSize: "12px"}}><strong>{props.fileCount}</strong> Files
                            Uploaded</Typography>
                          : <Typography component="p" paragraph={false} sx={{
                            width: "100%",
                            color: "#1976d2",
                            fontWeight: "normal",
                            fontSize: "13px"
                          }}>No files selected</Typography>}
                      </Box>
                    </Box>
                  </Stack>
                </button>
                <Box>
                  {error && (
                    <Typography component="p"
                      sx={{color: "red", fontSize: "0.75rem", marginTop: "5px", textAlign: "center"}}
                      role="alert">{error.message}</Typography>
                  )}
                </Box>
              </Box>
            )}
          </Dropzone>
        )}
      />
    </Suspense>
  );
};

export default TF;