import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MediaUploaderDropZone from "components/ui/forms/components/fields/MediaUploaderDropZoneMultiUseage";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import TextField from "components/ui/forms/components/fields/TextField";
import React, {lazy, useEffect, useState} from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {allTheFacilitiesData, allTheMarketsData} from "services/jotai/requests/requests";
import {useCallsignConverter} from "hooks/useCallsignConverter";
import {cachedRequests} from "services/api/cachedRequests";
import urlPaths from "../../../services/api/urlPaths";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {Button, Chip} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import {userInfoAtom} from "services/jotai/auth/msauth";

interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: string[],
  PdfFile: any,
  Files: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
  // Date: any
}

interface Market {
  name: string;
  id: string | number;
}

interface MarketOutput {
  label: string;
  id: string | number;
  facility_id?: string | number;
}

const
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  MatchingTable = lazy(() => import("pages/fcc_political_candidates_and_issues/components/matchingTable").then((module) => ({default: module["MatchingTable"]})));

const getMarkets = (allMarketsData: Market[]): MarketOutput[] => {
  const allMarkets: Set<MarketOutput> = new Set();

  allMarketsData?.forEach((market: Market) => {
    allMarkets.add({label: market.name, id: market.name});
  });

  return Array.from(allMarkets);
};
const getCallsigns = (selectedMarkets: any, tsMarkets: any) => {
  const allCallsigns: Set<any> = new Set([]);
  if (tsMarkets && selectedMarkets && selectedMarkets.length > 0) {
    if (typeof selectedMarkets === "string") {
      tsMarkets?.forEach((tsMarket: any) => {
        if (tsMarket["name"].toLowerCase() === selectedMarkets.toLowerCase()) {
          tsMarket.brands.forEach((brands: any) => {
            allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
          });
        }
      });
      return Array.from(allCallsigns);
    } else {
      tsMarkets?.forEach((tsMarket: any) => {
        selectedMarkets.forEach((selectedMarket: any) => {
          if (tsMarket["name"].toLowerCase() === selectedMarket.toLowerCase()) {
            tsMarket.brands.forEach((brands: any) => {
              allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
            });
          }
        });
      });
      return Array.from(allCallsigns);
    }
  } else {
    return [];
  }
};


export default function FccPoliticalCandidatesAndIssuesForm(props: any) {
  const
    {makeRequest, makeRequestAlternative} = useApiAuth(),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {
      getCurrentQuarter
    } = useQuarter(),
    navigate = useNavigate(),
    {
      getCurrentYear,
      getArrayOfYears,
      convertDatePretty
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    {getCallsign} = useCallsignConverter(),
    // [marketsSelect] = useAtom(allTheMarkets),
    [allMarketsData] = useAtom(allTheMarketsData),
    [allFacilitiesData] = useAtom(allTheFacilitiesData),
    [submitted, setSubmitted] = useState(false),
    [matchingData, setMatchingData] = useState([]),
    {makePoliticalFileUploadRequest} = useApiAuth(),
    // [userInfo] = useAtom(userInfoAtom),
    {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        Markets: [],
        Callsigns: [],
        PdfFile: null,
        Type: "",
        Files: [],
        FilePath: [],
        CandidateOrIssueName: "",
        CustomFolders: [],
        Years: getCurrentYear(),
        Quarters: `Q${getCurrentQuarter()}`,
      },
    }),
    [relatedDocs, setRelatedDocs] = useState([]),
    market = useWatch({control, name: "Markets"}),
    callsign = useWatch({control, name: "Callsigns"}),
    year: any = useWatch({control, name: "Years"}),
    files = useWatch({control, name: "Files"}),
    type = useWatch({control, name: "Type"}),
    filePath = useWatch({control, name: "FilePath"}),
    customFolders = useWatch({control, name: "CustomFolders"}),
    officeType = useWatch({control, name: "OfficeType"}),
    office = useWatch({control, name: "Office"}),
    customFolder = useWatch({control, name: "CustomFolder"}),
    candidateOrIssueName = useWatch({control, name: "CandidateOrIssueName"}),
    [tableData, setTableData] = useState<any>([]),
    [fileCount, setFileCount] = useState<number>(0),
    [marketNames, setMarketNames] = useState<any>([]),
    [marketCallsigns, setMarketCallsigns] = useState([]),
    yearOptions = getArrayOfYears(),
    // quarterOptions = getArrayOfQuarters(Number(year), false),
    {setMainSnackBar} = useMainSnackBar(),
    [searching, setSearching] = useState<any>({count: 0, searching: false, term: null}),
    getWideOrbitOrders = async () => {
      setSearching({count: searching.count, searching: true, term: candidateOrIssueName});
      await makeRequest("GET", urlPaths.POLITICAL_MATCHES, null, {
        year: year,
        type: type,
        matched: false,
        quarter: Number(getCurrentQuarter()),
        facility_id: callsign,
        search: candidateOrIssueName.toString(),
      }).then((response: any) => {
        if (response) {
          setMatchingData(response.items);
          setSearching({count: searching.count + 1, searching: false, term: null});
        }
      }).catch((error: any) => {
        setMainSnackBar("error", "Oh no there was an error adding your document!", "error");
        setMatchingData([]);
        console.log("error", error);
      });
    },
    resolver = async (data: any) => {
      const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

      console.log("submit data", data);
      const batchId = uuidv4().toString().replace(/-/gi, "");
      setSubmitted(true);
      let filesSubmitted = 0;
      await Object.keys(data.Files).map(async (facility: any) => {
        let index = 0;
        for await (const file of data.Files[facility].cleanFiles) {
          await console.log("submit data cleanFile", file.status, file?.file);
          await makePoliticalFileUploadRequest("POST", urlPaths.POLITICAL_DOCUMENT_UPLOAD, {
            ui_batch_id: batchId,
            fcc_path: filePath.join("/").toString(),
            year: data.Years.toString(),
            candidate_campaign_name: data.CandidateOrIssueName ? data.CandidateOrIssueName.toString() : String("Terms and Disclosures"),
            facility_id: facility,
            file: file?.file,
          }, null).then(async (response: any) => {
            if (response) {
              setValue(`Files.${facility}.cleanFiles[${index}].status`, "complete", {shouldValidate: true});
              index++;
              filesSubmitted++;
              if (props?.id && response?.items[0]?.file_id) {
                const resp = response?.items[0];
                // Add the new array item to the existing possibleMatches array
                await makeRequest("POST", urlPaths.POLITICAL_MATCHES_ADD, {
                  file_id: resp.file_id,
                  csv_id: props?.id,
                  username: userInfo.username,
                  facility_id: resp.facility_id,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  callsign: allFacilitiesData[resp?.facility_id]?.callsign,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  market_name: allFacilitiesData[resp?.facility_id]?.market,
                  name: file?.file?.name,
                  campaign_name: resp.candidate_campaign_name,
                  year: resp.year,
                  quarter: Number(resp.date_based_quarter),
                  date_based_quarter: Number(resp.date_based_quarter),
                  path: filePath.join("/").toString(),
                }, null).then((data: any) => {
                  console.log("Adding a new match", data);
                }).catch((error) => {
                  console.log("error", error);
                });
                await sleep(1000);
                await makeRequest("POST", urlPaths.POLITICAL_MATCHES_SET, null, {
                  csv_id: props.id,
                  file_id: resp.file_id,
                  username: userInfo.username
                }).catch((error) => {
                  console.log("error", error);
                });
              }
            }
          }).catch((error: any) => {
            setMainSnackBar("error", `Error adding document: (${file?.file?.name})`, "error");
            console.log("error", error);
          });
        }
      });
      await sleep(4000);
      await setMainSnackBar("success", `${filesSubmitted} new document successfully submitted!`, "success");
      await navigate(`/political-candidates-and-issues/queue/${batchId}`);
    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver(data);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      // const test = onSubmit;
      // handleSubmit(onSubmit);
      // handleSubmit().dispatchEvent(new Event('submit', { cancelable: true }))
      // setValue("CandidateOrIssueName", "a", {shouldValidate: true});
      // handleSubmit(onSubmit);
      // console.log("trying to submit 2", );
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);

  useEffect(() => {
    let count = 0;
    if (files && Object.keys(files).length > 0) {
      Object.keys(files)?.forEach((facility: any) => {
        if (files[facility].files) {
          count += files[facility].files.length;
        }
      });
      setFileCount(count);
    }
  }, [files]);

  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // Set the market names inside the select
  useEffect(() => {
    if (allMarketsData) {
      const allTsmMarkets: MarketOutput[] = getMarkets(allMarketsData);
      setMarketNames(allTsmMarkets);
    }
  }, [allMarketsData]);

  // Update the file path component when various details change inside the UI and send the final path to the backend
  useEffect(() => {
    setValue("FilePath", []);

    // officeType === Federal
    if (type === "Candidate" && officeType === "Federal") {
      if (customFolders && customFolders.length > 0) {
        setValue("FilePath", [String(year), officeType?.toString(), office?.toString(), ...customFolders, candidateOrIssueName?.toString()]);
      } else {
        setValue("FilePath", [String(year), officeType?.toString(), office?.toString(), ...customFolders, candidateOrIssueName?.toString()]);
      }
    }
    // Office type === Local OR State
    else if (type === "Candidate" && officeType === "Local" || type === "Candidate" && officeType === "State") {
      if (customFolders && customFolders.length > 0) {
        setValue("FilePath", [String(year), officeType?.toString(), ...customFolders, candidateOrIssueName?.toString()]);
      } else {
        setValue("FilePath", [String(year), officeType?.toString(), candidateOrIssueName?.toString()]);
      }
    }
    // officeType === "Non-Candidate Issue Ads"
    else if (type === "Non-Candidate Issue Ads") {
      if (customFolders && customFolders.length > 0) {
        setValue("FilePath", [String(year), type, ...customFolders, candidateOrIssueName?.toString()]);
      } else {
        setValue("FilePath", [String(year), type, candidateOrIssueName?.toString()]);
      }
    }
    // officeType === "Terms and Disclosures"
    else if (type === "Terms and Disclosures") {
      if (customFolders && customFolders.length > 0) {
        setValue("FilePath", [String(year), type, ...customFolders]);
      } else {
        setValue("FilePath", [String(year), type]);
      }
    }
  }, [year, type, officeType, office, candidateOrIssueName, customFolders]);


  // Update files data when new callsigns are selected
  useEffect(() => {
    const data: any = {};
    callsign.map((call: any) => {
      if (data[call]) {
        data[call] = {id: `${uuidv4()}`, facilityId: call, files: null, cleanFiles: null};
      } else {
        data[call] = {};
        data[call] = {id: `${uuidv4()}`, facilityId: call, files: null, cleanFiles: null};
      }
    });

    setTableData(Object.keys(data).map((key: any) => ({
      key: key,
      id: `${uuidv4()}`,
      ...data[key]
    })));

    setValue("Files", data);
  }, [callsign]);


  // Add possibly related documents when docs data is available
  useEffect(() => {
    const docs: any = [];
    if (props?.docs) {
      if (props?.docs[0]?.possibleMatches && props.docs[0]?.possibleMatches.length > 0) {
        props?.docs[0]?.possibleMatches.map((doc: any) => {
          if (doc.match) {
            docs.push(doc);
          }
        });
      }
      setRelatedDocs(docs);
    }
  }, [props?.docs]);

  //
  useEffect(() => {
    if (props?.docs) {
      if (props.docs[0]?.order_type) {
        if (props.docs[0]?.order_type.toLowerCase().includes("candidate")) {
          setValue("Type", "Candidate");
        } else if (props.docs[0]?.order_type.toLowerCase().includes("issue")) {
          setValue("Type", "Non-Candidate Issue Ads");
        } else if (props.docs[0]?.order_type.toLowerCase().includes("terms")) {
          setValue("Type", "Terms and Disclosures");
        }
      }

      if (props?.docs[0]?.advertiser) {
        setValue("CandidateOrIssueName", props.docs[0].advertiser);
      }

      setTimeout(() => {
        if (props?.docs[0]?.market) {
          setValue("Markets", [props.docs[0].market]);
        }
      }, 1000);

      setTimeout(() => {
        if (props?.docs[0]?.fcc_markets?.facId) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue("Callsigns", [Number(props?.docs[0]?.fcc_markets?.facId)]);
        }
      }, 3000);
    }
  }, [relatedDocs]);

  // Wipe callsign selections and repopulate the select options with new callsigns each time the markets dropdown changes
  useEffect(() => {
    setValue("Callsigns", []);
    const allCallSigns: any = getCallsigns(market, allMarketsData);
    setMarketCallsigns(allCallSigns);
  }, [market]);

  const matchColumns = [{
    field: "market_name",
    id: "market_name",
    headerName: "Market",
    flex: 1,
    // width: "120",
    renderCell: (params: { row: { market_name: string } }) => {
      return params.row.market_name;
    }
  }, {
    field: "callsign",
    id: "callsign",
    headerName: "callsign",
    flex: 1,
    // width: "90",
    renderCell: (params: { row: { callsign: string } }) => {
      return params.row.callsign;
    }
  }, {
    field: "candidate_campaign_name",
    id: "candidate_campaign_name",
    headerName: "Campaign",
    flex: 2,
    renderCell: (params: { row: { candidate_campaign_name: string } }) => {
      return params.row.candidate_campaign_name;
    }
  }, {
    field: "link",
    id: "link",
    headerName: "File",
    flex: 2,
    renderCell: (params: { row: { name: string, file_id: string, link: string } }) => {
      return params.row.link ? <Link to={`/political-candidates-and-issues/document/${params.row.file_id}`}
        style={{
          color: "#1976d2",
        }} rel="noreferrer"
      >{params.row.name.toString()}</Link> : params.row.name.toString();
    }
  }, {
    field: "upload_date",
    id: "upload_date",
    headerName: "Uploaded",
    flex: 1,
    // width: "110",
    renderCell: (params: { row: { upload_date: string } }) => {
      return convertDatePretty(params.row.upload_date, false);
    }
  }];
  const columns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      width: 120,
      renderCell: (params: { row: { facilityId: string } }) => {
        // console.log("getCallsign", getCallsign(params.row.facilityId));
        return (<Link
          to={`/market/${getCallsign(params.row.facilityId).market}/`}>{getCallsign(params.row.facilityId).market}</Link>);
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params: { row: { facilityId: string } }) => {
        return (<Link to={`/facility/${params.row.facilityId}/`}>{getCallsign(params.row.facilityId).callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
      renderCell: (params: { row: { facilityId: string } }) => {
        return getCallsign(params.row.facilityId).service;
      }
    }, {
      field: "key",
      id: "key",
      headerName: "Facility ID",
      headerAlign: "center",
      align: "center",
      width: 100,
    }, {
      field: "files",
      id: "files",
      headerName: "Add Files",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: { row: { key: string, id: string, facilityId: string, file: any } }) => {
        return <MediaUploaderDropZone
          name={`files.${params.row.facilityId}.files`}
          control={control}
          validationMessage={"Select at least one document for upload"}
          mediaType={{
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/rtf": [".rtf"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/plain": [".txt"],
          }}
          message={"Click to choose files or drag and drop"}
          required={true}
          width={"350px"}
          fileCount={files[Number(params.row.facilityId)]?.files?.length}
          onFileChange={async (file: any) => {
            // Logic to append new files to the uploads data
            const newVals: any = [];
            const cleanFiles: any = [];
            newVals.push(...file);

            file.map((f: any) => {
              cleanFiles.push({file: f, status: null, id: f.lastModified});
            });
            if (files[Number(params.row.facilityId)]?.files?.length > 0) {
              newVals.push(...files[Number(params.row.facilityId)].files);
              cleanFiles.push(...files[Number(params.row.facilityId)].cleanFiles);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await setValue(`Files.${params.row.facilityId}.files`, newVals, {shouldValidate: true});

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await setValue(`Files.${params.row.facilityId}.cleanFiles`, cleanFiles, {shouldValidate: true});
          }}
        />;
      }
    }, {
      field: "uploads",
      id: "uploads",
      headerName: "Selected Files",
      flex: 1,
      headerAlign: "center",
      align: "center",
      // type: "actions",
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      // getActions: (params: any) => {
      //   const fileArray = files && params?.row?.key ? files[Number(params?.row?.key)]?.file : [];
      //   if (fileArray && fileArray.length >= 0) {
      //     return [<Stack key={uuidv4()} direction="column" spacing={0}>{[fileArray.map((file: any) => {
      //       console.log("file", file.name);
      //       return (<GridActionsCellItem
      //         key={uuidv4()}
      //         label={file.name}
      //         icon={<Typography component="span" sx={{display: "flex", alignItems: "center"}}>
      //           {file.name}
      //           <DeleteOutlineIcon sx={{
      //             position: "relative",
      //             zIndex: 100000,
      //             color: "#1976d2",
      //             fontSize: "1.6rem",
      //             margin: "0 auto"
      //           }}/>
      //         </Typography>}
      //         onClick={() => {
      //           console.log("deleting stuff", file);
      //
      //           // Logic to remove deleted file from the uploads data
      //           const newVals: any = [];
      //
      //           files[Number(params.row.callsign)].file.map((f: any) => {
      //             if (f.name !== file.name) {
      //               newVals.push(f);
      //             }
      //           });
      //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //           // @ts-ignore
      //           return setValue(`Files.${params.row.callsign}.file`, newVals, {shouldValidate: true});
      //         }}
      //       />);
      //     })]}</Stack>];
      //   }
      //   return [];
      // }

      renderCell: (params: { row: { key: string, id: string, facilityId: string, cleanFiles: any } }) => {
        return <Grid container spacing={2}>
          <Grid item xs={6} md={10}><Stack direction="column"
            spacing={0}>{files && params?.row?.key && files[Number(params?.row?.key)]?.cleanFiles?.length >= 0 ? files[Number(params?.row?.key)]?.cleanFiles.map((file: any) => {
              return (<span key={uuidv4()} style={{overflow: "hidden", textOverflow: "ellipsis"}}>{file.file.name}</span>);
            }) : <></>}</Stack></Grid>
          <Grid item xs={6} md={2}><Stack direction="column"
            spacing={0}>{files && params?.row?.key && files[Number(params?.row?.key)]?.cleanFiles?.length >= 0 ? files[Number(params?.row?.key)]?.cleanFiles.map((file: any) => {
              if (file.status === null && !submitted) {
                return (<IconButton
                  key={uuidv4()}
                  sx={{margin: "0 auto"}}
                  onClick={() => {
                    console.log("deleting stuff", file);
                  // Logic to remove deleted file from the uploads data
                    const newVals: any = [];
                    const cleanFiles: any = [];
                    files[Number(params.row.facilityId)].files.map((f: any) => {
                      if (f.name !== file.file.name) {
                        newVals.push(f);
                      }
                    });
                    files[Number(params.row.facilityId)].cleanFiles.map((f: any) => {
                      if (f.file.name !== file.file.name) {
                        cleanFiles.push(f);
                      }
                    });
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                    setValue(`Files.${params.row.facilityId}.files`, newVals, {shouldValidate: true});
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                    setValue(`Files.${params.row.facilityId}.cleanFiles`, cleanFiles, {shouldValidate: true});

                  }}>
                  <DeleteOutlineIcon sx={{
                    color: "#1976d2",
                    fontSize: "1.6rem",
                    margin: "0 auto"
                  }}/>
                </IconButton>);
              } else if (file.status === null && submitted) {
                return (<IconButton
                  key={uuidv4()}
                  sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()}
                    sx={{color: "#ed6c02", margin: "0 auto"}}/></IconButton>);
              } else if (file.status === "complete" && submitted) {
                return (<IconButton
                  key={uuidv4()}
                  sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()}
                    sx={{color: "green", margin: "0 auto"}}/></IconButton>);
              }
            }) : <></>}</Stack></Grid></Grid>;
      }
    }],
    mobileColumns = [{
      field: "uploads",
      id: "uploads",
      headerName: "Selected Files",
      flex: 12,
      // width: "500px",
      headerAlign: "center",
      align: "center",
      // type: "actions",
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      renderCell: (params: { row: { key: string, id: string, facilityId: string, file: any } }) => {
        // console.log("getCallsign", getCallsign(params.row.facilityId));
        return <Stack direction="column" spacing={0}>
          <p><strong>Market:</strong> <Link
            to={`/market/${getCallsign(params.row.facilityId).market}/`}>{getCallsign(params.row.facilityId).market}</Link>
          </p>
          <p><strong>Callsign:</strong> <Link
            to={`/facility/${params.row.facilityId}/`}>{getCallsign(params.row.facilityId).callsign}</Link></p>
          <MediaUploaderDropZone
            name={`files.${params.row.facilityId}.files`}
            control={control}
            validationMessage={"Select at least one document for upload"}
            mediaType={{
              "application/pdf": [".pdf"],
              "application/msword": [".doc"],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
              "application/rtf": [".rtf"],
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
              "text/plain": [".txt"],
            }}
            message={"Click to choose files or drag and drop"}
            required={true}
            width={"350px"}
            fileCount={files[Number(params.row.facilityId)]?.files?.length}
            onFileChange={async (file: any) => {
              // Logic to append new files to the uploads data
              const newVals: any = [];
              const cleanFiles: any = [];
              newVals.push(...file);

              file.map((f: any) => {
                cleanFiles.push({file: f, status: null, id: f.lastModified});
              });
              if (files[Number(params.row.facilityId)]?.files?.length > 0) {
                newVals.push(...files[Number(params.row.facilityId)].files);
                cleanFiles.push(...files[Number(params.row.facilityId)].cleanFiles);
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await setValue(`Files.${params.row.facilityId}.files`, newVals, {shouldValidate: true});

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await setValue(`Files.${params.row.facilityId}.cleanFiles`, cleanFiles, {shouldValidate: true});
            }}
          />
          <Grid container spacing={0} sx={{width: "fit-content", margin: "0 auto !important"}}>
            <Grid item xs={10} md={12}>
              <Stack direction="column"
                spacing={0}>{files && params?.row?.key && files[Number(params?.row?.key)]?.cleanFiles?.length >= 0 ? files[Number(params?.row?.key)]?.cleanFiles.map((file: any) => {
                  return (
                    <span key={uuidv4()} style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "200px",
                    }}>{file.file.name}</span>);
                }) : <></>}</Stack>
            </Grid>
            <Grid item xs={2} md={12}>
              <Stack direction="column"
                spacing={0}>{files && params?.row?.key && files[Number(params?.row?.key)]?.cleanFiles?.length >= 0 ? files[Number(params?.row?.key)]?.cleanFiles.map((file: any) => {
                  if (file.status === null && !submitted) {
                    return (<IconButton
                      key={uuidv4()}
                      sx={{margin: "0 auto"}}
                      onClick={() => {
                        console.log("deleting stuff", file);
                      // Logic to remove deleted file from the uploads data
                        const newVals: any = [];
                        const cleanFiles: any = [];
                        files[Number(params.row.facilityId)].files.map((f: any) => {
                          if (f.name !== file.file.name) {
                            newVals.push(f);
                          }
                        });
                        files[Number(params.row.facilityId)].cleanFiles.map((f: any) => {
                          if (f.file.name !== file.file.name) {
                            cleanFiles.push(f);
                          }
                        });
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                        setValue(`Files.${params.row.facilityId}.files`, newVals, {shouldValidate: true});
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                        setValue(`Files.${params.row.facilityId}.cleanFiles`, cleanFiles, {shouldValidate: true});

                      }}>
                      <DeleteOutlineIcon sx={{
                        color: "#1976d2",
                        fontSize: "1.6rem",
                        margin: "0 auto"
                      }}/>
                    </IconButton>);
                  } else if (file.status === null && submitted) {
                    return (<IconButton
                      key={uuidv4()}
                      sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()}
                        sx={{color: "#ed6c02", margin: "0 auto"}}/></IconButton>);
                  } else if (file.status === "complete" && submitted) {
                    return (<IconButton
                      key={uuidv4()}
                      sx={{margin: "0 auto"}}><CircleIcon key={uuidv4()}
                        sx={{color: "green", margin: "0 auto"}}/></IconButton>);
                  }
                }) : <></>}
              </Stack>
            </Grid>
          </Grid>
        </Stack>;
      }
    }];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: marketNames && marketNames.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: marketCallsigns,
              disabled: marketCallsigns.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Candidate or Issue:</strong></p>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Type",
              label: "Type",
              multiSelect: false,
              validationMessage: "Select a type",
              options: ["Candidate", "Non-Candidate Issue Ads", "Terms and Disclosures"],
              required: true,
              control: control
            }}  />
            {type === "Candidate" ? <MultiSelect {...{
              name: "OfficeType",
              label: "Region",
              multiSelect: false,
              validationMessage: "Select a region",
              options: ["Federal", "Local", "State"],
              required: true,
              control: control
            }}  /> : <></>}
            {type === "Candidate" && officeType === "Federal" ?
              <MultiSelect {...{
                name: "Office",
                label: "Political Office",
                multiSelect: false,
                validationMessage: "Select an office",
                options: ["President", "US House", "US Senate"],
                required: true,
                control: control
              }}  /> : <></>}
            {type !== "Terms and Disclosures" ? <TextField {...{
              name: "CandidateOrIssueName",
              label: `${type} Name`,
              validationMessage: `Please include a ${type} name`,
              required: true,
              spacesAllowed: true,
              specialCharactersAllowed: true,
              control: control
            }} /> : <></>}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "1px"
          }}><strong>FCC File Location:</strong></p>
          <Stack direction="column" spacing={2}>
            <Breadcrumbs
              sx={{fontFamily: "arial", fontSize: "13px", fontWeight: "200", color: "black"}}
              separator="/"
              aria-label="breadcrumb">
              {filePath.map((item: any) => {
                return (<span key={item}>{item}</span>);
              })}
            </Breadcrumbs>
            <Stack direction="column" spacing={2}>
              {customFolders.length > 0 ? <><p style={{
                fontWeight: "100",
                fontSize: "13px",
                margin: "5px 0px -10px 0px",
              }}><strong>Custom Folders:</strong></p>
              <Stack direction="row" spacing={2}>
                {customFolders?.map((folder: any) => {
                  return <Chip
                    key={uuidv4()}
                    label={folder}
                    variant="outlined"
                    onClick={() => console.log(folder)}
                    onDelete={() => {
                      const index = customFolders.findIndex(item => item === folder);
                      index !== -1 && customFolders.splice(index, 1);
                      setValue("CustomFolders", [...customFolders]);
                    }}
                  />;
                })}
              </Stack>
              </> : <></>}
            </Stack>
            <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
              <TextField {...{
                name: "CustomFolder",
                label: `Custom Folder Name`,
                validationMessage: `Please include a folder name`,
                required: false,
                spacesAllowed: true,
                specialCharactersAllowed: true,
                control: control
              }} />
              <Button variant="contained" disableElevation size={"small"}
                onClick={() => {
                  if (customFolder !== "") {
                    setValue("CustomFolders", [...customFolders, customFolder]);
                    register("CustomFolder");
                    resetField("CustomFolder", {defaultValue: ""});
                  }
                }}
                sx={{backgroundColor: "rgb(162 162 162)", color: "white"}}>Add</Button>
            </Stack>
          </Stack>
        </Grid>
        {props?.docs && relatedDocs.length > 0 ? <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Associated Documents:</strong></p>
          <Table {...{
            tableKey: "matchedTable",
            mobile: props.mobile,
            data: relatedDocs.length > 0 ? relatedDocs : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: matchColumns,
            loading: false,
            error: false,
            errorMessage: "",
            minHeight: "400px",
          }} />
        </Grid> : <></>}
        {callsign && callsign?.length > 0 && type !== "Terms and Disclosures" && candidateOrIssueName.toString().length > 1 ?
          <Grid item xs={12}>
            <Stack direction="column" spacing={1}>
              <div style={{
                borderTop: "1px solid #e5e7eb"
              }}/>
              <p style={{
                fontWeight: "100",
                fontSize: "14px",
                marginTop: "14px",
                // marginBottom: "5px"
              }}><strong>WideOrbit Orders:</strong></p>
              <Stack direction="row" spacing={2} sx={{marginBottom: "5px"}}>
                <p style={{
                  fontWeight: "100",
                  fontSize: "14px",
                  marginTop: "5px",
                }}>Search for existing WideOrbit orders using the already supplied details?</p>
                <Button variant="contained" disableElevation size={"small"}
                  onClick={() => {
                    return getWideOrbitOrders();
                  }}
                  sx={{backgroundColor: "rgb(162 162 162)", color: "white", maxHeight: "30px"}}>Search</Button>
              </Stack>
              {searching.count >= 1 && !searching.searching && candidateOrIssueName.toString().length > 1 && matchingData && matchingData.length > 0 ?
                <MatchingTable {...{
                  data: matchingData && matchingData.length > 0 ? matchingData : [], // tableData && tableData?.length > 0 ? tableData : [],
                  // columns: matchingColumns,
                  pending: false,
                  tableKey: "politicalMatchingTable2",
                  mobile: props.mobile,
                  resetPagination: uuidv4(),
                  tableSortBy: "date_created",
                  subTableKey: "possibleMatches",
                  subTableSortBy: "score",
                  loading: false,
                  error: false,
                  errorMessage: "No WideOrbit orders located."
                }} />
                : !searching.searching && searching.count >= 1 && matchingData && matchingData.length === 0 ?
                  <p style={{fontSize: "13px", fontWeight: 200}}>No matching WideOrbit orders found.</p> : <></>}
            </Stack>
          </Grid> : <></>}
        {callsign && callsign?.length > 0 ? <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Document Upload:</strong></p>
          <Table {...{
            tableKey: "politicalCandidateUploadForm",
            mobile: props.mobile,
            data: tableData?.length > 0 ? tableData : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props?.mobile ? mobileColumns : columns,
            loading: false,
            error: false,
            errorMessage: "",
            minHeight: props?.mobile ? "500px" : "500px", /*"scaleWithDataSize",*/
            fileCount: fileCount,
            removeMobileMargin: true
          }} />
        </Grid> : <></>}
      </Grid>
      <ModalFooter {...{
        buttons: {
          left: [],
          right: [{
            variant: "contained",
            color: null,
            name: "Upload To FCC",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}