import React, {useEffect, useState} from "react";
import {useLeftNavStyles} from "../../nav/mobile/styles/left_nav_styles.js";
import {
  Avatar,
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from "@mui/material";
import {NavLink as Link} from "react-router-dom";
import styled from "@emotion/styled";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PolicyIcon from "@mui/icons-material/Policy";

export const NotificationCenter = (props: any) => {
  const
    classes = useLeftNavStyles(),
    {makeRequest} = useApiAuth(),
    [notificationCount, setNotificationCount] = useState(0),
    [notifications, setNotifications] = useState([]),
    [notificationDrawer, notificationDrawerSet] = useState<any>(false),
    {getCurrentQuarter} = useQuarter(),
    {getCurrentYear, convertDatePretty} = useDate(),
    toggleDrawer = (open: boolean) => (event: any) => {
      notificationDrawerSet(!open);
    };

  const getNotifications = async () => {
    await makeRequest("GET", `${urlPaths.NOTIFICATIONS}?username=${props.userInfo.username}`).then((data: any) => {
      setNotifications(data.notifications);
      setNotificationCount(data.count);
    }).catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const
    stringToColor = (string: string) => {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    },

    stringAvatar = (name: string) => {
      return {
        sx: {
          height: "30px",
          width: "30px",
          fontSize: "15px",
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1] ? name.split(" ")[1][0] : null}`,
      };
    };

  const
    NotificationsSidebar = styled(Box)`
      width: 300px;
      padding: 10px 10px 10px 10px;
      background-color: #00013E;
      //backgroundColor: "#00013E", minHeight: "100%"
      //position: fixed;
      overflow-y: auto;
      min-height: 100vh;
      height: auto;
      //max-height: max-content;
    `,
    CloseNotificationsButton = styled(IconButton)`
      color: #ffa400 !important;
      //float: right;
      right: 15px;
      top: 5px;
      position: absolute;
    `,
    DeleteAllNotificationsButton = styled(ListItemButton)`
      background-color: #ffa400 !important;
      margin: 5px;
      //padding: 15px 5px 5px 5px;
      //margin-top: 30px;
      border-radius: 3px;
      //background-color: #00013E;

      & span {
        font-size: 11px !important;
        //color: #ffa400 !important;
        color: #00013E !important;
      }

      & svg {
        //fill: #ffa400 !important;
        full: #00013E !important;
      }

      &:hover {
        box-shadow: 0px 0px 16px rgba(0, 196, 239, 0.6);
        background-color: rgba(255, 255, 255, 0.1);
      }
    `,
    NotificationItemButton = styled(ListItemButton)`
      margin: 5px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.1);

      &:hover {
        //background-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 16px rgba(0, 196, 239, 0.6);
        background-color: #ffa400;
      }
    `,
    NotificationListItem = styled(ListItemText)`
      & span {
        font-size: 11px !important;
        color: white;
      }
    `,
    NotificationListItemDeleteButton = styled(IconButton)`
      color: white;
      //float: right;
      right: 0px;
      top: 0px;
      position: absolute;

      & svg {
        height: 14px;
        width: 14px;
        //fill: #ffa400 !important;
        full: white;
      }
    `,
    NoNotificationsListItem = styled(ListItem)`
      color: white;
      text-align: center;
      margin: 30px 0px;
    `;

  const deleteAllNotifications = async () => {
    await makeRequest("POST", `${urlPaths.NOTIFICATIONS_REMOVE}?username=${props.userInfo.username}`).then((data: any) => {
      getNotifications();
    }).catch((error) => console.log("error", error));
  };
  const deleteNotification = async (data: any) => {
    console.log("notification deleted", data);
    await makeRequest("POST", `${urlPaths.NOTIFICATION_REMOVE}?username=${props.userInfo.username}&_id=${data._id}`).then((data: any) => {
      getNotifications();
    }).catch((error) => console.log("error", error));
  };
  const NotificationDeleteButton = (data: any) => {
    return <NotificationListItemDeleteButton onClick={(e) => deleteNotification(data.data)}>
      <CloseIcon/>
    </NotificationListItemDeleteButton>;
  };


  const notification_type_generic = (data: any) => {
    return <NotificationItemButton
      dense={true}
      disableGutters={false}
      divider={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      to={data?.href ? data?.href : undefined}
    >
      <ListItemIcon sx={{marginRight: "-15px", padding: "-15px"}}>
        <MailOutlineIcon/>
      </ListItemIcon>
      <NotificationListItem primary={<Stack direction="column" spacing={0}>
        <strong>{convertDatePretty(data?.date)}</strong>
        <strong>{data.title}</strong>
        <p>{data.message}</p>
      </Stack>}/>
      <NotificationDeleteButton {...{data}} />
    </NotificationItemButton>;
  };

  const notification_type_political = (data: any) => {
    return <NotificationItemButton
      dense={true}
      disableGutters={false}
      divider={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      to={data?.href ? data?.href : undefined}
    >
      <ListItemIcon sx={{marginRight: "-15px", padding: "-15px"}}>
        <PolicyIcon/>
      </ListItemIcon>
      <NotificationListItem primary={<Stack direction="column" spacing={0}>
        <strong>{convertDatePretty(data?.date)}</strong>
        <strong>{data.title}</strong>
        <p>{data.message}</p>
      </Stack>}/>
      <NotificationDeleteButton {...{data}} />
    </NotificationItemButton>;
  };
  const notification_type_issue = (data: any) => {
    return <NotificationItemButton
      dense={true}
      disableGutters={false}
      divider={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={Link}
      to={data?.type === "issues" && data?.meta?.facility_id ? `/issues-and-programs/queue/${data?.meta?.upload_process_status_id}/facility/${data?.meta?.facility_id}` : undefined}
    >
      <ListItemIcon sx={{marginRight: "-15px", padding: "-15px"}}>
        <AssuredWorkloadIcon/>
      </ListItemIcon>
      <NotificationListItem primary={<Stack direction="column" spacing={0}>
        {data?.meta?.facility_id ? <Avatar
          {...stringAvatar(`${data?.meta?.facility_id}`)}
          key={data?.meta?.facility_id}
          sx={{
            width: 20,
            height: 20,
            boxShadow: 2,
            fontSize: "13px",
            fontColor: "white",
            backgroundColor: "#010159",
            position: "absolute",
            top: "0",
            left: "0",
            margin: "-5px"
          }}
          alt={data?.meta?.facility_id}
          src={`/craft/rest/image?facility_id=${data?.meta?.facility_id}`}
        /> : <></>}
        <strong>{convertDatePretty(data?.date)}</strong>
        <strong>{data.title}</strong>
        <p>{data.message}</p>
        <p>{data?.meta?.upload_filename}</p>
      </Stack>}/>
      <NotificationDeleteButton {...{data}} />
    </NotificationItemButton>;
  };

  const list = () => (
    <NotificationsSidebar
      sx={{width: 300}}
      role="presentation"
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <CloseNotificationsButton onClick={() => notificationDrawerSet(!notificationDrawer)}>
            <CloseIcon/>
          </CloseNotificationsButton>
          <h1 style={{color: "white"}}>Notifications</h1>
        </Stack>
        <ListItem disablePadding>
          <DeleteAllNotificationsButton onClick={() => deleteAllNotifications()}>
            <ListItemIcon>
              <DeleteForeverIcon/>
            </ListItemIcon>
            <ListItemText primary={"Delete All Notifications"}/>
          </DeleteAllNotificationsButton>
        </ListItem>
      </Stack>
      <List>
        {notifications?.length > 0 ? notifications?.map((data: any) => (
          <>
            <ListItem key={data._id} disablePadding>
              {data?.type === "issues" ? notification_type_issue(data) : data?.type === "political" ? notification_type_political(data) : notification_type_generic(data)}
            </ListItem>
            <Divider/>
          </>
        )) : <NoNotificationsListItem key={"none"} disablePadding>
          <NotificationListItem>No Notifications</NotificationListItem>
        </NoNotificationsListItem>}
      </List>
    </NotificationsSidebar>
  );

  if (props.type === "desktop") {
    return <><Button
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // component={Button}
      // to={"/notifications/"}
      onClick={toggleDrawer(notificationDrawer)}
      variant="contained"
      sx={{
        backgroundColor: "transparent",
        fontSize: "12px",
        "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}
      }}
      startIcon={<Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        overlap="rectangular"
        badgeContent={notificationCount}
        color="warning"
        sx={{padding: "5px"}}
      >
        <NotificationsActiveIcon sx={{height: "18px", marginRight: "-4px"}}/>
      </Badge>}
    >Notifications</Button>
    <Drawer
      anchor={"right"}
      open={notificationDrawer}
      onClose={toggleDrawer(notificationDrawer)}
    >
      {notificationDrawer ? list() : <></>}
    </Drawer>
    </>;
  } else {
    return <>
      <ListItemButton
        sx={{...classes.listItemStyles, pl: 2}}
        dense={true}
        onClick={toggleDrawer(notificationDrawer)}>
        <ListItemIcon sx={classes.listItemIcon}>
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            overlap="rectangular"
            badgeContent={notificationCount}
            color="warning"
            // sx={{padding: "5px"}}
            // sx={leftNavClasses.body}
            // sx={{...leftNavClasses.listItemStyles, pl: 2}}
          >
            <NotificationsActiveIcon sx={{height: "18px", marginRight: "-4px"}}/>
          </Badge>
        </ListItemIcon>
        <ListItemText primary={"Notifications"} sx={classes.listItemText}/>
      </ListItemButton>
      <Drawer
        anchor={"right"}
        open={notificationDrawer}
        onClose={toggleDrawer(notificationDrawer)}
      >
        {notificationDrawer ? list() : <></>}
      </Drawer>
    </>;
  }
};