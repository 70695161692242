import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link} from "react-router-dom";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheMarkets} from "services/jotai/requests/requests";
import {Brand} from "components/ui/table/components/cell/Brand";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {TableCellModalButtonV2} from "components/ui/table/components/cell/ModalV2";
import {v4 as uuidv4} from "uuid";

const
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Button = lazy(() => import("@mui/material/Button")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  UploadFileIcon = lazy(() => import("@mui/icons-material/UploadFile")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Status = lazy(() => import("components/ui/table/components/cell/Status").then((module: any) => ({default: module["Status"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccIssuesAndProgramsComplianceReport = (props: any) => {
  const
    [marketsSelect] = useAtom(allTheMarkets),
    {
      getCurrentQuarter,
      lastDayInQuarter,
      lastDayInQuarterAsDayjs,
      getArrayOfQuarters
    } = useQuarter(),
    {getCurrentYear, getArrayOfYears, daysBetweenDates} = useDate(),
    [modalState, setModalState] = useState<boolean>(false),
    [modalData, setModalData] = useState<any>(),
    [compliant, setCompliance] = useState<any>(false),
    [year, setYear] = useState<any>(getCurrentYear()),
    [quarter, setQuarter] = useState<any>(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("All Markets"),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    complianceAtom = requestAtom("complianceCompliance", urlPaths.ISSUES_STATUS_COMPLIANCE, {
      year: year,
      market: market,
      quarter: quarter,
      compliant: 0
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(complianceState);

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    setComplianceState(complianceAtom);
  }, [year, quarter, market]);

  const mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      // width: 250,
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          id: string,
          quarter: string,
          year: string
          market: string,
          callsign: string,
          service: string,
          facility_id: string,
          fcc_directory: string,
          containing_folder_link: string,
        }
      }) => {
        return <Link to={`/facility/${params.row.facility_id}/`}>
          <Grid container spacing={3}>
            <Grid xs>
              <div style={{lineHeight: "normal"}}><Box
                component="img"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                loading="lazy"
                sx={{
                  lineHeight: "normal",
                  width: "auto",
                  maxHeight: {xs: 90, md: 90},
                }}
                alt={`${params.row.callsign} Logo`}
                src={`/craft/rest/image?facility_id=${params.row.facility_id}`}
              />
              </div>
            </Grid>
            <Grid xs sx={{float: "left"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
                <span>Market: {params.row.market}</span>
                <span>Callsign: {params.row.callsign}</span>
                <span>Service: {params.row.service}</span>
                <span>Facility ID: {params.row.facility_id}</span>
              </Stack>
            </Grid>
            <Grid xs={12} sx={{marginTop: "-15px", marginBottom: "5px"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
                <span>Quarter: {quarter}</span>
                <span>Date Due: {lastDayInQuarter(quarter, year)}</span>
                <Status {...{
                  ...props,
                  type: "days",
                  mobile: true,
                  compliant: compliant,
                  value: daysBetweenDates(lastDayInQuarterAsDayjs(quarter, year))
                }} />
                <Button variant="outlined"
                  href={params.row.containing_folder_link}
                  sx={{boxShadow: 0, width: "100%"}}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  target={"_blank"}>
                  <FolderIcon/>
                  <span style={{fontSize: "10px", marginLeft: "5px"}}>VIEW FCC ISSUES & PROGRAMS</span>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Link>;
      }
    }],
    columns = [{
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 150,
      // flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => <Brand {...{
        callsign: params.row.callsign,
        facility_id: params.row.facility_id,
        service: params.row.service
      }} />
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
      // flex: 1,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "date_required",
      id: "date_required",
      headerName: "Date Required",
      renderCell: () => {
        return <p>{lastDayInQuarter(quarter, year)}</p>;
      },
      flex: 1
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { upload_process_id: string, callsign: string } }) => {
        return <Status {...{
          ...props,
          type: "days",
          compliant: compliant,
          mobile: false,
          value: daysBetweenDates(lastDayInQuarterAsDayjs(quarter, year))
        }} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      type: "actions",
      headerName: "Actions",
      // flex: 1,
      width: 120,
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      getActions: (params: any) => [
        !compliant ? <GridActionsCellItem
          key={uuidv4()}
          label={uuidv4()}
          icon={<UploadFileIcon sx={{
            color: "#1976d2",
            fontSize: "1.6rem",
          }}/>}
          onClick={(() => {
            setModalData(params);
            setModalState(!modalState);
          })}
        /> : <></>
      ]
    }],

    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload PDF",
        type: "modal",
        modal: {
          modalTitle: "Upload A PDF",
          modalForm: FccIssuesAndProgramsForm,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [
                // {
                //   variant: "contained",
                //   color: null,
                //   name: "Save For Later",
                //   type: "",
                //   // disabled: null,
                //   // onClick: null
                // },
                {
                  variant: "contained",
                  color: null,
                  name: "Upload To FCC",
                  type: "submit",
                }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/"
      }]
    },
    topperProps: any = {
      title: "Compliance Report",
      inputs: [{
        label: "Year",
        type: "select",
        multiple: false,
        width: "150px",
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: getArrayOfQuarters(year),
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "All Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("All Markets");
          setYear(2024);
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    },
    modal = {
      name: "+ Upload PDF",
      type: "modal",
      modal: {
        state: modalState,
        updateModalState: setModalState,
        icon: UploadFileIcon,
        data: modalData,
        modalTitle: "Upload A PDF",
        modalForm: FccIssuesAndProgramsForm,
        modalFooter: {
          modalFooterButtons: {
            left: [{
              variant: "contained",
              color: "secondary",
              name: "cancel",
              type: null,
            }],
            right: [{
              variant: "contained",
              color: null,
              name: "Upload To FCC",
              type: "submit",
            }]
          }
        }
      }
    };


  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Issues And Programs",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Issues And Programs"]
      }} />
      <TableCellModalButtonV2 {...modal} />
      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        tableKey: "complianceTable",
        mobile: props.mobile,
        data: data?.length > 0 ? data : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : columns,
        loading: isPending,
        error: isError,
        errorMessage: errorState
      }} />
    </Suspense>
  );
};