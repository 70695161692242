import {atom, useAtom} from "jotai";

// Define atoms for modal state, title, and description
export const allTheMarkets = atom([]);

export const allTheMarketsWithMyMarkets = atom([]);
export const allTheMarketsData = atom([]);
export const allTheFacilitiesData = atom({});

export const allTheFacilitiesDataAsArray = atom([]);

export const allUserProfileSettings = atom({});

export const allUsersPrimaryAndSecondaryMarkets = atom([]);

// Getter and setter for modal title
export const useMarkets = () => useAtom(allTheMarkets);

// Getter and setter for modal description
export const useAllMarketsData = () => useAtom(allTheMarketsData);