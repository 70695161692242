import React, {Suspense, useState} from "react";
import Modal from "components/ui/modal/index";
import Skeleton from "@mui/material/Skeleton";
import {v4 as uuidv4} from "uuid";

export const TableCellModalButtonV2 = (props: any) => {
  const
    // [modalState, setModalState] = useState<boolean>(false),
    [submitButtonState, setModalSubmitState] = useState<boolean>(true);
  // updateModalState = () => setModalState(!modalState);

  return (<Modal
    key={uuidv4()}
    {...{
      open: props?.modal?.state,
      mobile: props?.mobile,
      handleChange: props?.modal?.updateModalState,
      title: props?.modal?.modalTitle,
      buttons: props?.modal?.modalFooter?.modalFooterButtons,
      submitButtonState: submitButtonState,
      setModalSubmitState: setModalSubmitState
    }}>
    <Suspense fallback={<Skeleton animation="wave" variant="rectangular" width={"100%"} height={450}/>}>
      <props.modal.modalForm
        key={uuidv4()}
        {...{
          open: props?.modal?.state,
          mobile: props?.mobile,
          handleChange: props?.modal?.updateModalState,
          title: props?.modal?.modalTitle,
          buttons: props?.modal?.modalFooter?.modalFooterButtons,
          submitButtonState: submitButtonState,
          setModalSubmitState: setModalSubmitState,
          data: props?.modal?.data
        }}/>
    </Suspense>
  </Modal>);
};
