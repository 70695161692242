import React, {lazy, Suspense, useEffect, useState} from "react";
import FccPoliticalCandidatesAndIssuesForm from "components/ui/forms/political_candidates_and_issues";
import {TableCellModalButtonV2} from "../../../components/ui/table/components/cell/ModalV2";
import {useAtom} from "jotai/index";
import {allTheMarkets} from "services/jotai/requests/requests";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import Paper from "@mui/material/Paper";
import {useMatch} from "react-router-dom";
import urlPaths from "../../../services/api/urlPaths";
import {useAtomWithQuery} from "hooks/useAtomWithQuery";

const
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Button = lazy(() => import("@mui/material/Button")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  UploadFileIcon = lazy(() => import("@mui/icons-material/UploadFile")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Status = lazy(() => import("components/ui/table/components/cell/Status").then((module: any) => ({default: module["Status"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccPoliticalUpload = (props: any) => {

  const [marketsSelect] = useAtom(allTheMarkets);
  const {getCurrentQuarter, getArrayOfQuarters} = useQuarter();
  const {getCurrentYear, getArrayOfYears, convertDate} = useDate();
  const [year, setYear] = useState<any>(getCurrentYear());
  const wideOrbitId = "/political-candidates-and-issues/upload/:id";
  const match = useMatch(wideOrbitId);
  const id = match?.params?.id;
  const [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`);
  const [market, setMarket] = useState("All Markets");
  const [submit, setSubmit] = useState(false);

  const [quartersArray, setQuartersArray] = useState(getArrayOfQuarters(year.toString(), true));
  const {getDocumentQuarter} = useQuarter();
  const [data, setData] = useState<any>([]);

  const {requestAtom} = useAtomWithQuery();
  const politicalMatches = id
    ? requestAtom("politicalMatch", urlPaths.POLITICAL_MATCH, {csv_id: id})
    : undefined;

  const [politicalMatchesState, setPoliticalMatchesState] = useState<any>(politicalMatches);
  let atomQueryResult: any = null;

  if (id && politicalMatchesState) {
    atomQueryResult = useAtom<AtomQuery>(politicalMatchesState)[0];
  }

  useEffect(() => {
    if (id && atomQueryResult) {
      setData(atomQueryResult?.data || []);
    }
  }, [id, atomQueryResult]);

  const buttonBarButons: any = {
    mobile: props.screen,
    left: [],
    right: [{
      name: "Share Feedback",
      type: "link",
      location: "external",
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/upload/"
    }]
  },
    topperProps: any = {
      title: !id || !data ? `Political Document Uploads` : data && data.length > 0 && data[0]?.advertiser ? `Uploads: ${data[0]?.advertiser}` : `Political Document Uploads`,
      inputs: [
        //   {
        //   label: "Year",
        //   type: "select",
        //   multiple: false,
        //   width: "150px",
        //   options: getArrayOfYears(),
        //   defaultValue: getCurrentYear(),
        //   state: year,
        //   setState: setYear
        // }, {
        //   label: "Quarter",
        //   type: "select",
        //   multiple: false,
        //   width: "200px",
        //   options: quartersArray,
        //   defaultValue: `Q${getCurrentQuarter()}`,
        //   state: quarter,
        //   setState: setQuarter
        // }, {
        //   label: "Market",
        //   type: "select",
        //   multiple: false,
        //   width: "300px",
        //   options: marketsSelect,
        //   defaultValue: "All Markets",
        //   state: market,
        //   setState: setMarket
        // },
        {
          label: "Upload To FCC",
          type: "button",
          onClick: ((e: React.MouseEvent) => {
            setSubmit(true);

            setTimeout(() => {
              setSubmit(false);
            }, 5000);
          })
        }
      ]
    };

  return (<Suspense fallback={<></>}>
    <Title {...{
      title: "Political Document Uploads",
    }} />
    <Breadcrumb {...{
      breadcrumb: ["Home", "Political Candidates & Issues"]
    }} />
    <TableCellModalButtonV2 {...{modal: {modalForm: FccPoliticalCandidatesAndIssuesForm}}} />
    <ButtonBar {...buttonBarButons} />
    <Topper {...{...topperProps, mobile: props.mobile}} />
    <Paper
      sx={{
        marginTop: "25px",
        borderRadius: "0px",
        margin: props?.mobile ? "25px 10px" : undefined
      }}
      elevation={props?.mobile ? 3 : 4}>
      <FccPoliticalCandidatesAndIssuesForm {...{
        docs: !data ? [] : data,
        data: data,
        id: id,
        mobile: props?.mobile,
        submit: submit
      }} />
    </Paper>
  </Suspense>);
};