import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {FccPoliticalUploadQueue} from "./upload_queue";
import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import {FccPoliticalFullReportTable} from "./full_report";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const MultiPoliticalTables = (props: any) => {
  const
    [politicalTab, setPoliticalTab] = useState<number>(0),
    handlePoliticalTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setPoliticalTab(newValue);
    },
    politicalTabProps = (index: number) => {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    },
    PoliticalFilesTabPanel = (props: TabPanelProps) => {
      const {children, value, index, ...other} = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && <Box>{children}</Box>}
        </div>
      );
    };

  {/* LIST OF POLITICAL DOCUMENTS UPLOADED IN THE MARKET */
  }
  return <Stack sx={{padding: "0px !important"}}>
    <Tabs centered value={politicalTab} onChange={handlePoliticalTabChange}
      aria-label="basic tabs example"
      style={{margin: "10px 0"}}
    >
      <Tab sx={{fontSize: "12px", padding: "5px"}} label="Full Report" {...politicalTabProps(0)} />
      <Tab sx={{fontSize: "12px", padding: "5px"}} label="Upload Queue" {...politicalTabProps(1)} />
    </Tabs>
    <PoliticalFilesTabPanel value={politicalTab} index={0}>
      <FccPoliticalFullReportTable {...{
        ...props,
        buttonBar: true,
        title: props?.market ? `All ${props?.market?.replace(/%20/gi, " ")} Political Files` : `All Political Files`,
        market: props?.market ? `${props?.market?.replace(/%20/gi, " ")}` : undefined,
        facility_id: props?.facility_id ? props.facility_id : undefined,
        showFccLink: true
      }} />
    </PoliticalFilesTabPanel>
    <PoliticalFilesTabPanel value={politicalTab} index={1}>
      <FccPoliticalUploadQueue {...{
        ...props,
        buttonBar: true,
        title: props?.market ? `${props?.market?.replace(/%20/gi, " ")}'s Political File Uploads` : `All Political File Uploads`,
        market: props?.market ? `${props?.market?.replace(/%20/gi, " ")}` : undefined,
        facility_id: props?.facility_id ? props.facility_id : undefined,
        showFccLink: true
      }} />
    </PoliticalFilesTabPanel>
  </Stack>;
};