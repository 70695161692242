// styles.js
// import {useTheme} from "@mui/material/styles";

// Define drawer width and header height constants
// const drawerWidth = 260;
const headerHeight = "70px";
const isMobile = false;
const useTopNavStyles = () => {
  const
    // theme = useTheme(),
    backgroundImage = "https://images.unsplash.com/photo-1621947081720-86970823b77a?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // backgroundImage = "https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png",
    backgroundColor = "#050508";

  return {
    loading_toolbar: {
      backgroundColor: backgroundColor,
      display: "block",
      zIndex: 1199,
      position: "sticky",
      top: 0,
      width: "100vw",
      height: headerHeight,
      color: "white"
    },
    appbar: {
      fontSize: "20px",
      position: "fixed",
      zIndex: (isMobile)? "10000" : "99999"
    },
    toolbar: {
      justifyContent: "space-between",
      backgroundColor: backgroundColor,
      height: headerHeight,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundImage: `url(${backgroundImage})`
    },
    logo: {
      width: "100%",
      height: "auto",
      display: "block",
      maxWidth: "126px",
      maxHeight: "50px",
    },
    nav: {
      display: "block",
      zIndex: 1199,
      position: "sticky",
      top: 0,
      width: "100vw",
      height: headerHeight
    },
    logolink: {
      // margin: "0 auto",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%"
    }
  };
};

export {useTopNavStyles};