// styles.js
// import {useTheme} from "@mui/material/styles";

// Define drawer width and header height constants
// const drawerWidth = 260;
const headerHeight = "70px";

const useStyles = () => {
  const
    // theme = useTheme(),
    // backgroundImage = "https://www.townsquareblueprint.com/crm/view/dist/bundles/b04db375f2788e20993a.png",
    backgroundImage = "https://images.unsplash.com/photo-1621947081720-86970823b77a?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    backgroundColor = "#050508";

  return {
    loading_toolbar: {
      backgroundColor: backgroundColor,
      display: "block",
      zIndex: 1199,
      position: "sticky",
      top: 0,
      width: "100vw",
      height: headerHeight,
      color: "white"
    },
    appbar: {
      fontSize: "20px",
      position: "fixed",
      zIndex: "10000"
    },
    toolbar: {
      justifyContent: "space-between",
      backgroundColor: backgroundColor,
      height: headerHeight,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundImage: `url(${backgroundImage})`
    },
    userProfilePopover: {
      "& .MuiStack-root": {
        width: "200px",
        top: "20px",
        padding: "15px 5px 5px 5px",
        backgroundColor: "#00013E",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundAttachment: "fixed",
        // backgroundImage: `url(${backgroundImage})`,
      },
      "& .MuiButton-text": {
        color: "white",
        backgroundColor: "transparent",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)"
        }
      }
    },
    logo: {
      width: "100%",
      height: "auto",
      display: "block",
      marginLeft: "10px",
      maxWidth: "200px",
      maxHeight: "50px"
    },
    nav: {
      display: "block",
      zIndex: 1199,
      position: "sticky",
      top: 0,
      width: "100vw",
      height: headerHeight
    }
  };
};

export {useStyles};