import React, {lazy, Suspense} from "react";
import {useAtom} from "jotai";
import {modalContent, modalStateAtom} from "services/jotai/modal";
import {v4 as uuidv4} from "uuid";

const
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  Button = lazy(() => import("@mui/material/Button")),
  PlayCircleOutlineIcon = lazy(() => import("@mui/icons-material/PlayCircleOutline")),
  LinkIcon = lazy(() => import("@mui/icons-material/Link")),
  ArticleIcon = lazy(() => import("@mui/icons-material/Article")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const Training = (props: any) => {
  const trainingData = [{
    name: "Dashboard",
    link: "/craft/view/",
    videos: [{
      id: uuidv4(),
      subject: "Personal Dashboard",
      link: "/craft/view/",
      video: "/craft/rest/training/SignalCraft%20-%20Dashboard.mp4",
      guide: ""
    }]
  }, {
    name: "Issues And Programs",
    link: "/craft/view/issues-and-programs/report/",
    videos: [{
      id: uuidv4(),
      subject: "Full Report",
      link: "/craft/view/issues-and-programs/report/",
      video: "/craft/rest/training/SignalCraft%20-%20Full%20Report.mp4",
      guide: ""
    }, {
      id: uuidv4(),
      subject: "Compliance Report",
      link: "/craft/view/issues-and-programs/compliance/",
      video: "",
      guide: ""
    }, {
      id: uuidv4(),
      subject: "Upload Queue",
      link: "/craft/view/issues-and-programs/queue/",
      video: "",
      guide: ""
    }]
    // }
    // , {
    //   name: "User Profile Settings",
    //   link: "/craft/view/profile/",
    //   videos: [{
    //     subject: "Editing Profile Settings",
    //     link: "/craft/view/profile/",
    //     video: "",
    //     guide: ""
    //   }
    //   ]
  }];

  const [modal, setModal] = useAtom(modalStateAtom);
  const [content, setContent] = useAtom(modalContent);

  const
    mobileColumns = [{
      field: "link",
      id: "link",
      headerName: "Screen",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      align: "center",
      renderCell: (params: { row: { link: string, subject: string, guide: string, video: string } }) => {
        return <div><h2><strong>Screen: </strong>{params.row.subject}</h2><p>
          <Button
            variant="contained"
            color="info"
            href={params.row.link}
            sx={{boxShadow: 0, minWidth: props.screen ? "100%" : undefined}}
            key={params.row.link}>
            <LinkIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>View Screen</span>
          </Button>
        </p>
        <p>
          <Button
            variant="contained"
            color="warning"
            onClick={(e: React.MouseEvent) => {
              window.open(params.row.guide);
            }}
            sx={{boxShadow: 0, minWidth: props.screen ? "100%" : undefined}}
            key={params.row.guide}>
            <ArticleIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>Read Guide</span>
          </Button>
        </p>
        <p>
          <Button
            variant="contained"
            color="success"
            onClick={(e: React.MouseEvent) => {
              setModal(!modal);
              setContent({
                title: params.row.subject,
                body: "",
                video: params.row.video
              });
            }}
            sx={{boxShadow: 0, minWidth: props.screen ? "100%" : undefined}}
            key={params.row.video}>
            <PlayCircleOutlineIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>Watch Video</span>
          </Button>
        </p>
        </div>;
      }
    }],
    columns = [{
      field: "subject",
      id: "subject",
      headerName: "subject",
      flex: 3
    }, {
      field: "link",
      id: "link",
      headerName: "Screen",
      flex: 1,
      renderCell: (params: { row: { link: string, subject: string } }) => {
        return <p>
          <Button
            variant="contained"
            color="info"
            href={params.row.link}
            sx={{boxShadow: 0}}
            key={params.row.link}>
            <LinkIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>View Screen</span>
          </Button>
        </p>;
      }
    }, {
      field: "guide",
      id: "guide",
      headerName: "Guide",
      flex: 1,
      renderCell: (params: { row: { guide: string } }) => {
        return <p>
          <Button
            variant="contained"
            color="warning"
            onClick={(e: React.MouseEvent) => {
              window.open(params.row.guide);
            }}
            sx={{boxShadow: 0}}
            key={params.row.guide}>
            <ArticleIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>Read Guide</span>
          </Button>
        </p>;
      }
    },
    {
      field: "video",
      id: "video",
      headerName: "Video",
      flex: 1,
      renderCell: (params: { row: { video: string, subject: string } }) => {
        return <p>
          <Button
            variant="contained"
            color="success"
            onClick={(e: React.MouseEvent) => {
              setModal(!modal);
              setContent({
                title: params.row.subject,
                body: "",
                video: params.row.video
              });
            }}
            sx={{boxShadow: 0}}
            key={params.row.video}>
            <PlayCircleOutlineIcon/>
            <span style={{fontSize: "10px", marginLeft: "5px"}}>Watch Video</span>
          </Button>
        </p>;
      }
    }];

  return (
    <Suspense fallback={<></>}>
      <div style={{backgroundColor: "transparent", margin: "0px 10px"}}>
        {trainingData.map((trainingSection: any) => {
          return (<div style={{marginBottom: "35px"}} key={trainingSection.name}>
            <Topper {...{
              title: trainingSection.name,
              inputs: [{
                label: "View Screen",
                type: "button",
                onClick: ((e: React.MouseEvent) => {
                  window.open(trainingSection.link);
                })
              }]
            }} /><Table {...{
              data: trainingSection.videos,
              selector: "subject",
              columns: props.screen ? mobileColumns : columns,
              pageSize: 10,
            // minHeight: "max-content",
            // maxHeight: "400px"
            } as any} /></div>);
        })}
      </div>
    </Suspense>
  );
};