import React, {lazy, Suspense, useEffect} from "react";
// import {useAtom} from "jotai";
// import {useNavigate} from "react-router-dom";
// import urlPaths from "services/api/urlPaths";
// import {useApiAuth} from "services/api/useApiAuth";
// import {useQuarter} from "hooks/useQuarter";
// import {useDate} from "hooks/useDate";
// import {allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {cachedRequests} from "services/api/cachedRequests";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {FccPoliticalUploadQueue} from "pages/fcc_political_candidates_and_issues/components/tables/upload_queue";
import FccPoliticalFetchNewFccDocuments from "../../../components/ui/forms/political_fetch_new_fcc_documents";


const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index"));

export const FccPoliticalQueue = (props: any) => {

  const
    // navigate = useNavigate(),
    {cachedMarketsData} = cachedRequests();
  // {requestAtom} = useAtomWithQuery(),
  // [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
  // [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
  // {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
  // {convertDate, convertDatePretty, getCurrentYear, getArrayOfYears, daysBetweenDates} = useDate(),
  // {makeRequest} = useApiAuth(),
  // [year, setYear] = useState(2024),
  // [market, setMarket] = useState("My Markets"),
  // [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
  // [errorState, setErrorState] = useState(null),
  // uploadQueue = requestAtom("politicalUploadQueue", urlPaths.POLITICAL_DOCUMENT_ACTIVITY, {
  //   year: year,
  //   market: market === "My Markets" ? allUserMarkets : [market], /*market,*/
  //   date_based_quarter: quarter
  // }, setErrorState),
  // [atomState, setAtomState] = useState(() => uploadQueue),
  // [{data, isPending, isError}] = useAtom<AtomQuery>(atomState);

  useEffect(() => {
    cachedMarketsData();
  }, []);

  // useEffect(() => {
  //   return setAtomState(uploadQueue);
  // }, [year, market, quarter, allUserMarkets]);

  const
    buttonBarButons: any = {
      left: [{
        name: "+ Upload Docs",
        type: "link",
        location: "internal",
        href: "/political-candidates-and-issues/upload/"
      }, {
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/queue/"
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues", "Upload Queue"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <FccPoliticalUploadQueue {...{...props, showFccLink: false}} />
    </Suspense>
  );
};