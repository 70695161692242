import React, {lazy} from "react";
import styled from "@emotion/styled";

const
  Button = lazy(() => import("@mui/material/Button")),
  Grid = lazy(() => import("@mui/material/Grid"));
const ModalFooter = (props: any) => {
  const
    FooterBox = styled(Grid)`
      background-color: lightcyan;
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      justify-content: space-between;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 15px;
      padding-top: 12px !important;
      margin-top: 10px !important;
    `,
    FooterContainer = styled(Grid)`
      display: contents;
    `,
    CloseButton = styled(Button)`
      background-color: rgb(97, 97, 97);

      &:hover {
        background-color: black;
      }
    `;

  return (
    <FooterContainer container spacing={2}>
      <FooterBox item xs={12}>
        <Grid item xs={6}>
          {props?.buttons?.left.map((button: any) => {
            if (button.name === "cancel") {
              return (<CloseButton
                size="small"
                key={button.name}
                sx={{float: "left", boxShadow: 0}}
                variant={button?.variant}
                color={button?.color}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  props.close();
                }}
              >{button.name}</CloseButton>);
            } else {
              return (<Button
                size="small"
                key={button?.name}
                sx={{float: "left", marginRight: "10px", boxShadow: 0}}
                disabled={props.submitButtonState}
                type={button?.type}
                variant={button?.variant}
              >{button.name}</Button>);
            }
          })}
        </Grid>
        <Grid item xs={6}>
          {props?.buttons?.right.map((button: any) => {
            if (button.name === "cancel") {
              return (<CloseButton
                size="small"
                key={button.name}
                sx={{float: "right", boxShadow: 0}}
                variant={button?.variant}
                color={button?.color}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  props.close();
                }}
              >{button.name}</CloseButton>);
            } else {
              return (<Button
                size="small"
                key={button?.name}
                sx={{float: "right", marginLeft: "10px", boxShadow: 0}}
                disabled={props.submitButtonState}
                type={button?.type}
                variant={button?.variant}
              >{button.name}</Button>);
            }
          })}
        </Grid>
      </FooterBox>
    </FooterContainer>
  );
};
export default ModalFooter;