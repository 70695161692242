import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
import {NavLink as Link} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {allTheMarkets} from "services/jotai/requests/requests";
import {cachedRequests} from "services/api/cachedRequests";
import {Brand} from "components/ui/table/components/cell/Brand";
import {StepperCell} from "components/ui/table/components/cell/Stepper";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {v4 as uuidv4} from "uuid";
import Button from "@mui/material/Button";


const
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  IconButton = lazy(() => import("@mui/material/IconButton")),

  TerminalIcon = lazy(() => import("@mui/icons-material/Terminal")),
  ReceiptLongIcon = lazy(() => import("@mui/icons-material/ReceiptLong")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccIssuesAndProgramsQueue = (props: any) => {

  const
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [marketsSelect] = useAtom(allTheMarkets),
    {getCurrentQuarter, getArrayOfQuarters} = useQuarter(),
    {getCurrentYear, getArrayOfYears, convertDatePretty} = useDate(),
    {makeRequest} = useApiAuth(),
    [year, setYear] = useState(2024),
    [market, setMarket] = useState("All Markets"),
    [quarter, setQuarter] = useState(`Q${getCurrentQuarter()}`),
    [errorState, setErrorState] = useState(null),
    uploadQueue = requestAtom("issuesUploadQueue", urlPaths.ISSUES_QUEUE, {
      year: year,
      market: market,
      quarter: quarter
    }, setErrorState),
    [atomState, setAtomState] = useState(() => uploadQueue),
    [{data, isPending, isError}] = useAtom<AtomQuery>(atomState);

  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    console.log("logging data", data);
    return setAtomState(uploadQueue);
  }, [year, market, quarter]);

  const mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          upload_filename: string,
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          date_required: string,
          file_id: string,
          upload_status: string,
          upload_process_status_id: string
        }
      }) => {
        return <Stack direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{width: "100%", minHeight: "100%", height: "max-content"}}
        >
          <Grid container spacing={3} sx={{width: "100%", minHeight: "100%", height: "max-content"}}>
            <Grid xs={2}>
              <Stack direction="column"
                justifyContent="center"
                spacing={0}
                sx={{width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <Brand {...{
                  callsign: params.row.callsign,
                  facility_id: params.row.facility_id,
                  service: params.row.service,
                  size: 50,
                  styles: {}
                }}
                />
              </Stack>
            </Grid>
            <Grid xs={10}>
              <Stack direction="column"
                justifyContent="center"
                spacing={-3}
                sx={{marginTop: "-10px", width: "100%", minHeight: "100%", height: "max-content"}}
              >
                <p><strong>Uploaded:</strong> {convertDatePretty(params.row.upload_date)}</p>
                <p><strong>Market:</strong> <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link></p>
                <p><strong>Callsign:</strong> <Link
                  to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link></p>
                <p><strong>Status:</strong> {params.row.upload_status}</p>
                <p style={{marginTop: "-5px", height: "20px", marginBottom: "20px"}}>
                  <StepperCell {...{status: params.row.upload_status}} /></p>
                <p><strong>View Doc: </strong><Link
                  to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/issues-and-programs/document/${params.row.file_id}` : ""}
                  key={params.row.upload_filename}
                  style={{
                    pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
                    color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
                  }} rel="noreferrer"
                >{params.row.upload_filename.toString()}</Link></p>
              </Stack>
            </Grid>
            <Stack key={uuidv4()}
              sx={{width: "100%", minWidth: "fit-content", margin: "-5px 0px 5px 0px"}}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}>
              <Link
                to={`/issues-and-programs/queue/${params.row.upload_process_status_id}`}
                key={uuidv4()}
              ><Button sx={{backgroundColor: "#1976d2", color: "white"}}
                  variant={"contained"}
                  endIcon={<TerminalIcon/>}>
                BATCH LOG
                </Button></Link>
              <Link
                to={`/issues-and-programs/queue/${params.row.upload_process_status_id}/facility/${params.row.facility_id}`}
                key={uuidv4()}
              >
                <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                  variant={"contained"}
                  endIcon={<ReceiptLongIcon/>}>
                  BRAND LOG
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Stack>;
      }
    }],
    desktopColumns = [{
      field: "upload_date",
      id: "upload_date",
      headerName: "Upload Date",
      renderCell: (params: { row: { upload_date: string } }) => {
        return String(convertDatePretty(params.row.upload_date));
      },
      flex: 2,
    }, {
      field: "market",
      id: "market",
      headerName: "Market",
      width: 100,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      flex: 1,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      flex: 1,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "uploaded_by",
      id: "uploaded_by",
      align: "left",
      headerName: "Uploader",
      flex: 1,
      renderCell: (params: { row: { uploaded_by: string } }) => {
        return params.row.uploaded_by ?
          <p style={{textTransform: "capitalize"}}>{params.row.uploaded_by.split("@")[0].replace(".", " ")}</p> : null;
      }
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      // width: 250,
      // align: "center",
      cellClassName: "name",
      renderCell: (params: { row: { link: string, file_id: string, upload_filename: string } }) => {
        if (params.row.upload_filename.toString().includes(".pdf")) {
          return <Link
            to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/issues-and-programs/document/${params.row.file_id}` : ""}
            // target={"_blank"}
            key={params.row.upload_filename}
            style={{
              pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
              color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params.row.upload_filename.toString()}</Link>;
        } else {
          return <p>{params.row.upload_filename.toString()}</p>;
        }
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return params.row.upload_status;
      }
    }, {
      field: "step",
      id: "step",
      align: "left",
      headerName: "Progress",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      flex: 3,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return <StepperCell {...{status: params.row.upload_status}} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      headerName: "Actions",
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      // flex: 2,
      width: 120,
      renderCell: (params: {
        row: { callsign: string, upload_process_status_id: string, facility_id: string, upload_status: string }
      }) => {
        return <Stack direction="row" spacing={0}>
          <Link
            to={`/issues-and-programs/queue/${params.row.upload_process_status_id}`}
          >
            <Tooltip
              title="Batch Upload Log"
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <TerminalIcon/>
              </IconButton>
            </Tooltip></Link>
          <Link
            to={`/issues-and-programs/queue/${params.row.upload_process_status_id}/facility/${params.row.facility_id}`}
          >
            <Tooltip
              title={`Upload For ${params.row.callsign}`}
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <ReceiptLongIcon/>
              </IconButton>
            </Tooltip>
          </Link>
        </Stack>;
      }
    }],
    buttonBarButons: any = {
      left: [{
        name: "+ Upload PDF",
        type: "modal",
        modal: {
          modalTitle: "Upload A PDF",
          modalForm: FccIssuesAndProgramsForm,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/"
      }]
    },
    topperProps: any = {
      title: props.screen ? "Upload Queue" : "Issues & Programs Upload Queue",
      inputs: [{
        label: "Year",
        type: "select",
        multiple: false,
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        options: getArrayOfQuarters(year),
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        options: marketsSelect,
        defaultValue: "All Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("All Markets");
          setYear(2024);
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Issues And Programs",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Issues And Programs"]
      }} />
      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        ...props,
        mobile: props.mobile,
        tableKey: "issuesQueue",
        data: data?.items.length > 0 ? data.items : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : desktopColumns,
        loading: isPending,
        error: isError,
        errorMessage: errorState
      }} />
    </Suspense>
  );
};