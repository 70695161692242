import React, {lazy, Suspense, useEffect, useState} from "react";
import {NavLink as Link, useMatch} from "react-router-dom";
import {useDate} from "hooks/useDate";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import urlPaths from "services/api/urlPaths";
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {v4 as uuidv4} from "uuid";
import {useAtom} from "jotai";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  Brands = lazy(() => import("components/ui/table/components/cell/Brands").then((module) => ({default: module["Brands"]}))),
  StepStatus = lazy(() => import("components/ui/table/components/cell/Status").then((module) => ({default: module["Status"]}))),
  StatusStepper = lazy(() => import("pages/status/components/Stepper").then((module) => ({default: module["StatusStepper"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  Avatar = lazy(() => import("@mui/material/Avatar")),
  AvatarGroup = lazy(() => import("@mui/material/AvatarGroup")),
  Box = lazy(() => import("@mui/material/Box")),
  Stack = lazy(() => import("@mui/material/Stack"));


export const Status = (props: any) => {
  const
    theme = useTheme(),
    {convertDateAndTimeStamp} = useDate(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    issuesQueueFacilityPath = "/issues-and-programs/queue/:id/facility/:facid",
    id = useMatch(issuesQueueFacilityPath)?.params.id,
    facilityId = useMatch(issuesQueueFacilityPath)?.params.facid,
    [topLevelData, setTopLevelData] = useState<any>(),
    [documentName, setDocumentName] = useState(""),
    [elapsedTime, setElapsedTime] = useState<string>("0 minutes"),
    [statusName, setStatusName] = useState(""),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Fetching and using our data
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    issueUploadStatusSummary = requestAtom(`issueUploadStatusSummary`, urlPaths.ISSUES_STATUS_SUMMARY, {
      upload_process_status_id: id
    }),
    issueUploadStatus = requestAtom(`issueUploadStatus`, urlPaths.ISSUES_STATUS, {
      upload_process_status_id: id
    }, setErrorState),
    [issuesSummaryState, setIssuesSummaryState] = useState<any>(() => issueUploadStatusSummary),
    [issuesUploadTableState, setIssuesUploadTableState] = useState<any>(() => issueUploadStatus),
    [{data: summaryData, isPending: summaryPending, isError: summaryError}] = useAtom<AtomQuery>(issuesSummaryState),
    [{data: tableData, isPending: tablePending, isError: tableError}] = useAtom<AtomQuery>(issuesUploadTableState),
    [sortedTableData, setSortedTableData] = useState([]),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Control states
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    [pageIs404, setPageIs404] = useState<any>(false),
    [refetchButton, setRefetchButton] = useState({display: true, disabled: true}),
    [refetch, setRefetch] = useState(true),
    [getData, setGetData] = useState<any>();

  // Initial get and set of the data
  useEffect(() => {
    setIssuesSummaryState(() => issueUploadStatusSummary);
    setIssuesUploadTableState(() => issueUploadStatus);
  }, []);

  useEffect(() => {
    if (summaryError || tableError) {
      setRefetch(false);
      setPageIs404(true);
    }
  }, [summaryError, tableError]);

  // Once table data is available manipulate it
  useEffect(() => {
    console.log("status", tableData);
    if (tableData?.length > 0) {
      const brandItems: any = [];
      tableData?.map((status: any) => {
        if (status.status_type === "file") {
          status.results.map((brand: any) => {
            if (facilityId && brand.facility_id === facilityId.toString()) {
              brandItems.push(status);
            }
            if (status.status === "file is live") {
              setRefetch(false);
            }
          });
        }
      });
      if (brandItems.length > 0) {
        setSortedTableData(brandItems.reverse());
      }
    }
  }, [tableData]);

  // Once summary data is available manipulate it
  useEffect(() => {
    if (summaryData?.summary) {
      if (summaryData?.summary?.percentage === 100) {
        setRefetch(false);
        // setControl(true);
      }
      setRefetchButton(summaryData?.summary?.percentage === 100 || summaryData?.summary?.last_status === "verification process completed" ? {
        display: false,
        disabled: false
      } : {display: true, disabled: true});
      // setRefetch(summaryData?.summary?.percentage === 100 ? false : true);
      // setControl(summaryData?.summary?.percentage === 100 ? true : false);
      setStatusName(summaryData?.summary.last_status);
      setTopLevelData(summaryData?.summary);
      setDocumentName(summaryData?.summary?.document_name);

      setElapsedTime(summaryData?.summary.elapsed_time_string);
      // setStatusTitle(summaryData?.summary.document_name);
      setStatusName(summaryData?.summary.last_status);
      setBreadCrumb(["Home", "issues-and-programs/queue/", summaryData?.summary?.document_name]);
    }
  }, [summaryData]);

  // Everytime getData is reset update the summary table data
  useEffect(() => {
    // setIssuesSummaryState(() => issuesSummary);
    setIssuesUploadTableState(() => issueUploadStatus);
  }, [getData]); // Empty dependency array ensures useEffect runs only once on mount

  // Timed loop that executes so long the control state remains False
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let totalTime = 0;
    const loopDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

    if (refetch) {
      setRefetchButton({display: true, disabled: true});
      intervalId = setInterval(() => {
        if (totalTime >= loopDuration) {
          setRefetch(false);
          setRefetchButton({display: true, disabled: false});
          clearInterval(intervalId);
        } else {
          setGetData(new Date().toISOString());
          totalTime += 5000; // Increase total time by 5 seconds
        }
      }, 5000); // 5 seconds interval
    }
    return () => clearInterval(intervalId); // Cleanup on component unmount or control state change
  }, [refetch]);


  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Upload Status",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          status_type: string,
          status_date: number,
          status: string,
          callsigns: string[],
          message: string,
          results: {
            facility_id: string,
            callsign: string,
            service: string,
            image: string,
            brand_name: string | null,
            internal_doc_link?: string,
            public_doc_link?: string,
            containing_folder_link?: string
          }[]
        }
      }) => {
        return <Grid key={uuidv4()} container spacing={4}>
          <Grid xs={12} sx={{marginBottom: "10px"}}>
            <Stack direction="row" spacing={2}
              sx={{lineHeight: "normal", marginTop: "5px", width: "fit-content", textWrap: "wrap"}}>
              <AvatarGroup max={6}>
                {params?.row?.results.map((result: { callsign: string, image: string }) =>
                  <Avatar key={result.callsign} sx={{boxShadow: 4}} alt={result.callsign}
                    src={result.image}/>)
                }
              </AvatarGroup>
              <StepStatus {...{
                ...props,
                type: "status",
                mobile: false,
                value: params.row.status
              }} />
            </Stack>
            <Stack direction="column" spacing={.5}
              sx={{lineHeight: "normal", marginTop: "5px", width: "fit-content", textWrap: "wrap"}}>
              <span>Type: {params.row.status_type}</span>
              <span>Date: {params.row.status_date}</span>
              <span>Status: {params.row.status}</span>
              <span>Message: {params.row.message}</span>
            </Stack>
          </Grid>
        </Grid>;
      }
    }],
    columns = [
      {
        field: "upload_date",
        id: "status_date",
        headerName: "Upload Date",
        maxWidth: 200,
        // align: "right",
        flex: 1,
        cellClassName: "modifiedDate",
        renderCell: (params: { row: { status_date: string } }) => {
          return <p>{convertDateAndTimeStamp(params.row.status_date)}</p>;
        }
      },
      {
        field: "status",
        id: "status",
        headerName: "Status",
        flex: 1,
        width: 200,
        renderCell: (params: { row: { status: string } }) => {
          return <StepStatus {...{
            ...props,
            type: "status",
            mobile: false,
            value: params.row.status
          }} />;
        }
      },
      {
        field: "message",
        id: "message",
        headerName: "Message",
        flex: 2
        // width: 250
      },
      {
        field: "results",
        id: "results",
        headerName: "Results",
        // maxWidth: 250,
        // align: "right",
        flex: 1,
        cellClassName: "modifiedDate",
        renderCell: (params: {
          row: {
            status: string,
            results: {
              facility_id: string,
              callsign: string,
              service: string,
              image: string,
              brand_name: any,
              // containing_folder_link: any
            }[]
          }
        }) => {
          if (params.row.status.includes("is live")) {
            return <details>
              <summary>
                View Upload Results
              </summary>
              <p>
                {params.row.results.map((result: any) => <Stack spacing={0} key={result.callsign}>
                  <Link to={"/"} target={"_blank"} rel="noreferrer">View {result.callsign} File</Link>
                  <Link to={result?.public_doc_link} target={"_blank"}
                    rel="noreferrer">Download {result.callsign} File</Link>
                  <Link to={result?.containing_folder_link} target={"_blank"} rel="noreferrer">View FCC Directory</Link>
                </Stack>)}
              </p>
            </details>;
          } else {
            return <p>-</p>;
          }
        }
      },
      {
        field: "brands",
        id: "brands",
        headerName: "Brands",
        width: 150,
        flex: 1,
        sortable: false,
        editable: false,
        disableColumnMenu: true,
        disableColumnFilter: true,
        disableColumnSelector: true,
        renderCell: (params: {
          row: {
            results: { facility_id: string, callsign: string, service: string, image: string, brand_name: any }[]
          }
        }) => {
          return <Brands {...{results: params.row.results}} />;
        }
      }
    ],
    topperProps: any = {
      title: documentName,
      inputs: [{
        label: "Refresh",
        type: "button",
        disabled: refetchButton.disabled,
        display: refetchButton.display,
        loader: true,
        onClick: ((e: React.MouseEvent) => {
          setRefetch(true);
        })
      }]
    };

  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <><Title {...{
        topLevelData: {
          screen: "queue",
          ...topLevelData
        },
        title: "Upload",
      }} />
      <Breadcrumb {...{
        breadcrumb: breadcrumb
      }} />
      <Box sx={{backgroundColor: "transparent", margin: props.screen ? "10px" : undefined, boxShadow: 4}}>
        <Topper {...{...topperProps}} />
        <Box sx={{
          width: "100%",
          backgroundColor: "#e0f7fa",
          zIndex: 10,
          padding: "20px 0px 0px 0px",
          position: "relative"
        }}>
          <Stack direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0} sx={{margin: "0 auto"}}
          >
            <StatusStepper {...{status: statusName}}/>
            <p style={{fontSize: "12px", fontWeight: "400", marginTop: "20px"}}><strong>Time
                Elapsed:</strong> {elapsedTime}</p>
          </Stack>
        </Box>
        <Table {...{
          data: sortedTableData ? sortedTableData : [],
          pageSize: 20,
          columns: props.screen ? mobileColumns : columns,
          selector: "status_data",
          loading: tablePending,
          error: tableError,
          errorMessage: errorState
        } as any} />
      </Box></> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're having difficulties locating this upload process."
      }} />}
    </Suspense>
  );
};