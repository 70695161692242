// import useShouldShowPrompt from "./useShouldShowPrompt";

// const androidInstallPromptedAt = "androidInstallPromptedAt";

// const isAndroid = (): boolean => {
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   if (window.navigator && window.navigator.standalone) {
//     //user has already installed the app
//     return false;
//   }
//   const ua = window.navigator.userAgent;
//   const isDroid = !!ua.match(/Android/i);
//   return isDroid;
// };
//
// const useAndroidInstallPrompt = (): [boolean, () => void] => {
//   const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(androidInstallPromptedAt);
//   return [isAndroid() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
// };
// export default useAndroidInstallPrompt;


import {useEffect, useState} from "react";
import useShouldShowPrompt from "hooks/pwa/useShouldShowPrompt";

const webInstallPromptedAt = "webInstallPromptedAt";


const useWebInstallPrompt = (): [any, () => void, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(webInstallPromptedAt);

  // const shouldShow = useShouldShowPrompt(webInstallPromptedAt);
  // Function to determine if the clients user agent is from an Android device
  const isAndroidAndShouldShow = (): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.navigator && window.navigator.standalone) {
      //user has already installed the app
      return false;
    }

    const ua = window.navigator.userAgent;
    const isDroid = !!ua.match(/Android/i);
    return (isDroid && userShouldBePromptedToInstall) ? true : false;
  };

  const [showPrompt, setShowPrompt] = useState<any>(isAndroidAndShouldShow());
  const [installPromptEvent, setInstallPromptEvent] = useState<any>();


  useEffect(() => {
    // handleUserSeeingInstallPrompt(useShouldShowPrompt(webInstallPromptedAt) && isAndroid());

    const beforeInstallPromptHandler = (event: any) => {

      event.preventDefault();
      // check if user has already been asked
      if (userShouldBePromptedToInstall) {
        // store the event for later use
        setInstallPromptEvent(event);
      }
    };
    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    return () => window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
  }, []);

  const handleInstallDeclined = () => {
    handleUserSeeingInstallPrompt();
    setInstallPromptEvent(null);
    setShowPrompt(false);
  };

  const handleInstallAccepted = () => {
    // show native prompt
    installPromptEvent.prompt();

    // decide what to do after the user chooses
    installPromptEvent.userChoice.then((choice: any) => {
      // if the user declined, we don't want to show the prompt again
      if (choice.outcome !== "accepted") {
        handleUserSeeingInstallPrompt();
      }
      setInstallPromptEvent(null);
    });
  };
  return [showPrompt, handleInstallDeclined, handleInstallAccepted];
};
export default useWebInstallPrompt;