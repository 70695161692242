import React from "react";
import {v4 as uuidv4} from "uuid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Tooltip from "@mui/material/Tooltip";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import StepConnector, {stepConnectorClasses} from "@mui/material/StepConnector";
import styled from "@emotion/styled";

export const StepperCell = (props: { status: string }) => {
  const returnStep = (status: string) => {
    const phaseOne = ["init file"];
    const phaseTwo = ["upload process ended", "cloud upload process error", "cloud upload done"];
    const phaseThree = ["cloud upload process ended", "fcc upload process error"];
    const phaseFour = ["fcc upload done", "fcc upload process ended", "verification process error", "verification process incomplete"];
    const phaseFive = ["verification completed"];

    if (phaseOne.indexOf(status) > -1) {
      return 1;
    } else if (phaseTwo.indexOf(status) > -1) {
      return 2;
    } else if (phaseThree.indexOf(status) > -1) {
      return 3;
    } else if (phaseFour.indexOf(status) > -1) {
      return 4;
    } else if (phaseFive.indexOf(status) > -1) {
      return 5;
    }
  };

  const returnToolTip = (step: number) => {
    if (step === 1) {
      return "File upload started";
    } else if (step === 2) {
      return "File uploaded to cloud storage";
    } else if (step === 3) {
      return "File uploaded to FCC";
    } else if (step === 4) {
      return "File upload verified on FCC";
    } else if (step === 5) {
      return "Upload complete";
    }
  };

  const steps = [
    "1",
    "2",
    "3",
    "4",
    "5",
  ];

  const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 12,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`& .${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // color: "linear-gradient( 130deg, rgb(0,208,250) 0%, rgb(0,124,210) 50%, rgb(0,42,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      backgroundColor: "red",
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: "#eaeaf0",
      // width: "5px",
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }));

  const issueStep: any = returnStep(props.status);
  return <Box><Stepper activeStep={issueStep} alternativeLabel sx={{
    // marginTop: "15px",
    width: "min-content",
    "& .MuiStepIcon-root.Mui-active ": {
      // color: "#CCCCCC",
    },
    "& span[aria-label='Upload complete'] .MuiStepIcon-root.Mui-completed": {
      // color: "#CCCCCC",
      color: "rgb(56, 142, 60)"
    }
  }}
  connector={<QontoConnector/>}
  >
    {steps.map((label) => (
      <Step key={uuidv4()}>
        <Tooltip
          title={returnToolTip(Number(label))}
          enterDelay={1200}
          // leaveDelay={500}
          TransitionProps={{timeout: 200}}
        >
          <StepLabel sx={{
            marginTop: "0px",
            "& .MuiStepLabel-label, .MuiStepLabel-alternativeLabel ": {
              marginTop: "0px",
            }
          }}></StepLabel>
        </Tooltip>
      </Step>
    ))}
  </Stepper></Box>;
};