const useStyles = () => {
  return {
    desktopcontent: {
      flex: "1",
      position: "relative",
      maxWidth: "calc(100% - 260px)",
      height: "calc(100vh - 70px)",
      overflowY: "auto",
      padding: "25px",
      overflowX: "hidden"
    },
    mobilecontent: {
      flex: "1",
      position: "relative",
      height: "calc(100vh - 70px)",
      overflowY: "auto",
      padding: "25px",
    }
  };
};

export {useStyles};