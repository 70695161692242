// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {firstNameAtom, userInfoAtom} from "services/jotai/auth/msauth";
// import {useDate} from "hooks/useDate";
import {NotificationCenter} from "../../../notifications/index";
import {Avatar} from "@mui/material";
import Box from "@mui/material/Box";
import {useAtom} from "jotai";
import React, {lazy, Suspense, useCallback} from "react";
import {NavLink as Link, useNavigate} from "react-router-dom";
import logo from "../../../../../assets/signalcraft-logo-darkBG.png";
import {useStyles} from "./styles/styles.js";
// import styled from "@emotion/styled";
import LogoutIcon from "@mui/icons-material/Logout";
// import urlPaths from "../../../../../services/api/urlPaths";
// import {useApiAuth} from "../../../../../services/api/useApiAuth";

// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
// import CloseIcon from "@mui/icons-material/Close";

const AppBar = lazy(() => import("@mui/material/AppBar"));
// const Link = lazy(() => import("@mui/material/Link"));
const Button = lazy(() => import("@mui/material/Button"));
const Typography = lazy(() => import("@mui/material/Typography"));
const Toolbar = lazy(() => import("@mui/material/Toolbar"));
const Popover = lazy(() => import("@mui/material/Popover"));
const Stack = lazy(() => import("@mui/material/Stack"));
const SettingsIcon = lazy(() => import("@mui/icons-material/Settings"));
export const TopAppBar = (props: any) => {
  const
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    [firstName] = useAtom(firstNameAtom);
  // {requestAtom} = useAtomWithQuery(),
  // [notificationCount, setNotificationCount] = useState(0),
  // [notifications, setNotifications] = useState([]),
  // {makeRequest} = useApiAuth(),
  // {convertDatePretty} = useDate();

  // useEffect(() => {
  //   makeRequest("GET", `${urlPaths.NOTIFICATIONS}?username=${userInfo.username}`).then((data: any) => {
  //     setNotifications(data.notifications);
  //     setNotificationCount(data.count);
  //   }).catch((error) => console.log("error", error));
  // }, []);


  // const
  //   NotificationsSidebar = styled(Box)`
  //     width: 300px;
  //     padding: 10px 10px 10px 10px;
  //     background-color: #00013E;
  //     height: 100vh;
  //   `,
  //   CloseNotificationsButton = styled(IconButton)`
  //     color: #ffa400 !important;
  //     //float: right;
  //     right: 5px;
  //     top: 5px;
  //     position: absolute;
  //   `,
  //   DeleteAllNotificationsButton = styled(ListItemButton)`
  //     background-color: #ffa400 !important;
  //     margin: 5px;
  //     //padding: 15px 5px 5px 5px;
  //     //margin-top: 30px;
  //     border-radius: 3px;
  //     //background-color: #00013E;
  //
  //     & span {
  //       font-size: 11px !important;
  //       //color: #ffa400 !important;
  //       color: #00013E !important;
  //     }
  //
  //     & svg {
  //       //fill: #ffa400 !important;
  //       full: #00013E !important;
  //     }
  //
  //     &:hover {
  //       background-color: rgba(255, 255, 255, 0.1);
  //     }
  //   `,
  //   NotificationItemButton = styled(ListItemButton)`
  //     margin: 5px;
  //     border-radius: 3px;
  //     background-color: rgba(255, 255, 255, 0.1);
  //
  //     &:hover {
  //       //background-color: rgba(255, 255, 255, 0.1);
  //       background-color: #ffa400;
  //     }
  //   `,
  //   NotificationListItem = styled(ListItemText)`
  //     & span {
  //       font-size: 11px !important;
  //       color: white;
  //     }
  //   `;

  const
    classes = useStyles(),
    navigate = useNavigate(),

    [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null),

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },

    handleClose = () => {
      setAnchorEl(null);
    },

    stringToColor = (string: string) => {
      let hash = 0;
      let i;

      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */

      return color;
    },

    stringAvatar = (name: string) => {
      return {
        sx: {
          height: "30px",
          width: "30px",
          fontSize: "15px",
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1] ? name.split(" ")[1][0] : null}`,
      };
    },

    handleLogout = useCallback(async () => {
      navigate("/msauth/logout");
    }, []);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // const [notificationDrawer, notificationDrawerSet] = useState<any>(false);
  // const toggleDrawer = (open: boolean) => (event: any) => {
  //   notificationDrawerSet(!open);
  // };


  // const list = () => (
  //   <NotificationsSidebar
  //     sx={{width: 300}}
  //     role="presentation"
  //     // onClick={toggleDrawer(notificationDrawer)}
  //   >
  //     <Stack direction="column" spacing={2}>
  //       <Stack direction="row" spacing={2}>
  //         <CloseNotificationsButton onClick={() => notificationDrawerSet(!notificationDrawer)}>
  //           <CloseIcon/>
  //         </CloseNotificationsButton>
  //         <h1 style={{color: "white"}}>Notifications</h1>
  //       </Stack>
  //       <ListItem disablePadding>
  //         <DeleteAllNotificationsButton>
  //           <ListItemIcon>
  //             <DeleteForeverIcon/>
  //           </ListItemIcon>
  //           <ListItemText primary={"Delete All Notifications"}/>
  //         </DeleteAllNotificationsButton>
  //       </ListItem>
  //     </Stack>
  //
  //
  //     <List>
  //       {notifications?.map((data: any) => (
  //         <>
  //           <ListItem key={data._id} disablePadding>
  //             <NotificationItemButton
  //               dense={true}
  //               disableGutters={false}
  //               divider={true}
  //               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //               // @ts-ignore
  //               component={Link}
  //               to={data?.type === "issues" && data?.meta?.facility_id ? `/issues-and-programs/queue/${data?.meta?.upload_process_status_id}/facility/${data?.meta?.facility_id}` : undefined}
  //             >
  //               <ListItemIcon sx={{marginRight: "-15px", padding: "-15px"}}>
  //                 <AssuredWorkloadIcon/>
  //               </ListItemIcon>
  //               <NotificationListItem primary={<Stack direction="column" spacing={0}>
  //                 {data?.meta?.facility_id ? <Avatar
  //                   {...stringAvatar(`${data?.meta?.facility_id}`)}
  //                   key={data?.meta?.facility_id}
  //                   sx={{
  //                     width: 20,
  //                     height: 20,
  //                     boxShadow: 2,
  //                     fontSize: "13px",
  //                     fontColor: "white",
  //                     backgroundColor: "#010159",
  //                     position: "absolute",
  //                     top: "0",
  //                     left: "0",
  //                     margin: "-5px"
  //                   }}
  //                   alt={data?.meta?.facility_id}
  //                   src={`/craft/rest/image?facility_id=${data?.meta?.facility_id}`}
  //                 /> : <></>}
  //                 <strong>{convertDatePretty(data?.date)}</strong>
  //                 <strong>{data.title}</strong>
  //                 <p>{data.message}</p>
  //                 <p>{data?.meta?.upload_filename}</p>
  //               </Stack>}/>
  //             </NotificationItemButton>
  //           </ListItem>
  //           <Divider/>
  //         </>
  //       ))}
  //     </List>
  //
  //   </NotificationsSidebar>
  // );


  return (
    <Suspense fallback={<div style={{...classes.loading_toolbar} as any}></div>}>
      <nav style={{...classes.nav as any}}>
        <AppBar sx={classes.appbar} position="fixed">
          <Toolbar sx={classes.toolbar} variant="dense">
            <Link to={"/"}>
              <img style={{...classes.logo}}
                src={logo}/>
            </Link>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              {/*<Avatar {...imageAvatar("")} />*/}
              <div>
                <Button aria-describedby={id} variant="contained" onClick={handleClick}
                  sx={{
                    boxShadow: 0,
                    backgroundColor: "transparent",
                    "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}
                  }}
                >
                  <Avatar alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    {...stringAvatar(`${userInfo.firstName} ${userInfo.lastName}`)}
                    src={`/craft/rest/users/avatar/${userInfo.username}`}
                    sx={{
                      boxShadow: 9,
                      height: "30px",
                      width: "30px",
                      fontSize: "14px",
                      bgcolor: stringToColor(`${userInfo.firstName} ${userInfo.lastName}`)
                    }}/>
                  <Typography variant="h6" color="inherit"
                    style={{color: "white", fontSize: "12px", textDecoration: "none", marginLeft: "10px"}}>
                    {(userInfo.firstName) ? userInfo.firstName : firstName + " " + userInfo?.lastName}
                  </Typography>
                </Button>
                <Popover
                  sx={classes.userProfilePopover}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    <Button
                      sx={{color: "#ffa400 !important", fontSize: "12px"}}
                      onClick={handleLogout}
                      startIcon={<LogoutIcon sx={{height: "15px", fontSize: "14px", marginRight: "2px"}}/>}
                    >LOGOUT</Button>
                  </Stack>
                </Popover>
              </div>
              <NotificationCenter {...{userInfo: userInfo, type: "desktop"}}/>
              {/*<Button*/}
              {/*  // component={Button}*/}
              {/*  // to={"/notifications/"}*/}
              {/*  onClick={toggleDrawer(notificationDrawer)}*/}
              {/*  variant="contained"*/}
              {/*  sx={{*/}
              {/*    backgroundColor: "transparent",*/}
              {/*    fontSize: "12px",*/}
              {/*    "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}*/}
              {/*  }}*/}
              {/*  startIcon={<Badge*/}
              {/*    anchorOrigin={{*/}
              {/*      vertical: "top",*/}
              {/*      horizontal: "right",*/}
              {/*    }}*/}
              {/*    overlap="rectangular"*/}
              {/*    badgeContent={notificationCount}*/}
              {/*    color="warning"*/}
              {/*    sx={{padding: "5px"}}*/}
              {/*  >*/}
              {/*    <NotificationsActiveIcon sx={{height: "18px", marginRight: "-4px"}}/>*/}
              {/*  </Badge>}*/}
              {/*>Notifications</Button>*/}
              {/*<Drawer*/}
              {/*  anchor={"right"}*/}
              {/*  open={notificationDrawer}*/}
              {/*  // variant="persistent"*/}
              {/*  onClose={toggleDrawer(notificationDrawer)}*/}
              {/*>*/}
              {/*  {notificationDrawer ? list() : <></>}*/}
              {/*</Drawer>*/}
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Link}
                to={"/profile/"}
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  fontSize: "12px",
                  "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}
                }}
                startIcon={<SettingsIcon sx={{height: "18px", marginRight: "-4px"}}/>}
              >Settings</Button>
            </Box>
          </Toolbar>
        </AppBar>
      </nav>
    </Suspense>
  );
};