import React, {lazy, Suspense, useEffect, useState} from "react";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {NavLink as Link, useMatch} from "react-router-dom";
import {useDate} from "hooks/useDate";

const
  Box = lazy(() => import("@mui/material/Box")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Typography = lazy(() => import("@mui/material/Typography")),
  NotFound = lazy(() => import("pages/not_found/index")),
  PdfView = lazy(() => import("components/ui/pdf/index").then((module) => ({default: module["PdfView"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const Pdf = (props: any) => {
  const
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [documentData, setDocumentData] = useState<any>(),
    issuesBatchQueuePath = "/issues-and-programs/document/:id",
    id = useMatch(issuesBatchQueuePath)?.params.id,
    {convertDate} = useDate(),
    {makeRequest} = useApiAuth(),
    [pageIs404, setPageIs404] = useState<any>(false);


  useEffect(() => {
    const params: any = {file_id: id};
    makeRequest("GET", urlPaths.ISSUES_DOCUMENT, null, params).then((data: any) => {
      setDocumentData(null);
      setDocumentData(data.items);
      setBreadCrumb(["Home", `facility/${data.items.facility_id}`, `${data.items.name}`]);
      if (data?.items?.containing_folder_link) {
        setPageIs404(false);
      } else {
        setPageIs404(true);
      }
    }).catch((error) => {
      console.log("error", error);
      setPageIs404(true);
    });
  }, [props]);

  const customHeader = () => {
    return (<Box key={"customHeaderComponent"} sx={{fontSize: "11px"}}>
      <Stack spacing={{xs: 1, sm: 2, md: 4}} direction="row" sx={{width: "max-content"}}>
        <Typography sx={{fontSize: "12px"}}
          variant="subtitle2"><strong>Status:</strong> {documentData?.upload_status}</Typography>
        <Typography sx={{fontSize: "12px"}} variant="subtitle2"><strong>Upload
          Date:</strong> {convertDate(documentData?.upload_date)}
        </Typography>
        <Typography sx={{fontSize: "12px"}} variant="subtitle2"><strong>Year:</strong> {documentData?.year}
        </Typography>
        <Typography sx={{fontSize: "12px"}}
          variant="subtitle2"><strong>Quarter:</strong> Q{documentData?.quarter}
        </Typography>
      </Stack>
      <Stack spacing={{xs: 1, sm: 2, md: 4}} direction="row" sx={{width: "max-content"}}>
        <Typography sx={{fontSize: "12px"}} variant="subtitle2"><strong>Facility
          ID:</strong> <Link
          to={`/facility/${documentData?.facility_id}`}>{documentData?.facility_id}</Link></Typography>
        <Typography sx={{fontSize: "12px"}} variant="subtitle2"><strong>File
          ID:</strong> {documentData?.file_id}
        </Typography>
      </Stack>
      <Stack spacing={{xs: 1, sm: 2, md: 4}} direction="row" sx={{width: "max-content"}}>
        <Typography sx={{fontSize: "12px"}} variant="subtitle2"><strong>FCC
          Page:</strong> <a target="_blank"
          href={documentData?.containing_folder_link}
          rel="noreferrer">{documentData?.containing_folder_link.split("Issues")[0]}</a>
        </Typography>
      </Stack>
    </Box>);
  };

  const
    topperProps: any = {
      title: `${documentData?.name}`,
      href: `${documentData?.containing_folder_link}`,
      inputs: [{
        type: "custom",
        component: customHeader
      }]
    };

  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <><Title {...{
        title: "Issues And Programs Document",
      }} />
      <Breadcrumb {...{
        breadcrumb: breadcrumb
      }} /><Stack direction="column" spacing={0}
        sx={{
          margin: "0 10px",
          width: "auto",
          overflow: "hidden",
          boxShadow: 2,
          marginBottom: "150px"
        }}>
        <Topper {...{...topperProps}} />
        <PdfView {...{pdfUrl: `/craft/rest/file?file_id=${id}`}} />
      </Stack></> : <NotFound {...{
        mobile: props.mobile,
        message: "We're sorry, we're having difficulties locating this document."
      }} />}
    </Suspense>
  );
};