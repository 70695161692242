import React, {lazy, Suspense, useEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link, useMatch} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAtom} from "jotai/index";
import {v4 as uuidv4} from "uuid";
import Button from "@mui/material/Button";
import {useDate} from "hooks/useDate";
import {cachedRequests} from "services/api/cachedRequests";
// import {useApiAuth} from "services/api/useApiAuth";
import _ from "lodash";

const
  Grid = lazy(() => import("@mui/material/Grid")),
  Box = lazy(() => import("@mui/material/Box")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  IconButton = lazy(() => import("@mui/material/IconButton")),
  // TerminalIcon = lazy(() => import("@mui/icons-material/Terminal")),
  Stack = lazy(() => import("@mui/material/Stack")),
  ReceiptLongIcon = lazy(() => import("@mui/icons-material/ReceiptLong")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  StepperCell = lazy(() => import("components/ui/table/components/cell/Stepper").then((module) => ({default: module["StepperCell"]}))),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));


export const FccPoliticalBatchQueue = (props: any) => {

  const
    {cachedMarketsData} = cachedRequests(),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    {convertDatePretty} = useDate(),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [topLevelData, setTopLevelData] = useState<any>(),
    issuesBatchQueuePath = "/political-candidates-and-issues/queue/:id",
    id = useMatch(issuesBatchQueuePath)?.params.id,
    [documentName, setDocumentName] = useState(""),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Fetching and using our data
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    issuesSummary = requestAtom(`politicalStatusSummary`, urlPaths.POLITICAL_DOCUMENT_STATUS_SUMMARY, {
      ui_batch_id: id
    }),
    issuesQueue = requestAtom(`politicalUploadQueue`, urlPaths.POLITICAL_DOCUMENT_ACTIVITY, {
      ui_batch_id: id
    }, setErrorState),
    [issuesSummaryState, setIssuesSummaryState] = useState<any>(() => issuesSummary),
    [tableState, setTableState] = useState<any>(() => issuesQueue),
    [{data: summaryData, isPending: summaryPending, isError: summaryError}] = useAtom<AtomQuery>(issuesSummaryState),
    [{data: tableData, isPending: tablePending, isError: tableError}] = useAtom<AtomQuery>(tableState),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Control states
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // [pastSummaryData, setPastSummaryData] = useState<any>(),
    [pastTableData, setPastTableData] = useState<any>(),
    [pageIs404, setPageIs404] = useState<any>(false),
    [refetchButton, setRefetchButton] = useState({display: true, disabled: true}),
    [refetch, setRefetch] = useState(true),
    [getData, setGetData] = useState<any>();
  // {makeRequestServerSent} = useApiAuth();

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // const getStreamData = async () => {
  //   const url = `${urlPaths.POLITICAL_FILE_BY_ID}?file_id=e24cfeab35aa0f8f93baa1e8d233e338`;
  //
  //   const test = await makeRequestServerSent("GET", url);
  // };
  //
  // useEffect(() => {
  //   console.log("stream data starting");
  //   getStreamData();
  // }, []);
  // const getStream = () => {
  //   const url = `${urlPaths.POLITICAL_FILE_BY_ID}?file_id=1f2679e9c64a927aa3310cbccd480a2b`;
  //   const eventSource = new EventSource(url);
  //
  //   eventSource.onmessage = (event: any) => {
  //     if (event && event?.data) {
  //       if (event?.data?.count && event?.data?.count > 0 && JSON.parse(JSON.stringify(event?.data))) {
  //         const data = JSON.parse(event?.data);
  //         console.log("Received data from SSE:", data);
  //         // Process the data as needed
  //       }
  //     }
  //   };
  //
  //   eventSource.onerror = (error) => {
  //     console.error("Error in SSE connection", error);
  //     eventSource.close(); // Close the connection on error
  //   };
  //
  //   // Return eventSource to allow manual closure later if needed
  //   return eventSource;
  // };
  //
  // useEffect(() => {
  //   const eventSource = getStream();
  //   // Cleanup function to close SSE connection when the component unmounts
  //   return () => {
  //     eventSource.close();
  //   };
  // }, []);

  useEffect(() => {
    setIssuesSummaryState(() => issuesSummary);
    // setTimeout(() => {
    setTableState(() => issuesQueue);
    // }, 1500);
  }, []);

  useEffect(() => {
    if (summaryError || tableError) {
      setRefetch(false);
      setPageIs404(true);
    }
  }, [summaryError, tableError]);

  // Once summary data is available manipulate it
  useEffect(() => {

    // if (!tableData || tableData && tableData?.items?.length <= 0) {
    //   setTableState(() => issuesQueue);
    // }
    // if (!pastSummaryData) {
    //   setPastSummaryData(summaryData);
    // }
    // if (pastSummaryData && summaryData?.summary) {
    // if (_.isEqual(summaryData, pastSummaryData)) {
    //   console.log("table - no summary state change observed");
    // } else {
    if (summaryData) {
      if (summaryData && summaryData?.summary?.percentage === 100) {
        setRefetch(false);
        setRefetchButton({display: false, disabled: true});
      }
      summaryData.summary.document_name = summaryData?.summary?.candidate_campaign_name;
      // if (_.isEqual(topLevelData, summaryData?.summary)) {
      //   console.log("no top level data change");
      // } else {
      setTopLevelData(summaryData?.summary);
      // }
      setDocumentName(summaryData?.summary?.candidate_campaign_name);
      setBreadCrumb(["Home", "political-candidates-and-issues/queue/", summaryData?.summary?.candidate_campaign_name]);
    }
    // }
    // }
  }, [summaryData]);

  // Everytime getData is reset update the summary table data
  useEffect(() => {
    // setIssuesSummaryState(() => issuesSummary);

    setTableState(() => issuesQueue);
    if (!pastTableData) {
      setPastTableData(tableData);
    }


    const pastTableDataWithNoIds = _.map(pastTableData?.items, (item) => _.omit(item, ["id"]));
    const newTableDataWithNoIds = _.map(tableData?.items, (item) => _.omit(item, ["id"]));

    // console.log("table test", !_.isEqual(newTableDataWithNoIds, pastTableDataWithNoIds), {
    //   tableData: newTableDataWithNoIds,
    //   pastTableData: pastTableDataWithNoIds
    // });

    if (pastTableData && !_.isEqual(newTableDataWithNoIds, pastTableDataWithNoIds)) {
      // console.log("Setting table data");
      setPastTableData(tableData);
    }
  }, [getData]);

  // Timed loop that executes so long the control state remains False
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let totalTime = 0;
    const loopDuration = 1 * 60 * 1000; // 10 minutes in milliseconds

    if (refetch) {
      setRefetchButton({display: true, disabled: true});
      intervalId = setInterval(() => {
        if (totalTime >= loopDuration) {
          setRefetch(false);
          setRefetchButton({display: true, disabled: false});
          clearInterval(intervalId);
        } else {
          setGetData(new Date().toISOString());
          totalTime += 5000; // Increase total time by 5 seconds
        }
      }, 5000); // 5 seconds interval
    }
    return () => clearInterval(intervalId); // Cleanup on component unmount or control state change
  }, [refetch]);


  const mobileColumns = [{
    field: "brand",
    id: "brand",
    headerName: "Recently Uploaded Items",
    flex: 12,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: {
      row: {
        upload_filename: string,
        facility_id: string,
        service: string,
        callsign: string,
        market: string,
        link: string,
        name: string,
        status: string,
        upload_date: string,
        date_required: string,
        file_id: string,
        upload_status: string,
        ui_batch_id: any
      }
    }) => {
      return <Stack direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{width: "100%", minHeight: "100%", height: "max-content"}}
      >
        <Grid container spacing={3} sx={{width: "100%", minHeight: "100%", height: "max-content"}}>
          <Grid xs={2}>
            <Stack direction="column"
              justifyContent="center"
              spacing={0}
              sx={{width: "100%", minHeight: "100%", height: "max-content"}}
            >
              <Brand {...{
                callsign: params.row.callsign,
                facility_id: params.row.facility_id,
                service: params.row.service,
                size: 50,
              }}
              />
            </Stack>
          </Grid>
          <Grid xs={10}>
            <Stack direction="column"
              justifyContent="center"
              // alignItems="center"
              spacing={-3}
              sx={{marginTop: "-10px", width: "100%", minHeight: "100%", height: "max-content"}}
            >
              <p><strong>Uploaded:</strong> {convertDatePretty(params.row.upload_date)}</p>
              <p><strong>Market:</strong> <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link></p>
              <p><strong>Callsign:</strong> <Link
                to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link></p>
              <p><strong>Status:</strong> {params.row.upload_status}</p>
              <p style={{marginTop: "-5px", height: "20px", marginBottom: "20px"}}>
                <StepperCell {...{status: params.row.upload_status}} /></p>
              <p><strong>View Doc: </strong><Link
                to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/political-candidates-and-issues/document/${params.row.file_id}` : ""}
                key={uuidv4()}
                style={{
                  pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
                  color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
                }} rel="noreferrer"
              >{params.row.upload_filename.toString()}</Link></p>
            </Stack>
          </Grid>
          <Stack key={uuidv4()}
            sx={{width: "100%", minWidth: "fit-content", margin: "-5px 0px 5px 0px"}}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}>
            <Link
              to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}/facility/${params.row.facility_id}/`}
              key={uuidv4()}
            >
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<ReceiptLongIcon/>}>
                BRAND LOG
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Stack>;
    }
  }];

  const desktopColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        const arr = params.row.market.split(",");
        return arr.map((market: string) => <Link key={uuidv4()} to={`/market/${market}/`}>{market}</Link>);
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 150,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} key={uuidv4()}/>;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link key={uuidv4()} to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link key={uuidv4()} to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      cellClassName: "name",
      renderCell: (params: {
        row: { link: string, file_id: string, upload_filename: string, upload_status: string }
      }) => {
        if (params.row.upload_filename.toString().includes(".pdf") && params.row.upload_status.toLowerCase() === "verification completed") {
          return <Link
            to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/political-candidates-and-issues/document/${params.row.file_id}` : ""}
            // target={"_blank"}
            key={uuidv4()}
            style={{
              pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
              color: params.row.link && params.row.link !== "#" && params.row?.link?.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params?.row?.upload_filename?.toString()}</Link>;
        } else {
          return <p key={uuidv4()}>{params.row.upload_filename.toString()}</p>;
        }
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return params.row.upload_status;
      }
    }, {
      field: "step",
      id: "step",
      align: "left",
      headerName: "Progress",
      flex: 3,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return <StepperCell key={uuidv4()} {...{status: params.row.upload_status}} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      headerName: "Actions",
      // flex: 2,
      width: 120,
      renderCell: (params: {
        row: { callsign: string, ui_batch_id: string, facility_id: string, upload_status: string, file_id: string }
      }) => {
        return <Stack direction="row" spacing={0} key={uuidv4()}>
          <Link
            to={`/political-candidates-and-issues/queue/${params.row.ui_batch_id}/facility/${params.row.facility_id}/document/${params.row.file_id}`}
            key={uuidv4()}
          >
            <Tooltip
              title={`Upload For ${params.row.callsign}`}
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <ReceiptLongIcon/>
              </IconButton>
            </Tooltip>
          </Link>
        </Stack>;
      }
    }],
    topperProps: any = {
      title: documentName,
      inputs: [{
        label: "Refresh",
        type: "button",
        disabled: refetchButton.disabled,
        display: refetchButton.display,
        loader: true,
        onClick: ((e: React.MouseEvent) => {
          setRefetch(true);
        })
      }]
    };
  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <>
        <Title {...{
          topLevelData: {
            screen: "queue",
            ...topLevelData,
            ...summaryData
          },
          title: "Uploads For ",
        }} />
        <Breadcrumb {...{
          breadcrumb: breadcrumb
        }} />
        <Box sx={{margin: props.screen ? "10px" : undefined, boxShadow: 2}}>
          <Topper {...{...topperProps, mobile: props.screen}} />
          <Table {...{
            ...props,
            sortBy: "market",
            mobile: props.screen,
            data: tableData ? tableData.items : [],
            columns: props.screen ? mobileColumns : desktopColumns,
            loading: tablePending,
            error: tableError,
            errorMessage: errorState
          }} />
        </Box></> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're unable to locating this upload process."
      }} />}
    </Suspense>
  );
};