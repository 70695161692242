import React, {lazy, Suspense, useEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link, useMatch} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAtom} from "jotai/index";
import {v4 as uuidv4} from "uuid";
import Button from "@mui/material/Button";
import {useDate} from "hooks/useDate";


const
  Grid = lazy(() => import("@mui/material/Grid")),
  Box = lazy(() => import("@mui/material/Box")),
  Tooltip = lazy(() => import("@mui/material/Tooltip")),
  IconButton = lazy(() => import("@mui/material/IconButton")),
  TerminalIcon = lazy(() => import("@mui/icons-material/Terminal")),
  Stack = lazy(() => import("@mui/material/Stack")),
  ReceiptLongIcon = lazy(() => import("@mui/icons-material/ReceiptLong")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  StepperCell = lazy(() => import("components/ui/table/components/cell/Stepper").then((module) => ({default: module["StepperCell"]}))),
  Brand = lazy(() => import("components/ui/table/components/cell/Brand").then((module) => ({default: module["Brand"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));


export const FccIssuesAndProgramsBatchQueue = (props: any) => {

  const
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    {convertDatePretty} = useDate(),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [topLevelData, setTopLevelData] = useState<any>(),
    issuesBatchQueuePath = "/issues-and-programs/queue/:id",
    id = useMatch(issuesBatchQueuePath)?.params.id,
    [documentName, setDocumentName] = useState(""),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Fetching and using our data
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    issuesSummary = requestAtom(`issuesStatusSummary`, urlPaths.ISSUES_STATUS_SUMMARY, {
      upload_process_status_id: id
    }),
    issuesQueue = requestAtom(`issuesUploadQueue`, urlPaths.ISSUES_QUEUE, {
      upload_process_status_id: id
    }, setErrorState),
    [issuesSummaryState, setIssuesSummaryState] = useState<any>(() => issuesSummary),
    [tableState, setTableState] = useState<any>(() => issuesQueue),
    [{data: summaryData, isPending: summaryPending, isError: summaryError}] = useAtom<AtomQuery>(issuesSummaryState),
    [{data: tableData, isPending: tablePending, isError: tableError}] = useAtom<AtomQuery>(tableState),
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    // Control states
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    [pageIs404, setPageIs404] = useState<any>(false),
    [refetchButton, setRefetchButton] = useState({display: true, disabled: true}),
    [refetch, setRefetch] = useState(true),
    [getData, setGetData] = useState<any>();


  useEffect(() => {
    setIssuesSummaryState(() => issuesSummary);
    setTableState(() => issuesQueue);
  }, []);

  useEffect(() => {
    if (summaryError || tableError) {
      setRefetch(false);
      setPageIs404(true);
    }
  }, [summaryError, tableError]);

  // Once summary data is available manipulate it
  useEffect(() => {
    if (summaryData?.summary) {
      if (summaryData?.summary?.percentage === 100) {
        setRefetch(false);
        setRefetchButton({display: false, disabled: true});
      }
      setTopLevelData(summaryData?.summary);
      setDocumentName(summaryData?.summary?.document_name);
      setBreadCrumb(["Home", "issues-and-programs/queue/", summaryData?.summary?.document_name]);
    }
  }, [summaryData]);

  // Everytime getData is reset update the summary table data
  useEffect(() => {
    // setIssuesSummaryState(() => issuesSummary);
    setTableState(() => issuesQueue);
  }, [getData]);

  // Timed loop that executes so long the control state remains False
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let totalTime = 0;
    const loopDuration = 1 * 60 * 1000; // 10 minutes in milliseconds

    if (refetch) {
      setRefetchButton({display: true, disabled: true});
      intervalId = setInterval(() => {
        if (totalTime >= loopDuration) {
          setRefetch(false);
          setRefetchButton({display: true, disabled: false});
          clearInterval(intervalId);
        } else {
          setGetData(new Date().toISOString());
          totalTime += 5000; // Increase total time by 5 seconds
        }
      }, 5000); // 5 seconds interval
    }
    return () => clearInterval(intervalId); // Cleanup on component unmount or control state change
  }, [refetch]);


  const mobileColumns = [{
    field: "brand",
    id: "brand",
    headerName: "Recently Uploaded Items",
    flex: 12,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: {
      row: {
        upload_filename: string,
        facility_id: string,
        service: string,
        callsign: string,
        market: string,
        link: string,
        name: string,
        status: string,
        upload_date: string,
        date_required: string,
        file_id: string,
        upload_status: string,
        upload_process_status_id: any
      }
    }) => {
      return <Stack direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{width: "100%", minHeight: "100%", height: "max-content"}}
      >
        <Grid container spacing={3} sx={{width: "100%", minHeight: "100%", height: "max-content"}}>
          <Grid xs={2}>
            <Stack direction="column"
              justifyContent="center"
              spacing={0}
              sx={{width: "100%", minHeight: "100%", height: "max-content"}}
            >
              <Brand {...{
                callsign: params.row.callsign,
                facility_id: params.row.facility_id,
                service: params.row.service,
                size: 50,
              }}
              />
            </Stack>
          </Grid>
          <Grid xs={10}>
            <Stack direction="column"
              justifyContent="center"
              // alignItems="center"
              spacing={-3}
              sx={{marginTop: "-10px", width: "100%", minHeight: "100%", height: "max-content"}}
            >
              <p><strong>Uploaded:</strong> {convertDatePretty(params.row.upload_date)}</p>
              <p><strong>Market:</strong> <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link></p>
              <p><strong>Callsign:</strong> <Link
                to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link></p>
              <p><strong>Status:</strong> {params.row.upload_status}</p>
              <p style={{marginTop: "-5px", height: "20px", marginBottom: "20px"}}>
                <StepperCell {...{status: params.row.upload_status}} /></p>
              <p><strong>View Doc: </strong><Link
                to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/issues-and-programs/document/${params.row.file_id}` : ""}
                key={params.row.upload_filename}
                style={{
                  pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
                  color: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
                }} rel="noreferrer"
              >{params.row.upload_filename.toString()}</Link></p>
            </Stack>
          </Grid>
          <Stack key={uuidv4()}
            sx={{width: "100%", minWidth: "fit-content", margin: "-5px 0px 5px 0px"}}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}>
            <Link
              to={`/issues-and-programs/queue/${params.row.upload_process_status_id}/facility/${params.row.facility_id}/`}
              key={uuidv4()}
            >
              <Button sx={{backgroundColor: "#1976d2", color: "white"}}
                variant={"contained"}
                endIcon={<ReceiptLongIcon/>}>
                BRAND LOG
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Stack>;
    }
  }];

  const desktopColumns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        const arr = params.row.market.split(",");
        return arr.map((market: string) => <Link key={market} to={`/market/${market}/`}>{market}</Link>);
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 150,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { facility_id: string, service: string, callsign: string } }) => {
        return <Brand {...{
          callsign: params.row.callsign,
          facility_id: params.row.facility_id,
          service: params.row.service
        }} />;
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.facility_id}</Link>);
      }
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      cellClassName: "name",
      renderCell: (params: { row: { link: string, file_id: string, upload_filename: string } }) => {
        if (params.row.upload_filename.toString().includes(".pdf")) {
          return <Link
            to={params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? `/issues-and-programs/document/${params.row.file_id}` : ""}
            // target={"_blank"}
            key={params.row.upload_filename}
            style={{
              pointerEvents: params.row.link && params.row.link !== "#" && params.row.link.length > 0 ? "auto" : "none",
              color: params.row.link && params.row.link !== "#" && params.row?.link?.length > 0 ? "#1976d2" : "rgba(0, 0, 0, 0.87)",
            }} rel="noreferrer"
          >{params?.row?.upload_filename?.toString()}</Link>;
        } else {
          return <p>{params.row.upload_filename.toString()}</p>;
        }
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return params.row.upload_status;
      }
    }, {
      field: "step",
      id: "step",
      align: "left",
      headerName: "Progress",
      flex: 3,
      renderCell: (params: { row: { upload_process_id: string, upload_status: string } }) => {
        return <StepperCell {...{status: params.row.upload_status}} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      headerName: "Actions",
      // flex: 2,
      width: 120,
      renderCell: (params: {
        row: { callsign: string, upload_process_status_id: string, facility_id: string, upload_status: string }
      }) => {
        return <Stack direction="row" spacing={0}>
          <Link
            to={`/issues-and-programs/queue/${params.row.upload_process_status_id}/facility/${params.row.facility_id}`}
            key={params.row.upload_process_status_id}
          >
            <Tooltip
              title={`Upload For ${params.row.callsign}`}
              enterDelay={1200}
              // leaveDelay={500}
              TransitionProps={{timeout: 200}}
            >
              <IconButton sx={{
                color: "#1976d2",
              }}>
                <ReceiptLongIcon/>
              </IconButton>
            </Tooltip>
          </Link>
        </Stack>;
      }
    }],
    topperProps: any = {
      title: documentName,
      inputs: [{
        label: "Refresh",
        type: "button",
        disabled: refetchButton.disabled,
        display: refetchButton.display,
        loader: true,
        onClick: ((e: React.MouseEvent) => {
          setRefetch(true);
        })
      }]
    };
  return (
    <Suspense fallback={<></>}>
      {!pageIs404 ? <>
        <Title {...{
          topLevelData: {
            screen: "queue",
            ...topLevelData
          },
          title: "Upload",
        }} />
        <Breadcrumb {...{
          breadcrumb: breadcrumb
        }} />
        <Box sx={{margin: props.screen ? "10px" : undefined, boxShadow: 2}}>
          <Topper {...{...topperProps, mobile: props.screen}} />
          <Table {...{
            ...props,
            mobile: props.screen,
            data: tableData && tableData.items ? tableData.items : [],
            columns: props.screen ? mobileColumns : desktopColumns,
            loading: tablePending,
            error: tableError,
            errorMessage: errorState
          }} />
        </Box></> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're unable to locating this upload process."
      }} />}
    </Suspense>
  );
};