import React from "react";
import {Avatar, Box, Button, Dialog, Stack, Typography} from "@mui/material";
import useIosInstallPrompt from "hooks/pwa/useIosInstallPrompt";
import useWebInstallPrompt from "hooks/pwa/useWebInstallPrompt";
import icon from "assets/icon-192-maskable.png";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <Dialog
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiDialog-paper": {borderRadius: "8px", border: "1px solid #00acc0"},
      }}
    >
      <Box sx={{
        width: "100%",
        height: "auto",
        // backgroundColor: "white",
        // padding: "0px",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundAttachment: "fixed",
        color: "white",
        // backgroundOverflow: "hidden",
        // backgroundImage: `url(${background})`,
        backgroundColor: "#00004A"
      }}>
        <Stack sx={{padding: "15px"}} direction="column" spacing={0}>
          <Stack direction="row" spacing={2}
            sx={{lineHeight: "normal", margin: "0 auto", padding: "15px"}}
            justifyContent="center"
            alignItems="center">
            <Avatar
              alt="Signal Craft"
              src={icon}
              sx={{width: 35, height: 35, boxShadow: 4}}
            />
            <Typography variant="h1" sx={{fontSize: "16px"}}>Install <strong>SignalCraft</strong></Typography>
          </Stack>
          <Stack direction="column" spacing={1} sx={{width: "100%", marginBottom: "0px"}}>
            <Typography variant="h1" sx={{fontSize: "14px", padding: "0px 20px 10px 20px", textAlign: "left"}}>We
              noticed you&apos;re on a mobile device. <strong>SignalCraft</strong> can be installed on most smart phones
              and used as a mobile application. Save some steps and give it a try today!</Typography>

            {iosInstallPrompt && (
              <>
                <Stack direction="row" spacing={0}>
                  <span style={{fontSize: "13px"}}><strong>1.</strong> Tap the on the</span>
                  <svg
                    style={{width: "20px", height: "20px", fill: "white", margin: "0 5px"}}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 566 670"
                  >
                    <path
                      d="M255 12c4-4 10-8 16-8s12 3 16 8l94 89c3 4 6 7 8 12 2 6 0 14-5 19-7 8-20 9-28 2l-7-7-57-60 2 54v276c0 12-10 22-22 22-12 1-24-10-23-22V110l1-43-60 65c-5 5-13 8-21 6a19 19 0 0 1-16-17c-1-7 2-13 7-18l95-91z"/>
                    <path
                      d="M43 207c16-17 40-23 63-23h83v46h-79c-12 0-25 3-33 13-8 9-10 21-10 33v260c0 13 0 27 6 38 5 12 18 18 30 19l14 1h302c14 0 28 0 40-8 11-7 16-21 16-34V276c0-11-2-24-9-33-8-10-22-13-34-13h-78v-46h75c13 0 25 1 37 4 16 4 31 13 41 27 11 17 14 37 14 57v280c0 20-3 41-15 58a71 71 0 0 1-45 27c-11 2-23 3-34 3H109c-19-1-40-4-56-15-14-9-23-23-27-38-4-12-5-25-5-38V270c1-22 6-47 22-63z"/>
                  </svg>
                  <span style={{fontSize: "13px"}}>icon in your browser</span>
                </Stack>
                <Stack direction="row" spacing={0}>
                  <span style={{fontSize: "13px"}}><strong>2.</strong> Select the <strong
                    style={{backgroundColor: "rgba(250, 250, 250, 0.2", color: "white"}}>Add to Home Screen</strong> option</span>
                </Stack>
                <Button variant="contained" disableElevation size={"small"}
                  onClick={handleIOSInstallDeclined}
                  sx={{mindWidth: "100%", backgroundColor: "rgb(162 162 162)", color: "white"}}>
                  All Done / Maybe Later
                </Button>
              </>
            )}

            {webInstallPrompt && (
              <><Button variant="contained" disableElevation size={"small"} color={"primary"}
                onClick={(e: any) => handleWebInstallAccepted()}
                style={{backgroundColor: "#00acc0", color: "white"}}>
                Install Now
              </Button>
              <Button variant="contained" disableElevation size={"small"}
                onClick={handleWebInstallDeclined}
                sx={{mindWidth: "100%", backgroundColor: "rgb(162 162 162)", color: "white"}}>
                  Maybe Later
              </Button></>
            )}
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};