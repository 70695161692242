import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {SnackbarProvider} from "notistack";
import React, {useRef} from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "../router/Router.tsx";
import "./App.css";
import GlobalModal from "components/ui/globalmodal/index";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {InstallPWA} from "components/pwa/index";

function App() {
  const notistackRef = useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <BrowserRouter basename={"/craft/view/"}>
      <SnackbarProvider
        maxSnack={5}
        ref={notistackRef}
        action={(key) => (
          <Button
            onClick={() => notistackRef.current.closeSnackbar(key)}
            style={{color: "#fff", fontSize: "15px", fontWeight: "100", width: "fit-content"}}
          ><CloseIcon sx={{color: "#fff", fontSize: "15px", fontWeight: "100"}}/></Button>
        )}
      >
        <Router/>
        <InstallPWA/>
        <GlobalModal/>

        {/*{isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*/}
        {/*{isSmallScreen ?*/}
        {/*  <main>*/}
        {/*    <Router/>*/}
        {/*  </main> : <main style={{display: "flex", position: "relative"}}>*/}
        {/*    <LeftNav/>*/}
        {/*    <Router/>*/}
        {/*  </main>*/}
        {/*}*/}
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;