import {atomWithStorage} from "jotai/utils";


interface IUserInfoAtom {
  firstName: string;
  lastName: string;
  username: string
  isMsUser: boolean
}

export const authTokenAtom = atomWithStorage("Authorization", "");
export const firstNameAtom = atomWithStorage("firstName", "");
export const userInfoAtom = atomWithStorage<IUserInfoAtom>("userInfo", {
  firstName: "",
  lastName: "",
  username: "",
  isMsUser: false,
});

