import React, {lazy, Suspense, useEffect, useState} from "react";
import {NavLink as Link, useMatch} from "react-router-dom";
import urlPaths from "services/api/urlPaths";
import {useApiAuth} from "services/api/useApiAuth";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {v4 as uuidv4} from "uuid";
import {MultiPoliticalTables} from "../fcc_political_candidates_and_issues/components/tables/market_tables";

const
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  NotFound = lazy(() => import("pages/not_found/index")),
  Table = lazy(() => import("components/ui/table").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar")),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]}))),
  Grid = lazy(() => import("@mui/material/Grid")),
  FolderIcon = lazy(() => import("@mui/icons-material/Folder")),
  Stack = lazy(() => import("@mui/material/Stack"));

export const Facility = (props: any) => {
  const
    {convertDate, getCurrentYear, getArrayOfYears} = useDate(),
    {getDocumentQuarter} = useQuarter(),
    [tableData, setTableData] = useState<any>([]),
    [year, setYear] = useState(getCurrentYear()),
    [market, setMarket] = useState("All Markets"),
    theme = useTheme(),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [pageIs404, setPageIs404] = useState<any>(false),
    [issuesAndProgramsLink, setIssuesAndProgramsLink] = useState<string>(),
    [breadcrumb, setBreadCrumb] = useState<any>([]),
    [topLevelData, setTopLevelData] = useState<any>(),
    [callsign, setCallsign] = useState<any>(),
    facilityPath = "/facility/:id",
    facilityId = useMatch(facilityPath)?.params.id,
    {makeRequest} = useApiAuth();

  // Make a request to obtain the facilities issues and programs
  useEffect(() => {
    setTableData([]);
    if (facilityId) {
      const params: any = {
        year: year,
        facility_id: facilityId
      };
      makeRequest("GET", urlPaths.ISSUES_ENDPONT, null, params).then((data: any) => {
        console.log("props.facilityData", data);
        const
          document = {
            market: data.items[0].market,
            callsign: data.items[0].callsign,
            service: data.items[0].service,
            facility_id: data.items[0].facility_id,
            fcc_directory: data.items[0].fcc_directory,
            containing_folder_link: data.items[0].containing_folder_link,
          },
          tableRows: any = [];
        setIssuesAndProgramsLink(data.items[0].containing_folder_link);
        setBreadCrumb(["Home", "markets/", `market/${data.items[0].market}`, data.items[0].callsign]);
        setTopLevelData(data.items[0]);
        setCallsign(data.items[0].callsign);
        setPageIs404(data.items.length >= 1 ? false : true);
        data?.items[0]?.documents?.forEach((doc: any) => {
          tableRows.push({
            ...document,
            id: uuidv4(),
            name: doc.name,
            link: doc.link,
            date: convertDate(doc.date),
            selector: data.items[0].facility_id + doc.date + doc.link,
            file_id: doc.file_id,
          });
        });
        setTableData([...tableRows.reverse()]);

      }).catch((error) => {
        setPageIs404(true);
        console.log("error", error);
      });
    }
  }, [facilityId, year, market]);

  const
    mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          date: string,
          facility_id: string,
          service: string,
          callsign: string,
          market: string,
          link: string,
          name: string,
          status: string,
          upload_date: string,
          date_required: string
        }
      }) => {
        return <Link to={`/facility/${params.row.facility_id}/`}>
          <Grid container spacing={3}>
            <Grid xs={12} sx={{float: "left"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
                <span><strong>PDF Link:</strong> <Link
                  to={`/craft/view/issues-and-programs/document/${params.row.link}`}
                  key={uuidv4()}
                  style={{
                    color: "#1976d2",
                  }}
                >{params.row.name}</Link></span>
                <span><strong>Date:</strong> {convertDate(params.row.date)}</span>
                <span><strong>Quarter:</strong> {getDocumentQuarter(new Date(params.row.date).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                }))}</span>
              </Stack>
            </Grid>
          </Grid>
        </Link>;
      }
    }],

    // All of the columns table data
    columns: any = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return (<Link
          to={`/market/${params.row.market}`}
        >{params.row.market}</Link>);
      }
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      flex: 1
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      flex: 1,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      flex: 1,
    }, {
      field: "name",
      id: "name",
      headerName: "Pdf Name/Pdf Link",
      flex: 2,
      editable: false,
      type: "string",
      // cellClassName: "name",
      renderCell: (params: { row: { link: string, name: string, file_id: string } }) => {
        return (<Link
          to={`/issues-and-programs/document/${params.row.file_id}`}
          style={{
            color: "#1976d2",
          }}
        >{params.row.name}</Link>);
      }
    }, {
      field: "quarter",
      id: "date",
      headerName: "Quarter",
      // key: "date",
      flex: 1,
      renderCell: (params: {
        row: { date: string }
      }) => params?.row?.date ? getDocumentQuarter(new Date(params.row.date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric"
      })) : "-"
    }, {
      field: "upload_date",
      id: "date",
      headerName: "Upload Date",
      // key: "date",
      flex: 1,
      cellClassName: "date",
      renderCell: (params: { row: { date: string } }) => params.row.date ? convertDate(params.row.date) : null
    }],
    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload PDF",
        type: "modal",
        modal: {
          data: {
            row: {
              market: topLevelData?.market,
              facility_id: topLevelData?.facility_id
            }
          },
          modalTitle: "Upload A PDF",
          modalForm: FccIssuesAndProgramsForm,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/"
      }]
    },
    topperProps: any = {
      title: "All Issues & Programs",
      inputs: [{
        label: <span style={{fontSize: "10px"}}><FolderIcon
          sx={{marginRight: "5px", marginTop: "-5px"}}/>VIEW FCC ISSUES & PROGRAMS</span>,
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          window.open(issuesAndProgramsLink);
        })
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("All Markets");
          setYear(getCurrentYear());
        })
      }]
    };

  return (
    <Suspense fallback={<div></div>}>
      {!pageIs404 ? <><Title {...{
        topLevelData: {
          screen: "facility",
          ...topLevelData
        },
        title: "Upload",
      }} />
      <Breadcrumb {...{
        breadcrumb: breadcrumb
      }} />

      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, screen: props.screen}} />
      <Table {...{
        mobile: props.screen,
        tableKey: "facilityReport",
        data: tableData,
        columns: props.screen ? mobileColumns : columns,
        selector: "selector"
      }} />

      <MultiPoliticalTables {...{facility_id: facilityId}} />
      </> : <NotFound {...{
        mobile: isSmallScreen,
        message: "We're sorry, we're having difficulties locating a facility matching this id."
      }} />}
    </Suspense>
  );
};

