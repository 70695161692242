import {atom, useAtom} from "jotai";

// Define atoms for modal state, title, and description
export const modalStateAtom = atom(false);
export const modalTitleAtom = atom("");
export const modalDescriptionAtom = atom("");

export const modalContent = atom({
  title: "",
  body: "",
  video: ""
});

// Getter and setter for modal state
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// export const useModalState = () => useAtom<boolean>(!modalStateAtom);

// Getter and setter for modal title
export const useModalTitle = () => useAtom(modalTitleAtom);

// Getter and setter for modal description
export const useModalDescription = () => useAtom(modalDescriptionAtom);

// Method for resetting modal state, title, and description
// export const resetModalState = () => {
//   useAtom(modalContent({title: "", body: "", video: ""});
// };