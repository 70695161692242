import React from "react";
import {Controller} from "react-hook-form";
// import {TextFieldElement} from "react-hook-form-mui";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const TF = (props: any) => {
  // const required = props.required ? props.required : "";
  let validationMessage = props.validationMessage ? props.validationMessage : "*Required";
  const regex = /[^\w\.-]/;

  function containsSpecialCharacters(input: string) {
    return regex.test(input);
  }

  function isValueInString(arr: any[], str: string) {
    // console.log("textfield", arr.some((item: string) => str.includes(item)));
    return arr.some((item: string) => str.includes(item));
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        // required: props.required,
        validate: {
          required: (value: string) => {
            if (props.required && value === "") {
              return validationMessage;
            } else if (props.required && props?.spacesAllowed === false && value.length > 0 && value.includes(" ")) {
              validationMessage = "Value cannot contain spaces";
              return validationMessage;
            } else if (props.required && props?.specialCharactersAllowed === false && value.length > 0 && containsSpecialCharacters(value)) {
              validationMessage = "Value cannot contain special characters besides these . _ -";
              return validationMessage;
            } else if (props.required && props?.requiredText && !isValueInString(props?.requiredText, value)) {
              validationMessage = `Value must contain the following text ${props?.requiredText.toString()}`;
              return validationMessage;
            }
          }
        },
        minLength: 2
      }}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => <FormControl
        style={{maxHeight: "30px"}} fullWidth><TextField
          name={props.name}
          label={props.label}
          fullWidth={true}
        // helperText={error ? validationMessage : null}
          error={!!error}
          onBlur={onBlur}
          value={value}
          ref={ref}
          onChange={onChange}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            margin: "dense",
            size: "small",
            disableUnderline: true,
            style: {
              maxHeight: "30px",
              fontSize: "12px"
            }
          }}
        // defaultValue=""
        /><FormHelperText style={{color: "red", display: error ? "block" : "none"}}>{validationMessage}</FormHelperText>
      </FormControl>}
    />

    // <TextFieldElement
    //   name={props.name}
    //   label={props.label}
    //   fullWidth={true}
    //   validation={{
    //     // pattern: {
    //     //   value: /\S+@\S+\.\S+/,
    //     //   message: "Entered value does not match email format"
    //     // },
    //     // required: "Hmm interesting",
    //     // minLength: {
    //     //   value: 100,
    //     //   message: "you need more letters"
    //     // },
    //     validate: {
    //       required: (value) => {
    //         if (value === "test") {
    //           return "Some Message";
    //         } else if (value === "wow") {
    //           return "*Required";
    //         }
    //         // else {
    //         //   return "good job!";
    //         // }
    //       },
    //     },
    //     required: "placeholder validation string"
    //   }}
    // rules={{
    //   validate: {
    //     required: (value) => {
    //       if (value === "test") return "Some Message";
    //       if (!value) return "*Required";
    //     }
    //   },
    //   maxLength: 5
    // }}
    // required
    // parseError={"error"}
    // size="small"
    // margin={"dense"}
    // variant={"standard"}
    // InputProps={{
    //   // margin: "dense",
    //   // size: "small",
    //   // variant: "standard",
    //   InputProps: {
    //     disableUnderline: true,
    //     variant: "standard",
    //     sx: {
    //
    //     }
    //   },
    //   InputLabelProps: {
    //     shrink: true
    //   },
    // }}
    // InputLabelProps={{
    //   shrink: true,
    // }}
    // InputProps={{
    //   margin: "dense",
    //   size: "small",
    //   disableUnderline: true,
    // textFieldProps: {
    //   "test": "test
    // },
    // inputProps: {
    //   variant: "standard",
    //   disableUnderline: true
    // },
    // InputLabelProps: {
    //   shrink: true
    // },
    // inputProps: {
    // },
    //   variant: "standard",
    //   disableUnderline: true,
    //   inputLabelProps: {
    //     shrink: true
    //   },
    // },
//       sx: {
//         "& .MuiInputBase-input": {
//           // height: "30px",
//           // width: "100%",
//           border: "solid 1px",
//           borderRadius: "3px",
//           padding: "0px 5px",
//           height: "30px"
//         },
//
//         // "& .MuiOutlinedInput-root, .MuiInputBase-input, .MuiFormControl-root, .MuiFormControl-marginDense": {
//         //   borderColor: "green",
//         //   color: "blue",
//         //   backgroundColor: "red",
//         //   // borderRadius: "5px",
//         //   // display: "block"
//         // },
//         // "& .MuiInputBase-root": {
//         //   height: "300px",
//         //   // width: "100%",
//         // }
//       }
//     }}
//   />
  );
};

export default TF;