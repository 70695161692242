import React from "react";
import {allTheFacilitiesData} from "services/jotai/requests/requests";
import {useAtom} from "jotai";

export const useCallsignConverter = () => {
  const
    // [allMarketsData] = useAtom(allTheMarketsData),
    // [facilitiesDataAsArray] = useAtom(allTheFacilitiesDataAsArray),
    [facilitiesData] = useAtom<any>(allTheFacilitiesData);

  const getCallsign = (facilityId: string) => {

    try {
      return facilitiesData[Number(facilityId)];
    } catch (error) {
      console.log(error);
    }
  };

  return {getCallsign};
};