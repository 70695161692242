import {atom} from "jotai";

/* Needed Values
        "political": {
            "_id": "66905877df063264871a749b",
            "date": "2024-07-11T22:12:58.072Z",
            "type": "menu",
            "name": "political",
            "value": true,
            "meta": null
        }

        "env": {
            "_id": "66905e474f0d28330eadc4f3",
            "date": "2024-07-11T22:35:51.178Z",
            "type": "enviornment",
            "name": "env",
            "value": "development",
            "meta": null
        }
 */

export const settings = atom({});
