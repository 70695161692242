import React from "react";
import urlPaths from "./urlPaths";
import {useApiAuth} from "./useApiAuth";
import {useAtom} from "jotai";
import {
  allTheFacilitiesData,
  allTheFacilitiesDataAsArray,
  allTheMarkets,
  allTheMarketsData,
  allTheMarketsWithMyMarkets,
  allUserProfileSettings,
  allUsersPrimaryAndSecondaryMarkets
} from "services/jotai/requests/requests";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {settings} from "services/jotai/settings/settings";

export const cachedRequests = () => {
  const
    {makeRequest} = useApiAuth(),
    [markets, setMarkets] = useAtom(allTheMarkets),
    [marketsWithMyMarkets, setMarketsWithMyMarkets] = useAtom(allTheMarketsWithMyMarkets),
    [allMarketsData, setAllMarketsData] = useAtom(allTheMarketsData),
    [allFacilitiesData, setAllFacilitiesData] = useAtom(allTheFacilitiesData),
    [allFacilitiesDataAsArray, setAllFacilitiesDataAsArray] = useAtom(allTheFacilitiesDataAsArray),
    [allUserSettings, setAllUserSettings] = useAtom(allUserProfileSettings),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [globalSettings, setGlobalSettings] = useAtom(settings),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom);

  const cachedUserData = async () => {
    try {
      console.log("cachedUserData", userInfo);
      makeRequest("GET", `${urlPaths.USER}${userInfo.username}`).then((data: any) => {
        console.log("userData req", data.user);
        setAllUserSettings(data.user);
        const markets: any = [];
        if (data.user.primaryMarket.includes("All Markets") || data.user.secondaryMarkets.includes("All Markets")) {
          markets.push("All Markets");
        } else {
          markets.push(data.user.primaryMarket);
          console.log("userData secondary", data.user.secondaryMarkets, data.user.secondaryMarkets.length);
          data?.user?.secondaryMarkets?.forEach((market: any) => {
            markets.push(market);
          });
        }
        setAllUserMarkets(markets);

        return {
          user: allUserSettings,
          markets: allUserMarkets
        };
      }).catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };
  const cachedSettingsData = async () => {
    try {
      if (Object.keys(globalSettings).length > 0) {
        return {
          settings: globalSettings
        };
      } else {
        await makeRequest("GET", urlPaths.SETTINGS).then((data: any) => {
          setGlobalSettings(data);
          return data;
        }).catch((error: any) => console.log("error", error));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const cachedMarketsData = () => {
    try {
      if (markets.length > 0 && allMarketsData.length > 0) {
        return {
          markets: markets,
          allMarketsData: allMarketsData,
          allFacilities: allFacilitiesData,
          allFacilitiesDataAsArray: allFacilitiesDataAsArray
        };
      } else {
        // setTimeout(() => {
        makeRequest("GET", urlPaths.MARKETS).then((data: any) => {
          const facilities: any = {};
          const facilitiesArr: any = [];
          data.items.forEach((market: any) => {
            if (market.brands.length > 0) {
              market.brands.forEach((brand: any) => {
                const temp: any = {
                  market: market.name,
                  ...brand
                };
                facilities[brand.facility_id] = temp;
                facilitiesArr.push(temp);
              });
            }
          });

          const markets: any = [];
          const marketsWithMyMarkets: any = [];
          markets.push("All Markets");
          marketsWithMyMarkets.push("My Markets");
          marketsWithMyMarkets.push("All Markets");
          data.items.forEach((market: any) => {
            markets.push(market.name);
            marketsWithMyMarkets.push(market.name);
          });

          // Set the global variables
          setMarkets(markets);
          setMarketsWithMyMarkets(marketsWithMyMarkets);
          setAllMarketsData(data.items);
          setAllFacilitiesData(facilities);
          setAllFacilitiesDataAsArray(facilitiesArr);

          return {
            allMarketsData: data.items,
            markets: markets,
            marketsWithMyMarkets: marketsWithMyMarkets,
            allFacilities: allFacilitiesData,
            allFacilitiesDataAsArray: allFacilitiesDataAsArray
          };
        }).catch((error: any) => console.log("error", error));
        // }, 4000);
      }
    } catch (error) {
      console.log(error);
    }

    cachedUserData();
  };


  return {cachedMarketsData, cachedSettingsData, cachedUserData};
};


// const
//   {getFutureExpDate, dateExpired} = useDate(),
//   makeRequest = useApiAuth(),
//   {setItem, getItem, removeItem}: {
//     setItem: any,
//     getItem: any,
//     removeItem: any
//   } = useLocalStorage("marketsData");
//
//
// // Reusable hook for getting the markets data if needed from the rest api
// // Otherwise we return the data directly from local storage
// const cachedMarketsData = () => {
//   try {
//     if (getItem() && getItem().date && !dateExpired(getItem().date)) {
//       return {allMarketsData: getItem().allMarketsData, markets: getItem().marketsSelect};
//     } else {
//       makeRequest("GET", urlPaths.MARKETS).then((data: any) => {
//         const markets: string[] = [];
//         markets.push("All Markets");
//         data.items.forEach((market: any) => {
//           markets.push(market.name);
//         });
//         // Set data in local storage
//         setItem({date: getFutureExpDate(0, 0, 20), marketsSelect: markets, allMarketsData: data.items});
//         return {allMarketsData: data.items, markets: markets};
//       }).catch((error: any) => console.log("error", error));
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };