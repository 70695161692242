import React, {lazy, Suspense, useEffect, useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useStyles} from "./styles/styles.js";
import urlPaths from "services/api/urlPaths";
import {settings} from "services/jotai/settings/settings";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import PolicyIcon from "@mui/icons-material/Policy";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ListItem from "@mui/material/ListItem";
import InsightsIcon from "@mui/icons-material/Insights";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {NavLink as Link} from "react-router-dom";
import GiteIcon from "@mui/icons-material/Gite";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {useAtom} from "jotai";
import {allUserProfileSettings, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import Badge from "@mui/material/Badge";

const Collapse = lazy(() => import("@mui/material/Collapse"));
const Box = lazy(() => import("@mui/material/Box"));
const Drawer = lazy(() => import("@mui/material/Drawer"));
const List = lazy(() => import("@mui/material/List"));
const Divider = lazy(() => import("@mui/material/Divider"));
const ListItemButton = lazy(() => import("@mui/material/ListItemButton"));
const ListItemIcon = lazy(() => import("@mui/material/ListItemIcon"));
const ListItemText = lazy(() => import("@mui/material/ListItemText"));

export const LeftNav = (props: any) => {
  const
    theme = useTheme(),
    classes = useStyles(),
    {makeRequest} = useApiAuth(),
    [open, setOpen] = useState(true),
    [globalSettings] = useAtom<any>(settings),
    isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")),
    [allUserSettings, setAllUserSettings] = useAtom<any>(allUserProfileSettings),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [politicalMatchingCount, setPoliticalMatchingCount] = useState(0),
    {getCurrentQuarter} = useQuarter(),
    {getCurrentYear} = useDate();

  const getPoliticalCompliance = async () => {
    const markets = allUserMarkets.map((item: any, index: number) => `market[${index}]=${encodeURIComponent(item)}`).join("&");
    if (allUserMarkets.length > 0) {
      await makeRequest("GET", `${urlPaths.POLITICAL_MATCHES}?year=${getCurrentYear()}&${markets}&quarter=${getCurrentQuarter()}&matched=false&showLatest=true`).then((data: any) => {
        setPoliticalMatchingCount(data.count);
      }).catch((error) => console.log("error", error));
    }
  };

  useEffect(() => {
    getPoliticalCompliance();
  }, [allUserMarkets, props]);

  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };

  const
    MainMenuItems = [{
      name: "Dashboard",
      src: "internal",
      href: "/",
      active: true,
      decoration: "Upcoming",
      icon: InsightsIcon
    }, {
      name: "Townsquare",
      src: "internal",
      href: "/townsquare/",
      active: true,
      decoration: FiberNewIcon,
      icon: GiteIcon,
      subNav: [{
        name: "Markets",
        src: "internal",
        href: "/markets/",
        active: true,
        icon: null
      }, {
        name: "Brands",
        src: "internal",
        href: "/facilities/",
        active: true,
        icon: null
      }]
    }, {
      name: "Issues & Programs",
      src: "internal",
      href: "/issues-and-programs/",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access.includes("Issues & Programs") ? true : false,
      decoration: FiberNewIcon,
      icon: DriveFolderUploadIcon,
      subNav: [{
        name: "Full Report",
        src: "internal",
        href: "/issues-and-programs/report/",
        active: true,
        icon: AssessmentIcon
      }, {
        name: "Compliance Report",
        src: "internal",
        href: "/issues-and-programs/compliance/",
        active: true,
        icon: CrisisAlertIcon
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/issues-and-programs/queue/",
        active: true,
        icon: ViewListIcon
      }]
    }, {
      name: "Political",
      src: "internal",
      href: "/political-candidates-and-issues/",
      active: allUserSettings && Object.keys(allUserSettings).length > 0 && allUserSettings?.access.includes("Political") && globalSettings?.menu?.political && globalSettings?.menu?.political?.value ? true : false,
      // active: globalSettings?.menu?.political ? globalSettings?.menu?.political?.value : false,
      decoration: "Upcoming",
      icon: GavelIcon,
      subNav: [{
        name: "Matching",
        src: "internal",
        href: "/political-candidates-and-issues/matching/",
        active: true,
        icon: AccountTreeIcon,
      }, {
        name: "Compliance Report",
        src: "internal",
        href: "/political-candidates-and-issues/compliance/",
        active: true,
        icon: CrisisAlertIcon,
        badge: politicalMatchingCount
      }, {
        name: "Upload Queue",
        src: "internal",
        href: "/political-candidates-and-issues/queue/",
        active: true,
        icon: ViewListIcon
      }, {
        name: "Full Report",
        src: "internal",
        href: "/political-candidates-and-issues/report/",
        active: true,
        icon: AssessmentIcon
      }]
    }, {
      name: "EEO",
      src: "internal",
      href: "/eeo",
      active: false,
      decoration: "Upcoming",
      icon: PolicyIcon
    }, {
      name: "Entity Settings",
      src: "internal",
      href: "/entity-settings",
      active: false,
      decoration: "Upcoming",
      icon: SettingsApplicationsIcon
    }],
    SecondaryMenuItems = [{
      name: "Get Support",
      src: "external",
      active: true,
      href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/issues-and-programs/",
      icon: ContactSupportIcon
    }, {
      name: "Training Library",
      src: "internal",
      active: true,
      href: "/training/",
      icon: VideoLibraryIcon
    }, {
      name: "Mobile App",
      src: "internal",
      active: false,
      href: "/mobile-app",
      icon: AppShortcutIcon
    }, {
      name: "Submit An Idea",
      src: "internal",
      active: false,
      href: "submit-an-idea",
      icon: TipsAndUpdatesIcon
    }];

  const DrawerList = (
    <Box sx={{...classes.drawerPaper, width: 250, marginTop: "100px"}} role="presentation">
      <List>
        {MainMenuItems.map((item, index) => {

          let expand = false;

          // If the menu item has subnav menu items
          if (item.subNav) {
            const subNavItems: any = [];
            // Put the subnav items into an array
            item.subNav.map((subNav: any) => {
              const route = props?.route?.split("/")[1];
              props?.route !== "/" && subNav.href.includes(route) ? expand = true : null;
              subNavItems.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <ListItem key={subNav.name} disablePadding component={Link} disabled={!subNav.active}
                  to={`${subNav.href}`}>
                  <ListItemButton sx={{...classes.listItemStyles, pl: 2}} dense={true}
                    selected={props?.route?.includes(subNav.href)}>
                    {subNav.icon ? <ListItemIcon sx={classes.listItemIcon}>
                      {subNav.badge && subNav.badge >= 1 ? <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        overlap="rectangular"
                        badgeContent={subNav.badge ? subNav.badge : 0}
                        color="warning"
                        sx={{paddingRight: "5px", paddingTop: "3px"}}
                      >
                        <subNav.icon/>
                      </Badge>
                        : <subNav.icon/>}
                    </ListItemIcon> : <ListItemIcon sx={classes.listItemIcon}></ListItemIcon>}
                    <ListItemText primary={subNav.name} sx={classes.listItemText}/>
                  </ListItemButton>
                </ListItem>
              );
            });

            const [openIssues, setOpenIssues] = useState(expand);
            const updateOpenIssuesMenu = () => {
              setOpenIssues(!openIssues);
            };

            // return the menu item and its subnav items
            return (<Suspense key={item.name} fallback={<div key={item.name} style={{
              height: "100px",
              backgroundColor: "transparent"
            }}></div>}>
              <ListItemButton id={item.name + "123123"} sx={classes.listItemStyles} disabled={!item.active} dense={true}
                onClick={updateOpenIssuesMenu}>
                {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon> : <></>}
                <ListItemText primary={item.name} sx={classes.listItemText}/>
                {openIssues ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
              </ListItemButton>
              <Collapse in={openIssues} timeout="auto" aria-expanded={openIssues} mountOnEnter unmountOnExit>
                <List disablePadding>
                  {subNavItems}
                </List>
              </Collapse>
            </Suspense>);
          }

          // Regular menu item
          else {

            return (<ListItem key={item.name} disablePadding disabled={!item.active}>
              <ListItemButton sx={classes.listItemStyles} dense={true}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Link}
                to={item.active ? item.href : undefined}
                selected={props?.route?.split("/")[1] === item.href || props?.route === item.href}>
                {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                  <item.icon/>
                </ListItemIcon> : <></>}
                <ListItemText primary={item.name} sx={classes.listItemText}/>
              </ListItemButton>
            </ListItem>);

          }
        })
        }
      </List>
      <Divider sx={classes.dividerStyleCb}/>
      <List>
        {SecondaryMenuItems.map((item, index) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <ListItem key={item.name} disablePadding component={Link}
            target={item.src === "external" ? "_blank" : undefined}
            to={item.src === "external" && item.active ? item.href : (item.src !== "external" && item.active) ? `${item.href}` : undefined}>
            <ListItemButton sx={classes.secondaryBtn} dense={true} disabled={!item.active}>
              {item?.icon ? <ListItemIcon sx={classes.listItemIcon}>
                <item.icon/>
              </ListItemIcon> : <></>}
              <ListItemText primary={item.name} sx={classes.listItemText}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>)
  ;
  return (
    <Suspense fallback={<div style={{...classes.loading_drawer as any}}></div>}>
      <Box>
        {/*<Button onClick={toggleDrawer(true)}>Open drawer</Button>*/}
        <Drawer
          // onClose={toggleDrawer(false)}
          anchor={"left"}
          variant={isSmallScreen ? "temporary" : "permanent"}
          // open={isSmallScreen ? open : true}
          open={true}
          sx={classes.body}
        >
          {DrawerList}
        </Drawer>
      </Box>
    </Suspense>
  );
};