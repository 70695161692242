import React, {lazy, Suspense, useEffect, useState} from "react";
import {useAtom} from "jotai";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useAtomWithQuery} from "hooks/useAtomWithQuery";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {useQuarter} from "hooks/useQuarter";
import {useDate} from "hooks/useDate";
import urlPaths from "services/api/urlPaths";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheMarketsWithMyMarkets, allUsersPrimaryAndSecondaryMarkets} from "services/jotai/requests/requests";
import {Brand} from "components/ui/table/components/cell/Brand";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {v4 as uuidv4} from "uuid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {useApiAuth} from "services/api/useApiAuth";
import {useSnackBar} from "hooks/useSnackBar";
import Tooltip from "@mui/material/Tooltip";
import FccPoliticalFetchNewFccDocuments from "../../../components/ui/forms/political_fetch_new_fcc_documents";

const
  Box = lazy(() => import("@mui/material/Box")),
  Grid = lazy(() => import("@mui/material/Grid")),
  Stack = lazy(() => import("@mui/material/Stack")),
  Button = lazy(() => import("@mui/material/Button")),
  UploadFileIcon = lazy(() => import("@mui/icons-material/UploadFile")),
  Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]}))),
  Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]}))),
  Table = lazy(() => import("components/ui/table/index").then((module) => ({default: module["StickyTable"]}))),
  FccIssuesAndProgramsForm = lazy(() => import("components/ui/forms/fcc_issues_and_programs")),
  ButtonBar = lazy(() => import("components/ui/buttonbar/index")),
  Status = lazy(() => import("components/ui/table/components/cell/Status").then((module: any) => ({default: module["Status"]}))),
  Topper = lazy(() => import("components/ui/table/components/Topper").then((module) => ({default: module["Topper"]})));

export const FccPoliticalComplianceReport = (props: any) => {
  const
    navigate = useNavigate(),
    [marketsSelect] = useAtom(allTheMarketsWithMyMarkets),
    [allUserMarkets, setAllUserMarkets] = useAtom(allUsersPrimaryAndSecondaryMarkets),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {makeRequest} = useApiAuth(),
    {setSnackBar} = useSnackBar(),
    {
      getCurrentQuarter,
      getArrayOfQuarters
    } = useQuarter(),
    {getCurrentYear, getArrayOfYears, daysBetweenDates, daysDocumentIsPastDue, convertDatePretty} = useDate(),
    [modalState, setModalState] = useState<boolean>(false),
    [modalData] = useState<any>(),
    [compliant] = useState<any>(false),
    [year, setYear] = useState<any>(getCurrentYear()),
    [quarter, setQuarter] = useState<any>(`Q${getCurrentQuarter()}`),
    [market, setMarket] = useState("My Markets"),
    // Fetching and using our data
    {cachedMarketsData} = cachedRequests(),
    {requestAtom} = useAtomWithQuery(),
    [errorState, setErrorState] = useState(null),
    [showLatest, setShowLatest] = useState<any>(true),
    complianceAtom = requestAtom("politicalCompliance", urlPaths.POLITICAL_MATCHES, {
      year: !showLatest ? year : undefined,
      market: market === "My Markets" ? allUserMarkets : [market], /*market,*/
      showLatest: showLatest ? showLatest : undefined,
      quarter: !showLatest ? quarter : undefined,
      matched: false,
    }, setErrorState),
    [complianceState, setComplianceState] = useState<any>(() => complianceAtom),
    [{data, isPending, isError}] = useAtom<AtomQuery>(complianceState);

  // Get the markets data once from local storage or the server
  useEffect(() => {
    cachedMarketsData();
  }, []);

  useEffect(() => {
    setComplianceState(complianceAtom);
  }, [year, quarter, market, allUserMarkets, showLatest]);

  const dismissMatching = async (params: any) => {
    await makeRequest("POST", urlPaths.POLITICAL_MATCHES_DISMISS_MATCHING, {
      csv_id: params.row.csv_id,
      username: userInfo.username
    }, null).then((data: any) => {
      setSnackBar("Matching has been dismised!", "success");
      setComplianceState(complianceAtom);
    }).catch((error) => {
      setSnackBar("Error: " + error, "error");
      console.log("error", error);
    });
  };

  const mobileColumns = [{
      field: "brand",
      id: "brand",
      headerName: "Recently Uploaded Items",
      // width: 250,
      flex: 12,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          csv_id: string,
          market: string,
          facility_id: string,
          callsign: string,
          advertiser: string,
          order_type: string,
          upload_process_id: string,
          date_created: string,
          fcc_markets: {
            facId: string,
            service: string,
            calls: string
          }
        }
      }) => {
        return <Link to={`/facility/${params.row.fcc_markets.facId}/`}>
          <Grid container spacing={3}>
            <Grid xs sx={{minHeight: "fit-content", height: "auto", display: "inline-block"}}>
              <div style={{lineHeight: "normal"}}><Box
                component="img"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                loading="lazy"
                sx={{
                  lineHeight: "normal",
                  width: "auto",
                  maxHeight: {xs: 90, md: 90},
                }}
                alt={`${params.row.callsign} Logo`}
                src={`/craft/rest/image?facility_id=${params.row.fcc_markets.facId}`}
              />
              </div>
            </Grid>
            <Grid xs sx={{float: "left"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal", marginTop: "5px"}}>
                <span><strong>Market:</strong> {params.row.market}</span>
                <span><strong>Callsign:</strong> {params.row.fcc_markets.calls}</span>
                <span><strong>Service:</strong> {params.row.fcc_markets.service}</span>
                <span><strong>Facility ID:</strong> {params.row.fcc_markets.facId}</span>
              </Stack>
            </Grid>
            <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
              <Stack direction="column" spacing={.5} sx={{lineHeight: "normal"}}>
                <span><strong>Advertiser:</strong> {params.row.advertiser}</span>
                <span><strong>Type:</strong> {params.row.order_type}</span>
                <span><strong>Date Ordered:</strong> {convertDatePretty(params.row.date_created)}</span>
                <span><strong>Date Required:</strong> {convertDatePretty(daysDocumentIsPastDue(params.row.date_created, 5), false)}</span>
                <Status {...{
                  ...props,
                  type: "days",
                  compliant: compliant,
                  mobile: false,
                  value: daysBetweenDates(params.row.date_created)
                }} />
              </Stack>
              <Grid xs={12} sx={{marginTop: "5px", marginBottom: "5px"}}>
                <Stack direction="column" spacing={.5}>
                  <Stack direction="row" sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }} spacing={1}>
                    <Button
                      key={uuidv4()}
                      sx={{
                        // borderRadius: "50%",
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        padding: "4px",
                        "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                      }}
                      onClick={(() => {
                        navigate(`/political-candidates-and-issues/upload/${params.row.csv_id}`);
                        // setModalData(params);
                        // setModalState(!modalState);
                      })}
                    ><UploadFileIcon sx={{
                        color: "#1976d2",
                        fontSize: "1.6rem",
                      }}/> Upload</Button>
                    <Button
                      key={uuidv4()}
                      sx={{
                        // borderRadius: "50%",
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        padding: "4px",
                        "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"},
                      }}
                      onClick={(() => {
                        dismissMatching(params);
                      })}
                    ><RemoveCircleOutlineIcon sx={{
                        color: "#1976d2",
                        fontSize: "1.6rem",
                      }}/> Remove</Button></Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Link>;
      }
    }],
    columns = [{
      field: "market",
      id: "market",
      headerName: "Market",
      flex: 1,
      renderCell: (params: { row: { market: string } }) => {
        return <Link to={`/market/${params.row.market}/`}>{params.row.market}</Link>;
      },
      valueGetter: (params: any) => params
    }, {
      field: "brand",
      id: "brand",
      headerName: "Brand",
      width: 80,
      // flex: 1,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: {
        row: {
          market: string,
          facility_id: string,
          callsign: string,
          advertiser: string,
          order_type: string,
          upload_process_id: string,
          date_created: string,
          fcc_markets: { facId: string, service: string, calls: string }
        }
      }) =>
        <Brand {...{
          callsign: params.row.fcc_markets.calls,
          facility_id: params.row.fcc_markets.facId,
          service: params.row.fcc_markets.service
        }} />
    }, {
      field: "callsign",
      id: "callsign",
      headerName: "Call Sign",
      width: 100,
      renderCell: (params: { row: { facility_id: string, callsign: string } }) => {
        return (<Link to={`/facility/${params.row.facility_id}/`}>{params.row.callsign}</Link>);
      }
    }, {
      field: "service",
      id: "service",
      headerName: "Service",
      width: 80,
      // flex: 1,
    }, {
      field: "facility_id",
      id: "facility_id",
      headerName: "Facility Id",
      width: 90,
      renderCell: (params: { row: { fcc_markets: { facId: string, callsign: string } } }) => {
        return (<Link to={`/facility/${params.row.fcc_markets.facId}/`}>{params.row.fcc_markets.facId}</Link>);
      }
    }, {
      field: "date_created",
      id: "date_created",
      headerName: "WideOrbit Date",
      renderCell: (params: { row: { upload_process_id: string, date_created: string } }) => {
        return <p>{convertDatePretty(params.row.date_created, false)}</p>;
      },
      flex: 1
    }, {
      field: "date_required",
      id: "date_required",
      headerName: "Date Required",
      renderCell: (params: { row: { upload_process_id: string, date_created: string } }) => {
        return <p>{convertDatePretty(daysDocumentIsPastDue(params.row.date_created, 5), false)}</p>;
      },
      flex: 1
    }, {
      field: "order_type",
      id: "order_type",
      headerName: "Type",
      flex: 1,
      renderCell: (params: { row: { order_type: string } }) => {
        return params.row.order_type.toLowerCase().replace("political ", "");
      }
    }, {
      field: "advertiser",
      id: "advertiser",
      headerName: "Advertiser",
      flex: 2,
      renderCell: (params: { row: { advertiser: string } }) => {
        return params.row.advertiser;
      }
    }, {
      field: "upload_status",
      id: "status",
      align: "left",
      headerName: "Status",
      flex: 2,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      renderCell: (params: { row: { upload_process_id: string, date_created: string } }) => {
        return <Status {...{
          ...props,
          type: "days",
          compliant: compliant,
          mobile: false,
          value: daysBetweenDates(params.row.date_created)
        }} />;
      }
    }, {
      field: "actions",
      id: "actions",
      align: "left",
      type: "actions",
      headerName: "Actions",
      // flex: 1,
      width: 120,
      disableSelectionOnClick: true,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      disableClickEventBubbling: true,
      getActions: (params: any) => [
        !compliant ? <Tooltip
          key={uuidv4()}
          title="Upload Docs"
          enterDelay={1200}
          // leaveDelay={500}
          TransitionProps={{timeout: 200}}
        ><GridActionsCellItem
            key={uuidv4()}
            label={uuidv4()}
            icon={<UploadFileIcon sx={{
              color: "#1976d2",
              fontSize: "1.6rem",
            }}/>}
            onClick={(() => {
              navigate(`/political-candidates-and-issues/upload/${params.row.csv_id}`);
            // setModalData(params);
            // setModalState(!modalState);
            })}
          /></Tooltip> : <></>, <Tooltip
          key={uuidv4()}
          title="Reject Match"
          enterDelay={1200}
          // leaveDelay={500}
          TransitionProps={{timeout: 200}}
        ><GridActionsCellItem
            key={uuidv4()}
            label={uuidv4()}
            icon={<RemoveCircleOutlineIcon sx={{
              color: "#1976d2",
              fontSize: "1.6rem",
            }}/>}
            onClick={(() => {
              dismissMatching(params);
            })}
          /></Tooltip>
      ]
    }],

    buttonBarButons: any = {
      mobile: props.screen,
      left: [{
        name: "+ Upload Docs",
        type: "link",
        location: "internal",
        href: "/political-candidates-and-issues/upload/"
      }, {
        name: "Import From FCC",
        type: "modal",
        modal: {
          modalTitle: "Import From FCC - Political Candidates and Issues",
          modalForm: FccPoliticalFetchNewFccDocuments,
          modalFooter: {
            modalFooterButtons: {
              left: [{
                variant: "contained",
                color: "secondary",
                name: "cancel",
                type: null,
              }],
              right: [{
                variant: "contained",
                color: null,
                name: "Upload To FCC",
                type: "submit",
              }]
            }
          }
        }
      }],
      right: [{
        name: "Share Feedback",
        type: "link",
        location: "external",
        href: "https://forms.townsquaremainstreet.com/submit/signalcraft/?ref=http://townsquaresignalcraft.com/craft/view/political-candidates-and-issues/compliance/"
      }]
    },
    topperProps: any = {
      title: props.mobile ? "Orders Needing Docs" : "WideOrbit Orders Needing Documents",
      inputs: [{
        label: "Show latest",
        type: "switch",
        defaultValue: false,
        state: showLatest,
        setState: setShowLatest
      }, {
        label: "Year",
        type: "select",
        multiple: false,
        width: "150px",
        options: getArrayOfYears(),
        defaultValue: getCurrentYear(),
        state: year,
        setState: setYear,
        display: !showLatest
      }, {
        label: "Quarter",
        type: "select",
        multiple: false,
        width: "200px",
        options: getArrayOfQuarters(year),
        defaultValue: `Q${getCurrentQuarter()}`,
        state: quarter,
        setState: setQuarter,
        display: !showLatest
      }, {
        label: "Market",
        type: "select",
        multiple: false,
        width: "300px",
        options: marketsSelect,
        defaultValue: "My Markets",
        state: market,
        setState: setMarket
      }, {
        label: "Reset",
        type: "button",
        onClick: ((e: React.MouseEvent) => {
          setMarket("My Markets");
          setYear(2024);
          setQuarter(`Q${getCurrentQuarter()}`);
        })
      }]
    },
    modal = {
      name: "+ Upload PDF",
      type: "modal",
      modal: {
        state: modalState,
        updateModalState: setModalState,
        icon: UploadFileIcon,
        data: modalData,
        modalTitle: "Upload A PDF",
        modalForm: FccIssuesAndProgramsForm,
        modalFooter: {
          modalFooterButtons: {
            left: [{
              variant: "contained",
              color: "secondary",
              name: "cancel",
              type: null,
            }],
            right: [{
              variant: "contained",
              color: null,
              name: "Upload To FCC",
              type: "submit",
            }]
          }
        }
      }
    };


  return (
    <Suspense fallback={<></>}>
      <Title {...{
        title: "Political Candidates & Issues",
      }} />
      <Breadcrumb {...{
        breadcrumb: ["Home", "Political Candidates & Issues"]
      }} />
      {/*<TableCellModalButtonV2 {...modal} />*/}
      <ButtonBar {...buttonBarButons} />
      <Topper {...{...topperProps, mobile: props.mobile}} />
      <Table {...{
        tableKey: "complianceTable",
        mobile: props.mobile,
        data: data?.length > 0 ? data : [], // tableData && tableData?.length > 0 ? tableData : [],
        columns: props.mobile ? mobileColumns : columns,
        loading: isPending,
        error: isError,
        errorMessage: errorState,
        sortBy: "date_created"
      }} />
    </Suspense>
  );
};