import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {cachedRequests} from "services/api/cachedRequests";
import {allTheFacilitiesData, allTheMarketsData} from "services/jotai/requests/requests";
import urlPaths from "services/api/urlPaths";
import dayjs from "dayjs";

interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: any[],
  PdfFile: any,
  Files: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
}

interface Market {
  name: string;
  id: string | number;
}

interface MarketOutput {
  label: string;
  id: string | number;
  facility_id?: string | number;
}

const getMarkets = (allMarketsData: Market[]): MarketOutput[] => {
  const allMarkets: Set<MarketOutput> = new Set();

  allMarketsData?.forEach((market: Market) => {
    allMarkets.add({label: market.name, id: market.name});
  });

  return Array.from(allMarkets);
};
const getCallsigns = (selectedMarkets: any, tsMarkets: any) => {
  const allCallsigns: Set<any> = new Set([]);
  if (tsMarkets && selectedMarkets && selectedMarkets.length > 0) {
    if (typeof selectedMarkets === "string") {
      tsMarkets?.forEach((tsMarket: any) => {
        if (tsMarket["name"].toLowerCase() === selectedMarkets.toLowerCase()) {
          tsMarket.brands.forEach((brands: any) => {
            allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
          });
        }
      });
      return Array.from(allCallsigns);
    } else {
      tsMarkets?.forEach((tsMarket: any) => {
        selectedMarkets.forEach((selectedMarket: any) => {
          if (tsMarket["name"].toLowerCase() === selectedMarket.toLowerCase()) {
            tsMarket.brands.forEach((brands: any) => {
              allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
            });
          }
        });
      });
      return Array.from(allCallsigns);
    }
  } else {
    return [];
  }
};


export default function FccPoliticalFetchNewFccDocuments(props: any) {
  let docsCount = 0;
  const
    {makeRequest} = useApiAuth(),
    {
      getCurrentYear,
      getArrayOfYears,
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    [allMarketsData] = useAtom(allTheMarketsData),
    [allTFacilitiesData] = useAtom<any>(allTheFacilitiesData),
    [submitted, setSubmitted] = useState(false),
    {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        Years: getCurrentYear(),
        Markets: [],
        Callsigns: [],
      },
    }),
    year: any = useWatch({control, name: "Years"}),
    market = useWatch({control, name: "Markets"}),
    [marketNames, setMarketNames] = useState<any>([]),
    [marketCallsigns, setMarketCallsigns] = useState([]),
    yearOptions = getArrayOfYears(),
    {setMainSnackBar} = useMainSnackBar(),
    getNewFccFiles = async (facilityId: string) => {
      return makeRequest("GET", urlPaths.POLITICAL_UPDATE_FCC_FILES, null, {
        year: year,
        facility_id: facilityId,
        start_date: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        end_date: dayjs().format("YYYY-MM-DD")
      }).then((response: any) => {
        if (response) {
          console.log("response", response);
          docsCount += response.updated_files.length;
        }
      }).then(() => {
        console.log("new doc count", docsCount);
      }).catch((error: any) => {
        setMainSnackBar("error", "Oh no there was an error adding your document!", "error");
        console.log("error", error);
      });
    },
    resolver = async (data: any) => {
      const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
      console.log("submit data", data);
      docsCount = 0;
      setSubmitted(true);

      for await (const facilityId of data.Callsigns) {
        await getNewFccFiles(facilityId);
        await sleep(1000);
      }

      // await sleep(4000);
      reset(); // Reset the form state back to the default state
      setTimeout(async () => {
        props.handleChange(); // Close the modal
        if (docsCount > 0) {
          await setMainSnackBar("success", `${docsCount} new FCC documents successfully added to SignalCraft!`, "success");
        } else {
          await setMainSnackBar("success", `No documents on the FCC are missing from SignalCraft`, "info");
        }
      }, 2000);

    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver(data);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);


  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();

    // Select the market if the data was sent
    if (props?.data?.market) {
      setValue("Markets", [props?.data?.market]);
    }
    // Select the facility in the dropdown if the data was sent
    if (props?.data?.facility_id && allTFacilitiesData) {
      setValue("Markets", [allTFacilitiesData[props?.data?.facility_id].market]);
      setTimeout(() => {
        setValue("Callsigns", [Number(props?.data?.facility_id)]);
      }, 2000);
    }
  }, []);

  // Set the market names inside the select
  useEffect(() => {
    if (allMarketsData) {
      const allTsmMarkets: MarketOutput[] = getMarkets(allMarketsData);
      setMarketNames(allTsmMarkets);
    }
  }, [allMarketsData]);

  // Wipe callsign selections and repopulate the select options with new callsigns each time the markets dropdown changes
  useEffect(() => {
    setValue("Callsigns", []);
    const allCallSigns: any = getCallsigns(market, allMarketsData);
    setMarketCallsigns(allCallSigns);
  }, [market]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: marketNames && marketNames.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: marketCallsigns,
              disabled: marketCallsigns.length === 0 ? true : false,
              required: true,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "11px",
            marginTop: "14px",
            marginBottom: "14px",
            maxWidth: "75%",
            margin: "15px auto -15px auto"
          }}><strong>Note:</strong> This process fetches all missing political documents that exist on the FCC and are
            absent from SignalCraft. This process is automatically performed behind the scenes periodically, however,
            if you&apos;re missing a newly uploaded document this tool is designed to help.</p>
        </Grid>
      </Grid>
      <ModalFooter {...{
        buttons: {
          left: [{
            variant: "contained",
            color: "secondary",
            name: "cancel",
            type: null,
          }],
          right: [{
            variant: "contained",
            color: null,
            name: "Fetch New Documents",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}