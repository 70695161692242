import React, {lazy, Suspense} from "react";
import Modal from "@mui/material/Modal";

const
  Box = lazy(() => import("@mui/material/Box")),
  ModalTopper = lazy(() => import("components/ui/modal/components/ModalTopper"));

const
  modal = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px",
    position: "relative",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  };

export default function MainModal(props: any) {
  return (
    <Suspense fallback={<></>}>
      <Modal
        open={props.open}
        onClose={props.handleChange}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{margin: "0 10px"}}
      >
        <Box sx={modal}>
          <ModalTopper {...{title: props.title, close: props.handleChange}} />
          {React.cloneElement(props.children, {
            ...{
              mobile: props.mobile,
              close: props.handleChange,
              submitButtonState: props.submitButtonState,
              setModalSubmitState: props.setModalSubmitState
            }
          })}
        </Box>
      </Modal>
    </Suspense>
  );
}