import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useAtom} from "jotai";
import {Facility} from "pages/facility/index";
import {Facilities} from "pages/facilities/index";
import SsoLogout from "pages/auth/Logout/index";
import SsoLogin from "pages/auth/SsoLogin/index";
import {Markets} from "pages/markets/index";
import {Market} from "pages/market/index";
import {FccIssuesAndPrograms} from "pages/fcc_issues_and_programs/pages/full_report";
import {FccIssuesAndProgramsQueue} from "pages/fcc_issues_and_programs/pages/upload_queue";
import {FccIssuesAndProgramsBatchQueue} from "pages/fcc_issues_and_programs/pages/upload_batch_status";
import {FccIssuesAndProgramsComplianceReport} from "pages/fcc_issues_and_programs/pages/compliance_report";
import {FccPoliticalFullReport} from "pages/fcc_political_candidates_and_issues/pages/full_report";
import {FccPoliticalMatching} from "pages/fcc_political_candidates_and_issues/pages/matching";
import {FccPoliticalUpload} from "pages/fcc_political_candidates_and_issues/pages/upload_documents";
import {FccPoliticalQueue} from "pages/fcc_political_candidates_and_issues/pages/upload_queue";
import {FccPoliticalBatchQueue} from "pages/fcc_political_candidates_and_issues/pages/upload_batch_status";
import {FccPoliticalDocumentUploadStatus} from "pages/fcc_political_candidates_and_issues/pages/upload_document_status";
import {FccPoliticalComplianceReport} from "pages/fcc_political_candidates_and_issues/pages/compliance_report";

import {Pdf} from "pages/pdf/index";
import {PoliticalPdf} from "pages/fcc_political_candidates_and_issues/pages/document_view";
import {Profile} from "pages/profile/index";
import {Training} from "pages/training/index";
import React, {lazy, Suspense, useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {authTokenAtom, userInfoAtom} from "services/jotai/auth/msauth";
import {useStyles} from "./styles/styles.js";
import {Status} from "pages/status/index";
import NotFound from "pages/not_found";
import {LeftNav} from "components/nav/desktop/components/left_nav/index";
import {TopAppBar} from "components/nav/desktop/components/top_nav/index";
import {MobileMenu} from "components/nav/mobile/index";
import {cachedRequests} from "services/api/cachedRequests";
import {v4 as uuidv4} from "uuid";

// import {Dashboard} from "../../pages/home";
const Container = lazy(() => import("components/ui/container/index"));

const Dashboard = lazy(() => import("pages/home/index").then((module) => ({default: module["Dashboard"]})));
const Login = lazy(() => import("pages/auth/index"));
const Onboarding = lazy(() => import("pages/onboarding/index"));
const Box = lazy(() => import("@mui/material/Box"));
// const FccIssuesAPrograms = lazy(() => import("pages/fcc_issues_and_programs/index").then((module) => ({ default: module["FccIssuesAndPrograms"] })));
// const Breadcrumb = lazy(() => import("components/ui/breadcrumb/index").then((module) => ({default: module["Breadcrumb"]})));
// const Title = lazy(() => import("components/ui/pagetitle/index").then((module) => ({default: module["Title"]})));

export default function AllRoutes(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [authToken] = useAtom(authTokenAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const {cachedSettingsData} = cachedRequests();

  useEffect(() => {
    if (authToken) {
      cachedSettingsData();
    }
  }, [authToken]);

  // Redirect the user to logout if their auth token has expired
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!authToken && userInfo.firstName !== "" && !window.location.pathname.includes("/msauth/")) {
  //       navigate("/msauth/logout");
  //     }
  //   }, 2000);
  // }, [authToken, window.location.pathname]);

  // function Users() {
  //   return (
  //     <div style={{margin: "150px"}}>
  //       <nav>
  //         <Link to="/craft/view/users/me">My Profile</Link>
  //       </nav>
  //       <nav>
  //         <Link to="/craft/view/users/wow">WOW My Profile</Link>
  //       </nav>
  //
  //       <Routes>
  //         <Route path="/craft/view/users" element={<h1>Users</h1>}/>
  //         <Route path="/craft/view/users/:id" element={<h1>User ID</h1>}/>
  //         <Route path="/craft/view/users/me" element={<h1>My profile</h1>}/>
  //       </Routes>
  //     </div>
  //   );
  // }


  // Routes with menus and content sections
  const routes: ({ component: any; route: string, title: null | string, breadCrumb: null | any | []; })[] = [{
    route: "/training/",
    component: Training,
    title: "Training Library",
    breadCrumb: ["Home", "Training Library"],
  }, {
    route: "/profile",
    component: Profile,
    title: "Profile",
    breadCrumb: ["Home", "Profile"],
  }];

  // {
  //   route: "/users/*",
  //     component: Users,
  //   title: null,
  //   breadCrumb: []
  // }

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route key={"health"} path={"/health"} element={<div>ok</div>}/>
        <Route key={"login"} path={"/msauth/login"} element={<Login/>}/>
        <Route key={"logincallback"} path={"/msauth/callback"} element={<SsoLogin/>}/>
        <Route key={"logout"} path={"/msauth/logout"} element={<SsoLogout/>}/>
        <Route key={"onboarding"} path={"/onboarding"} element={<Onboarding {...{mobile: isSmallScreen}}/>}/>


        {/*{*/}
        {/*  route: "/issues-and-programs/document",*/}
        {/*  component: Pdf,*/}
        {/*  title: " ",*/}
        {/*  breadCrumb: ["Home", ":id"],*/}
        {/*},*/}
        <Route key={uuidv4()} path={"/issues-and-programs/document/:id"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Pdf {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/document/:id"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Pdf {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>

        {/*{*/}
        {/*  route: "/facility/:id/",*/}
        {/*  component: Facility,*/}
        {/*  title: " ",*/}
        {/*  breadCrumb: ["Home", ":id"],*/}
        {/*},*/}
        <Route key={uuidv4()} path={"/facility/:id/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Facility {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/facilities/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Facility {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>


        <Route key={uuidv4()} path={"/facilities/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Facilities {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/facilities/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Facilities {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>

        <Route key={uuidv4()} path={"/markets/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Markets {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/markets/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Markets {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>


        <Route key={uuidv4()} path={"/market/:name/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Market {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/markets/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Market {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>


        {/*  {*/}
        {/*    route: "",*/}
        {/*    component: Dashboard,*/}
        {/*    title: `${userInfo?.firstName}'s Dashboard`,*/}
        {/*    breadCrumb: ["Home", "Dashboard"]*/}
        {/*  }, {*/}
        {/*  route: "/",*/}
        {/*  component: Dashboard,*/}
        {/*  title: `${userInfo?.firstName}'s Dashboard`,*/}
        {/*  breadCrumb: ["Home", "Dashboard"]*/}
        {/*},*/}
        <Route key={uuidv4()} path={"/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Dashboard {...{user: userInfo, mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Dashboard {...{user: userInfo, mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>


        {/*{*/}
        {/*  route: "/issues-and-programs/status/:id",*/}
        {/*  component: Status,*/}
        {/*  title: "Upload",*/}
        {/*  breadCrumb: ["Home", "issues-and-programs/queue/", ":id"]*/}
        {/*}*/}
        <Route key={uuidv4()} path={"/issues-and-programs/queue/:id/facility/:facid"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <Status {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/queue/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <Status {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>

        {/*{*/}
        {/*  route: "/issues-and-programs/queue/:id",*/}
        {/*  component: FccIssuesAndProgramsBatchQueue,*/}
        {/*  title: "Upload",*/}
        {/*  breadCrumb: ["Home", "issues-and-programs/queue/", ":id"]*/}
        {/*},*/}
        <Route key={uuidv4()} path={"/issues-and-programs/queue/:id"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccIssuesAndProgramsBatchQueue {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/queue/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccIssuesAndProgramsBatchQueue {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>


        <Route key={uuidv4()} path={"/issues-and-programs/compliance/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccIssuesAndProgramsComplianceReport {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/compliance/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccIssuesAndProgramsComplianceReport {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>

        {/*{*/}
        {/*  route: "/issues-and-programs/report/",*/}
        {/*  component: FccIssuesAndPrograms,*/}
        {/*  title: "Issues And Programs",*/}
        {/*  breadCrumb: ["Home", "Issues And Programs"],*/}
        {/*},*/}
        <Route key={uuidv4()} path={"/issues-and-programs/report/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccIssuesAndPrograms {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/report/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccIssuesAndPrograms {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>

        <Route key={uuidv4()} path={"/issues-and-programs/queue/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccIssuesAndProgramsQueue {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/issues-and-programs/queue/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccIssuesAndProgramsQueue {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>

        {/*{*/}
        {/*  route: "/issues-and-programs/compliance/",*/}
        {/*  component: FccIssuesAndProgramsComplianceReport,*/}
        {/*  title: "Issues And Programs",*/}
        {/*  breadCrumb: ["Home", "Issues And Programs"],*/}
        {/*},*/}


        { /* - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          Political candidates and issues routes
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        <Route key={uuidv4()} path={"/political-candidates-and-issues/compliance/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalComplianceReport {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/compliance/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalComplianceReport {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/report/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalFullReport {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/report/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalFullReport {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/matching/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalMatching {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/matching/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalMatching {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/queue/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalQueue {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalQueue {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/upload/"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalUpload {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalUpload {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/queue/:id"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalBatchQueue {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalBatchQueue {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/upload/:id"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <FccPoliticalUpload {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/report/"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <FccPoliticalUpload {...{mobile: isSmallScreen}}/>
                </Box>
              </main>}
          </>
        }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/queue/:id/facility/:facid/document/:document"}
          element={
            <>
              {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
              {isSmallScreen ?
                <main>
                  <FccPoliticalDocumentUploadStatus {...{mobile: isSmallScreen}}/>
                </main> :
                <main style={{display: "flex", position: "relative"}}>
                  <LeftNav {...{route: "/political-candidates-and-issues/queue/"}} />
                  <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                    <FccPoliticalDocumentUploadStatus {...{mobile: isSmallScreen}}/>
                  </Box>
                </main>}
            </>
          }/>
        <Route key={uuidv4()} path={"/political-candidates-and-issues/document/:id"} element={
          <>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <PoliticalPdf {...{mobile: isSmallScreen}}/>
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: "/political-candidates-and-issues/document/:id"}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <PoliticalPdf {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>
        }/>
        { /* - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          Dynamic routes that require the app bar and the side nav
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

        {/*{routes.map((route: {*/}
        {/*  title: string | null,*/}
        {/*  breadCrumb: any,*/}
        {/*  component: any,*/}
        {/*  route: string;*/}
        {/*}, index: number) => {*/}

        {/*  return (*/}
        {/*    <Route key={index} path={route.route} element={*/}
        {/*      <>*/}
        {/*        /!*{isSmallScreen ? <MobileMenu/> : <TopAppBar/>}*!/*/}
        {/*        {isSmallScreen ?*/}
        {/*          <Container {...{*/}
        {/*            ...route,*/}
        {/*            screen: isSmallScreen,*/}
        {/*          }} /> :*/}
        {/*          <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>*/}
        {/*            <Container {...{*/}
        {/*              ...route,*/}
        {/*              screen: isSmallScreen,*/}
        {/*            }} />*/}
        {/*          </Box>*/}
        {/*        }*/}
        {/*      </>*/}
        {/*    }/>*/}
        {/*  );*/}
        {/*})}*/}

        {routes.map((route: {
          title: string | null,
          breadCrumb: any,
          component: any,
          route: string;
        }, index: number) => {
          return (
            <Route key={uuidv4()} path={route.route} element={
              <>
                {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
                {isSmallScreen ?
                  <main>
                    <Container {...{
                      ...route,
                      screen: isSmallScreen,
                    }} />
                  </main> :
                  <main style={{display: "flex", position: "relative"}}>
                    <LeftNav {...{route: route.route}} />
                    <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                      <Container {...{
                        ...route,
                        screen: isSmallScreen,
                      }} />
                    </Box>
                  </main>}
              </>
            }/>
          );
        })}

        <Route key={"404"}
          path={"/*"} element={<>
            {isSmallScreen ? <MobileMenu/> : <TopAppBar/>}
            {isSmallScreen ?
              <main>
                <NotFound {...{mobile: isSmallScreen}} />
              </main> :
              <main style={{display: "flex", position: "relative"}}>
                <LeftNav {...{route: ""}} />
                <Box sx={isSmallScreen ? classes.mobilecontent : classes.desktopcontent}>
                  <NotFound {...{mobile: isSmallScreen}} />
                </Box>
              </main>}
          </>}
        />

      </Routes>
    </Suspense>
  );
}

